import { useEffect } from 'react'
import ReactGA from 'react-ga'

import { useLocation } from 'react-router-dom'

const useGATracker = () => {

  const TRACKING_ID = "UA-234924222-1"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  })
}

export default useGATracker
