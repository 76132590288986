import {ChevronLeft} from "@mui/icons-material";
import {Container} from '@mui/material';
import Box from '@mui/material/Box';
import transakSDK from "@transak/transak-sdk";
import {useEffect} from 'react';
import {
    useLocation,useNavigate
} from 'react-router-dom';


function openTransak(state, navigate) {

    const settings = {
        apiKey: 'cf5868eb-a8bb-45c8-a2db-4309e5f8b412',  // Your API Key
        environment: 'STAGING', // STAGING/PRODUCTION
        defaultCryptoCurrency: 'ETH',
        themeColor: '000000', // App theme color
        hostURL: window.location.origin,
        widgetHeight: "600px",
        widgetWidth: "358px",
        walletAddress: state.source, // Your customer wallet address
    }
    
    const transak = new transakSDK(settings);

    transak.init();

    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (eventData) => {
        navigate('/transact');
    });

    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (eventData) => {
        navigate('/dashboard');
    });
}


export default function TransakWidget() {
    
    const title={
        fontSize: "24px",
        color: "white",
        marginTop: '50px',
        textAlign: 'center'
    }
    
    
    const { state } = useLocation();
    let navigate = useNavigate(); 

      useEffect(() => {
        
        openTransak(state, navigate)

      }, []); // eslint-disable-line react-hooks/exhaustive-deps
    

    return (

            <div>
            <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            backgroundColor='#171E37'
            color='white'
            >
            <Container sx={{ height: '100vh', marginBottom: '70px' }}>
            
            <div style={{ width: '100%' }}>
            <ChevronLeft onClick={() => navigate(-1)} style={{ float: 'left', position: 'relative', left: 16, color: '#FFFFFF' }}/>
            <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Transact</h1>
            <hr style={{ width: '100%', borderColor: 'rgba(255,255,255,0.2)' }}/>
          </div>
                <Container style={{ marginTop: '20px' }}>

            <div style={{display:"flex", flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
           
                </div>
                </Container>
            
            </Container>
            </Box>
            </div>
    );
}

