import {Auth} from 'aws-amplify';
import {env} from '../environment';
import {getMyAvailableBalances} from "../Utils/getBalance.js";

export async function getAllWallets() {
  let walletSums = {}
  const auth = await Auth.currentSession();
  const options = {
    headers: {
      "Authorization": auth.idToken.jwtToken,
      "accessTokenStr": auth.accessToken.jwtToken
    }
  }

  const result = fetch(`${env.server}/get_user_deduped_wallets?userId=${auth.idToken.payload.email}`, options).then(res => res.json()).then(json => {
      
    let wallets = json

    wallets.map((wallet) => {

      let walletBalance = getMyAvailableBalances(wallet.address)
      // console.log("walletBalance", walletBalance)

      walletBalance.then(function(result) {
              walletSums[wallet.address] = result
          }).catch(() => {
            // Something is Wrong
            return(walletSums)
          })
      return(walletSums)
    })
    //   const reducer = (accumulator, curr) => accumulator + curr;
    //   console.log(everythingAssetsUSD.reduce(reducer));
    
    return(walletSums)
  
    });

    return result
 }

 export async function getAllWalletAddresses() {
  const auth = await Auth.currentSession();
  const options = {
    headers: {
      "Authorization": auth.idToken.jwtToken,
      "accessTokenStr": auth.accessToken.jwtToken
    }
  }
  const result = fetch(`${env.server}/get_user_deduped_wallets?userId=${auth.idToken.payload.email}`, options).then(res => res.json()).then(json => {
      
    
    return(json)
  
    });

    return result
 }

 export async function getEverythingBalance(walletValues) {

    let balance = []
    const test =  Object.values(walletValues)

       test.map((wallet) => (
        balance.push(wallet[1])
       ))

    const reducer = (accumulator, curr) => accumulator + curr;
    const roundedBalance = balance.length > 0 ? (balance.reduce(reducer)).toFixed(2) : 0

     return roundedBalance
 }

//   export async function getDumplingBalance(walletValues, did, wallet_info) {


//     let dumplingWallet = wallet_info[0].address 
//     console.log("bye", walletValues[dumplingWallet])
//     let total = walletValues[dumplingWallet]

//     return total
//  }