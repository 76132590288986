import {styled} from '@material-ui/core';
import {ArrowDropDown,ArrowDropUp} from '@mui/icons-material';
import {Accordion,AccordionDetails,AccordionSummary} from '@mui/material';
import {cryptoSymbol} from 'crypto-symbol';
import {useState} from 'react';
import WalletConnectLogo from '../images/WalletConnectLogo.svg';
import {aggregateCurrencies} from "./AssetList";
import {walletMap} from './walletMap';

export const determineChange = (old, newVal) => {
  const change = (newVal-old)/old * 100;
  const diff = newVal - old;
  if(change < 0) {
    return (
      <p style={{ margin: 0, fontSize: 12, fontFamily: 'Montserrat-Regular', fontWeight: 400, color: '#FF5E5E' }}>
        {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(diff)} ({change.toFixed(2)}%)
      </p>
    )
  }
  else if(change >= 0) {
    return (
      <p style={{ margin: 0, fontSize: 12, fontFamily: 'Montserrat-Regular', fontWeight: 400, color: '#02EDBA' }}>
        +{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(diff)} ({change.toFixed(2)}%)
      </p>
    )
  }
  else {
    return null;
  }
}

const CurrencyCard = ({ currencies, walletInfo, isPublic, quantities = true, values = true }) => {
  const { aggregate, totalValue } = aggregateCurrencies(currencies, walletInfo);
  const { nameLookup } = cryptoSymbol({});
  const [expanded, setExpanded] = useState(false);
  const providers = [];
  
  const getProviders = (currency) => {
    walletInfo.forEach(wallet => {
      const hasCurrency = wallet.currencies.find(x => x.name === currency && x.selected);
      if(hasCurrency && !providers.includes(wallet.provider)) {
        providers.push(wallet.provider);
      }
    });

    return providers;
  }

  const WalletImg = styled('img')(({ theme }) => ({
    borderRadius: '3px',
    height: '35px',
    width: '140px',
    [theme.breakpoints.down('sm')]: {
      height: '35px',
      width: '140px',
    }
  }))
  
  const getValueTotal = (currency, provider) => {
    let value = 0;
    walletInfo.forEach(wallet => {
      if(wallet.provider === provider) {
        wallet.currencies.forEach(type => {
          if(type.name === currency && type.selected) {
            value += parseFloat(type.usd_amount);
          }
        })
      }
    });

    return value;
  }

  const getBalanceTotal = (currency, provider) => {
    let balance = 0;
    walletInfo.forEach(wallet => {
      if(wallet.provider === provider) {
        wallet.currencies.forEach(type => {
          if(type.name === currency && type.selected) {
            balance += parseFloat(type.balance);
          }
        })
      }
    });

    return balance;
  }

  const getWalletForProvider = (provider, type) => {
    let wallets = [];
    walletInfo.forEach(wallet => {
      let typeAmount = {
        address: '',
        balance: '',
        value: ''
      };
      if(wallet.provider === provider) {
        let temp = {};
        temp = wallet.currencies.find(x => x.name === type && x.selected);
        typeAmount.address = wallet.address;
        typeAmount.balance = temp && temp.balance;
        typeAmount.value = temp && temp.usd_amount;
        if(typeAmount.value && typeAmount.balance) {
          wallets.push(typeAmount);
        }
      }
    });

    return wallets;
  }

  const changeDropdownIcon = (expanded) => {
    if(expanded) {
      setExpanded(true);
    }
    else {
      setExpanded(false);
    }
  }

  return(
    <div>
      {Object.keys(aggregate).map(type => (
        <Accordion onChange={(event, expanded) => changeDropdownIcon(expanded)} style={{ backgroundColor: '#252B43', borderRadius: 10, maxWidth: 400, display: 'flex', flexDirection: 'column', marginBottom: 16 }} disableGutters={true}>
          <AccordionSummary style={{ borderRadius: 10, width: '100%', backgroundColor: '#252B43', padding: 16}}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                  {/* <CryptoIcon coin={aggregate[type].name} style={{ fontSize: '24px' }}/> */}
                  <h3 style={{ fontSize: 20, fontFamily: 'Montserrat-Regular', fontWeight: 500, color: '#FFFFFF', marginLeft: '12px', marginTop: 0, marginBottom: 0 }}>
                    {aggregate[type].legalName || type}
                  </h3>
                </div>
                { !isPublic && (<div>
                  { expanded
                    ? <ArrowDropUp style={{ color: '#FFFFFF' }}/>
                    : <ArrowDropDown style={{ color: '#FFFFFF' }}/>}
                </div>) }
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                  <h3 style={{ marginTop: 0, marginBottom: 0, fontSize: 24, fontFamily: 'Montserrat-Regular', fontWeight: 500, color: '#FFFFFF' }}>{values && new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(aggregate[type].value)}</h3>
                  {determineChange(aggregate[type].formerValue, aggregate[type].value)}
                  <p style={{ margin: 0, fontSize: 13, fontWeight: 400, color: '#FFFFFF' }}>
                    {quantities && `${aggregate[type].balance.toFixed(3)} ${aggregate[type].name}`}
                  </p>
                </div>
              </div>
            </div>
          </AccordionSummary>
          { !isPublic
            ? <AccordionDetails sx={{ padding: '0px 0px 10px 0px'  }}>
                {getProviders(type).map(wallet => (
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 51, padding: '8px 16px', backgroundColor: 'rgba(255,255,255,0.1)' }}>
                  {wallet === "walletConnect" &&
                    <WalletImg
                    src={WalletConnectLogo}
                    alt="walletconnect"
                    /> }
                    {wallet === "coinbaseExchange" &&
                    <div>
                    {walletMap[wallet] || wallet}
                    <div style={{float: "right", marginLeft: "6px", color: "white", fontFamily: 'Montserrat-Regular', fontSize: "16px"}}> Exchange </div> 
                    </div>
                    }
                    {wallet === "Coinbase" &&
                    <div>
                    {walletMap[wallet] || wallet}
                    <div style={{float: "right", marginLeft: "6px", color: "white", fontFamily: 'Montserrat-Regular', fontSize: "16px"}}> Wallet </div> 
                    </div>
                    }
                    {wallet === "Metamask" &&
                    <div>
                    {walletMap[wallet] || wallet}
                    </div>
                    }
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <h2 style={{ margin: 0, fontSize: '16px', fontFamily: 'Montserrat-Regular', fontWeight: 400, color: '#FFFFFF' }}>
                        {values && new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(getValueTotal(type, wallet))}
                      </h2>
                      <p style={{ margin: 0, fontSize: 13, fontFamily: 'Montserrat-Regular', fontWeight: 400, color: '#FFFFFF' }}>
                        {quantities && `${getBalanceTotal(type, wallet).toFixed(3)} ${type}`}
                      </p>
                    </div>
                  </div>
                  
                    {getWalletForProvider(wallet, type).map(wallet => (
                      <>  
                      {!isNaN((wallet.address).charAt(0)) &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 51, padding: '8px 16px' }}>
                          <div style={{ display: 'flex', alignItems: 'flex-start', width: '60%', color: '#FFFFFF', overflowWrap: 'anywhere', fontSize: '16px', fontFamily: 'Montserrat-Regular', fontWeight: 500 }}>
                            {wallet.address}
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '40%' }}>
                            <h2 style={{ margin: 0, fontSize: '16px', fontFamily: 'Montserrat-Regular', fontWeight: 400, color: '#FFFFFF' }}>
                              {values && new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(parseFloat(wallet.value))}
                            </h2>
                            <p style={{ margin: 0, fontSize: 13, fontFamily: 'Montserrat-Regular', fontWeight: 400, color: '#FFFFFF' }}>
                              {quantities && `${parseFloat(wallet.balance).toFixed(3)} ${type}`}
                            </p>
                          </div>
                        </div>
                      }
                      </>
                    ))}
                </>))}
              </AccordionDetails>
            : null }
          
        </Accordion>
      ))}
    </div>
  )
}

export default CurrencyCard;
