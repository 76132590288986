
import {
  Text
} from "@chakra-ui/react";
import {styled} from "@material-ui/core";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Modal from '@mui/material/Modal';
import {useWeb3React} from "@web3-react/core";
import {Auth} from "aws-amplify";
import React,{useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as MetamaskIcon} from '../Components/svgs/metamask-icon.svg';
import {ReactComponent as RainbowIcon} from '../Components/svgs/rainbow-icon.svg';
import {ReactComponent as TrustIcon} from '../Components/svgs/trust-icon.svg';
import {env} from '../environment';
import CoinbaseLogo from '../images/Coinbase.svg';
import MetamaskLogo from '../images/MetamaskLogo.svg';
import WalletConnectLogo from '../images/WalletConnectLogo.svg';
import {connectors} from "./Connectors";

const dumplingTitle = {
  padding: "6px",
  paddingBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "20px",
  fontWeight: 600,
  marign: "0px",
  color: "white"
}

const DumplingTitle = styled('h3')(({ theme }) => ({
  ...dumplingTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '24px'
  }
}));

const addresstitle = {
  paddingBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "12px",
  fontWeight: 600,
  color: "white",
}

const AddressTitle = styled('h3')(({ theme }) => ({
  ...addresstitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));

const providerTitle = {
  paddingBottom: '0px',
  marginBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 400,
  color: "white",
  whiteSpace: "nowrap",
  marginTop: "4px"
}

const ProviderTitle = styled('h3')(({ theme }) => ({
  ...providerTitle
}));

const providerModalTitle = {
  paddingBottom: '0px',
  marginBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "center",
  fontSize: "24px",
  fontWeight: 600,
  color: "white",
  whiteSpace: "nowrap",
  marginTop: "10px"
}

const ProviderModalTitle = styled('h3')(({ theme }) => ({
  ...providerModalTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '20px'
  }
}));

function getProvImage(provider) {
  const logo = (provider === "Metamask") ? MetamaskLogo : (provider === "walletConnect") ? WalletConnectLogo : (provider === "Coinbase") ? CoinbaseLogo : (provider === "coinbaseExchange") ? CoinbaseLogo : null
  const text = (provider === "Coinbase") ? "Wallet" : (provider === "coinbaseExchange") ? "Exchange" : null
  if (text === "Wallet" || text === "Exchange") {
  return (
    <div>
    <CBImg
    src={logo}
    alt=" Logo"
    />
    <div style={{float: "right", marginLeft: "6px", marginTop: "8px", fontSize: "16px"}}> {text} </div> 
    </div>
  )
  }
  else {
    return (
      <WalletImg
      src={logo}
      alt=" Logo"
      />

    )

  }
}

function getProvImageModal(provider) {
  const logo = (provider === "Metamask") ? MetamaskLogo : (provider === "walletConnect") ? WalletConnectLogo : (provider === "Coinbase" || "coinbaseExchange") ? CoinbaseLogo : null
  return (
    <WalletImgModal
    src={logo}
    alt=" Logo"
    />
  )
}

const modalStyle = {
  position: 'absolute',
  fontFamily: "Montserrat-Regular",
  top: '50%',
  left: '50%', 
  transform: 'translate(-50%, -50%)',
  minWidth: '300px',
  bgcolor: 'background.paper',
  borderRadius: 5,
  borderColor: "white",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#3A4056", 
  color: "white",
  textAlign: "center"
};


const dumplingSubTitle = {
  marginTop: "2px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "20px",
  color: "white",
}

const connectCard = {
  padding: 12,
  marginBottom: "20px", 
  borderRadius: "10px",
  color: "white",
  backgroundColor: "#252B43",
  fontSize: "18px"
}

const connectionImg = {
  float: "left",
  marginLeft: "10px"
}

const sectionTitle = {
  fontSize: "20px",
  marginBottom: '10px',
  fontFamily: "Montserrat-Regular",
  fontWeight: 200,
  color: "white"
}

const WalletImg = styled('img')(({ theme }) => ({
  borderRadius: '3px',
  height: '35px',
  width: '140px',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '140px',
  }
}));

const WalletImgModal = styled('img')(({ theme }) => ({
  borderRadius: '3px',
  height: '50px',
  width: '200px',
  [theme.breakpoints.down('sm')]: {
    height: '50px',
    width: '200px',
  }
}));


const CBImg = styled('img')(({ theme }) => ({
  borderRadius: '3px',
  height: '35px',
  width: '90px',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '90px',
  }
}));

const WalletConnectButton = styled(Button)(({ theme }) => ({
  width: '80px',
  borderRadius: 10,
  fontSize: '14px',
  [theme.breakpoints.down('sm')]: {
    width: '80px',
    height: '24px',
    fontSize: '10px',
  }
}));

export default function Accounts() {

  // const state = React.useContext(GlobalContext);
  const [authInfo, setAuthInfo] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [ walletType, setWalletType ] = React.useState(0);
  const [ prov, setProv] = React.useState([]);
  const [ addr, setAdd] = React.useState([]);
  const [ walletMap, setMap] = React.useState({});

  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };

  const {
    account,
    activate,
    deactivate,
    active
  } = useWeb3React();


  const handleSelect = (value) => {

    switch(value) {
      case 'coinbaseExchange': 
        window.location="https://www.coinbase.com/oauth/authorize?response_type=code&client_id=3107595ac5102a9dff25ffb67fd039277932915fd27a15d5919b362388eb2a89&redirect_uri=https://www.dumplingapp.io/callback&state=secret&scope=wallet:user:read,wallet:user:email,wallet:accounts:read,wallet:transactions:read&account=all"
        break;
      case 'Metamask': 
        activate(connectors.injected)
        break;
      case 'walletConnect': 
        activate(connectors.walletConnect)
        break;
      case 'Coinbase': 
        activate(connectors.injected)
        break;
      default: break;
    }

  };

  // const refreshState = () => {
  //   window.localStorage.setItem("provider", undefined);
  //   setMessage("");
  //   setSignature("");
  // };

  // const disconnect = () => {
  //   setOpen(false);
  //   refreshState();
  //   deactivate();
  // };

  const getWalletProviders = (walletList) => {
    let listOfProviders = [];
    walletList.forEach(wallet => {
      listOfProviders.push(wallet.provider);
    });

    return listOfProviders;
  }

  const getAllWalletNumbers = async () => {
    const auth = await Auth.currentSession();
    setAuthInfo({
      idToken: auth.idToken.jwtToken,
      accessToken: auth.accessToken.jwtToken,
      username: auth.idToken.payload.email
    });

    const options = {
      method: "GET",
      headers: {
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      }
    }

    const GET_USER_DEDUPED_WALLETS_ENDPOINT = `${env.server}/get_user_deduped_wallets?userId=${auth.idToken.payload.email}`
    await fetch(GET_USER_DEDUPED_WALLETS_ENDPOINT, options).then(res => res.json()).then(json => {
    
      let walletProviders = []
      let walletProvidersMap = {}
      let walletAddresses = []

      json.map((value) => {

        if (Number(value.address.charAt(0)) === 0) {
          walletAddresses.push(value.address);
        } 
        walletProvidersMap[value.address] = value.provider
  
        if (!walletProviders.includes(value.provider)) {
          walletProviders.push(value.provider);
        }
      })
      setProv(walletProviders)
      setAdd(walletAddresses)
      setMap(walletProvidersMap)
  
    });
  }
  


  useEffect(() => {
    getAllWalletNumbers()
    if (active) {
        setOpen(true)
      }
  }, [active])
  
 
  const sendData = () => {
    deactivate(connectors.walletConnect)
    navigate('/create',
        {
            state: {
              wallet_id: account,
              wallet_provider: walletType,
              cb_assets: []
            }
        });
}


function isConnected(provider) {
  if (prov.includes(provider)){
    return true 
  } else {
    return false
  }
}


function getWallets(object, provider) {
  // return Object.keys(object).find(key => object[key] === provider);

  return Object.keys(object).filter(function(v) {
    return object[v] === provider;
  });
}

const checkWallets = (providerList) => {
  let hasWallets = false;
  providerList.forEach(provider => {
    if(getWallets(walletMap, provider).length > 0 && provider !== 'coinbaseExchange') {
      hasWallets = true;
    }
  });
  return hasWallets;
}

async function disconnectAccount(wal){
  console.log('this is the response: ', getWallets(walletMap, 'coinbaseExchange'));
  const username = authInfo.username;
  var myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(
    {
      "userId" : username,
      "wallet_address" : wal
    }
  );
  console.log("raw", raw)

  var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": authInfo.idToken,
      "accessTokenStr": authInfo.accessToken
    },
    body: raw,
    redirect: 'follow'
  };

  const DISCONNECT_WALLETS_ENDPOINT = `${env.server}/disconnect_wallet`
  await fetch(DISCONNECT_WALLETS_ENDPOINT, requestOptions).then(res => res.json()).then(json => {
    navigate('/account', {state: {isAccount: true }})
    window.location.reload()
    return json
  }).catch(error => console.log('error', error));

}


  return (
   <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: '#171e37', width: '358px'}}
    >
      <div style={{ marginBottom: "96px", width: 358 }}>

        <h1 style={sectionTitle}>Connect accounts</h1>
        <h3 style={dumplingSubTitle}> Select which exchanges & wallets you would like to connect.</h3>

        <h1 style={sectionTitle}>Wallets</h1>
        <Card style={connectCard}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                <ProviderTitle> Connect wallets </ProviderTitle>
                <p style={{ fontFamily: 'Montserrat-Regular', fontSize: 10, fontWeight: 400, marginTop: 5 }}>
                  Powered by WalletConnect
                </p>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 210, height: 31 }}>
                  <div style={{ backgroundColor: 'rgba(255,255,255,0.1)', display: 'flex', width: 50, height: 'inherit', justifyContent: 'center', alignItems: 'center' }}>
                    <MetamaskIcon/>
                  </div>
                  <div style={{ backgroundColor: 'rgba(255,255,255,0.1)', display: 'flex', width: 50, height: 'inherit', justifyContent: 'center', alignItems: 'center' }}>
                    <RainbowIcon/>
                  </div>
                  <div style={{ backgroundColor: 'rgba(255,255,255,0.1)', display: 'flex', width: 50, height: 'inherit', justifyContent: 'center', alignItems: 'center' }}>
                    <TrustIcon/>
                  </div>
                  <div style={{ backgroundColor: '#52596D', border: '2px solid rgba(255,255,255,0.25)', borderRadius: 18, width: 44, height: 31, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontFamily: 'Montserrat-Regular', fontSize: 12, fontWeight: 600 }}>+150</p>
                  </div>
                </div>
              </div>
              <WalletConnectButton  startIcon={<AddCircleIcon />}  sx={{color: "#3CA1EB", marginTop: "16px", width: '30%' }} variant="text" disableElevation      
                onClick={() => {
                    setProvider('walletConnect');
                    setWalletType('walletConnect');
                    handleSelect('walletConnect');
                  }} >
                  Connect
              </WalletConnectButton>
            </div>
        </Card>

        { !isConnected('coinbaseExchange')
          ? (<>
            <h1 style={sectionTitle}>Exchanges</h1>
            <Card style={connectCard}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <ProviderTitle> {getProvImage('coinbaseExchange')} </ProviderTitle>
                  </div>
                    <WalletConnectButton  startIcon={<AddCircleIcon />}  sx={{color: "#3CA1EB", marginTop: "16px", width: '30%' }} variant="text" disableElevation      
                      onClick={() => {
                          setProvider('coinbaseExchange');
                          setWalletType('coinbaseExchange');
                          handleSelect('coinbaseExchange');
                        }} >
                        Connect
                    </WalletConnectButton>
                </div>
            </Card>
          </>)
          : null }

        { checkWallets(prov) && (<h1 style={sectionTitle}>Your connections</h1>) }
        {prov.map((provider, index) => {
         const provWal = getWallets(walletMap, provider);
         
          return provWal.length > 0 && (<>
          <Card style={connectCard} >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                <ProviderTitle> {getProvImage(provider)} </ProviderTitle>
              </div>
            </div>
          
            {provWal.map((wal, index) => {
        
              return (
                
                <List sx={{ pt: 0 }}>

                <ListItem 
                 sx={{backgroundColor: (index % 2 === 0 && provider !== "coinbaseExchange") ? "#3B4055" : "#252B43", marginLeft: "-10px", width: 358, maginRight: "10px"}}

                >
                {provider !== "coinbaseExchange" &&
                 <div style={{width: "50%", overflowWrap: "break-word"}}>
                <AddressTitle> {wal} </AddressTitle>
                </div>
               }
                </ListItem>

                <ListItemSecondaryAction sx={{ right: 10 }}>
                  {isConnected(provider) &&
                  <WalletConnectButton startIcon={<RemoveCircleIcon />}  sx={{color: "#3CA1EB", marginTop: "-30px", marginRight: "10px"}} variant="text" disableElevation        
                    onClick={() => {
                      disconnectAccount(wal)
                      }} > 
                    Disconnect
                  </WalletConnectButton>
                  }
                </ListItemSecondaryAction>
                </List>
          
               )
              
            })}

          </Card>
        </>);
      })}


<Modal
open={isOpen}
onClose={handleClose}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
>
     <Box sx={modalStyle}>

     <CheckIcon style={{marginBottom: "0px", color: "#02EDBA"}} fontSize="large"/>
     <ProviderModalTitle> {getProvImageModal(walletType)} </ProviderModalTitle>

         <Text> You can now add assets to your dumplings from this wallet.
         </Text>

         <Button onClick={() => { sendData() }} style={{marginBottom: "10px", height: "44px", backgroundColor: "#0887E2", color: 'white', width: "100%", borderRadius: "20px", textTransform: "none", fontFamily: "Montserrat-Regular", }} >Continue</Button>

     </Box>
   </Modal>
   </div>
      </Box>
  );
}



