// import { styled } from '@mui/material/styles';
import { styled } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import '@uniswap/widgets/fonts.css';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  useNavigate
} from 'react-router-dom';
import DashboardDumplingCard from '../Components/DashboardDumplingCard';
import SharedDumplingCard from '../Components/SharedDumplingCard';
import SortDrawer, { sortAlphabetical, sortBalance, sortMostRecent } from '../Components/SortDrawer.js';
import { ReactComponent as SortIcon } from '../Components/svgs/sort-icon.svg';
import { env } from '../environment';
import { getAllWallets, getEverythingBalance } from "../Utils/getAllWallets.js";
import { NoDumplingH1, SortButton } from './Dumpling.js';

const chartdata = [
  { name: "ETH", value: 100 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];
const COLORS = ['#9D4FFF', '#02EDBA', '#FF50F9', '#9C4FFF'];

const dumplingCount = {
  marginTop: "2px",
  paddingLeft: "8px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "10px",
  fontWeight: 600,
  marginBottom: "0px",
  color: "white"
}

const dumplingSubTitle = {
  marginTop: "2px",
  paddingLeft: "8px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "0px",
  color: "white",
}

const DumplingSubTitle = styled('h3')(({ theme }) => ({
  ...dumplingSubTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));


const dumplingSubSubTitle = {
  marginTop: "0px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  color: "white",
  float: "left"
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '300px',
  bgcolor: 'background.paper',
  borderRadius: 5,
  borderColor: "white",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#3F4B5C",
  color: "white",
  fontFamily: "Montserrat-Regular",
};

const dumplingTitle = {
  padding: "6px",
  paddingBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 600,
  marginBottom: "0px",
  marginTop: '0px',
  color: "white",
  whiteSpace: "nowrap"
}

const DumplingTitle = styled('h3')(({ theme }) => ({
  ...dumplingTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px'
  }
}));


const totalBalanceStyle = {
  fontFamily: "Montserrat-Regular",
  color: "white",
  fontSize: "40px",
  fontWeight: 200,
  textAlign: "left",
  marginBottom: "0px",
}

const TotalBalance = styled('h2')(({ theme }) => ({
  ...totalBalanceStyle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '24px'
  }
}));

const PieContainer = styled('div')(({ theme }) => ({
  width: '15%',
  height: 200,
  [theme.breakpoints.down('xs')]: {
    width: '20%',
    height: 100
  }
}));

function SharedDumplings() {

const navigate = useNavigate();
const [authInfo, setAuthInfo] = useState();
const [sharedDumplings, setSharedDumplings] = useState([]);
const [ everythingTotal, setEverythingTotal ] = useState([]);
const [ walletValues, setWalletValues ] = useState([]);
const [isOpenShared, setOpenShared] = React.useState(false);
const handleOpenShared = () => setOpenShared(true);
const handleCloseShared = () => setOpenShared(false);
const [sharedWith, setSharedWith] = React.useState('');
const [isLoading, setIsLoading] = useState(false);

const [sort, setSort] = useState('Most recent');
const [openSort, setOpenSort] = useState(false);
const toggleSort = () => setOpenSort(!openSort);

const getAuthUser = async () => {
  await Auth.currentSession()
    .then(res => {
      setAuthInfo({
        idToken: res.idToken.jwtToken,
        accessToken: res.accessToken.jwtToken,
        username: res.idToken.payload.email
      });
    })
    .catch(err => {
      console.log('could not get user...')
    })
}

useEffect(() => {

  sync();
  getAuthUser();
  const totalWalletVals = getAllWallets()

     totalWalletVals.then(function(result) {
        setWalletValues(result)
      });

    getAllWallets();
    getSharedDumplings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getSharedDumplings() {
    setIsLoading(true);
    let auth = authInfo;
    let options = {}
    if(authInfo !== undefined){
    options = {
      headers: {
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      }
    }
    }
    if(!authInfo || authInfo === undefined){
      auth = await Auth.currentSession();
      options = {
        headers: {
          "Authorization": auth.idToken.jwtToken,
          "accessTokenStr": auth.accessToken.jwtToken
        }
      }
    }
    await fetch(`${env.server}/get_shared_dumplings?userId=${auth.username || auth.idToken.payload.email}`, options).then(res => res.json()).then(json => {

        console.log("shared dumplings", json)

        if (json.statusCode === "404"){
          setSharedDumplings([])
        } else {
          if(sort === 'Most recent'){
            setSharedDumplings(sortMostRecent(json));
          }
          else if(sort === 'A-Z'){
            setSharedDumplings(sortAlphabetical(json));
          }
          else if(sort === 'Highest balance'){
            setSharedDumplings(sortBalance(json, 'high'));
          }
          else if(sort === 'Lowest balance'){
            setSharedDumplings(sortBalance(json, 'low'));
          }
        }
        setIsLoading(false);
        checkNewSharedDumplings(json)
      }).catch(err => setIsLoading(false));
  }

  async function handleAcceptShared(id) {
      var myHeaders = new Headers();
    
      myHeaders.append("Content-Type", "application/json");
    
      var raw = JSON.stringify({
        "dumplingId": id,
        "userId": authInfo.username
      });
    
      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": authInfo.idToken,
          "accessTokenStr": authInfo.accessToken
        },
        body: raw,
        redirect: 'follow'
      };
    
      await fetch(`${env.server}/accept_dumpling`, requestOptions).then(res => res.json()).then(json => {
          
        //console.log("accept", json)
        handleCloseShared()
        getSharedDumplings()
        //setOpen(true);// create new modal  for succesfful change
    
      }).catch(error => console.log('error', error));
    
  }

  async function handleIgnoreShared(id, senderId) {
    var myHeaders = new Headers();
  
    myHeaders.append("Content-Type", "application/json");
  
    var raw = JSON.stringify({
      "receiverId" : authInfo.username,
      "senderId" : senderId,
      "dumplingId" : id,
      "actionType" : "Ignore"
    });
  
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      },
      body: raw,
      redirect: 'follow'
    };
    await fetch(`${env.server}/ignore_dumpling`, requestOptions).then(res => res.json()).then(json => {
        
      //console.log("ignore", json)
      handleCloseShared()
      getSharedDumplings()
    }).catch(error => console.log('error', error));
  
}


  function checkNewSharedDumplings(sharedDumplings){
    var hasFalseKeys = sharedDumplings.map(v => !!v.flag);
    let checker = arr => arr.every(Boolean);
    let hideModal = checker(hasFalseKeys)

     if (hideModal === false) {
      setOpenShared(true)
     } 
  }

    const sync = () => {
    const ebalance = getEverythingBalance(walletValues)
    console.log("ebalance", ebalance)
    ebalance.then(function(result) {
    console.log(result)
    setEverythingTotal(result)
  });

}

const getSingleDumpling = (dumplingId, dumplingTotal, dumplingAssets, isEverything = null, dumplingOwner, dumplingName, quantities, values) => {

  navigate('/singleDumpling',
      {
          state: {
            dumpling_id: dumplingId,
            myTotal: dumplingTotal,
            assets: dumplingAssets,
            dumplingOwner: dumplingOwner,
            dumpling_name: dumplingName,
            quantities: quantities,
            values: values,
            shared: true
          }
      });
}


  return (
    <div>

    <div>
        { isLoading
    ? (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
        <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px' }} variant="text" width={360} height={40}/>
        <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px' }} variant="text" width={360} height={40}/>
        <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px', marginBottom: '16px' }} variant="rectangular" width={360} height={300} />
        <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px', marginBottom: '16px' }} variant="rectangular" width={360} height={136} />
      </div>
    ) : (
    <div style={{ marginBottom: "100px", maxWidth: '1200px'}}>

      {sharedDumplings && sharedDumplings.length > 0 &&

      
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '17px' }}>
          <h3 style={dumplingCount}>
            {sharedDumplings.length} Dumpling
          </h3>
          <SortButton variant="contained" onClick={() => toggleSort()}>
            <span style={{ fontSize: '10px' }}>
              {sort} <SortIcon />
            </span>
          </SortButton>
        </div>

         { sharedDumplings !== undefined && sharedDumplings.length > 0 &&
            <div>
              {sharedDumplings.map((dumpling) => {
               //console.log("dumpling", dumpling)
                let total = 0;
                let roundedTotal = 0;
                if (dumpling.flag) {
                return  (
                  <SharedDumplingCard dumpling={dumpling} roundedTotal={roundedTotal} total={total} goToDumpling={getSingleDumpling}/>
                )
                }
                })}
            </div>
          }
        </div>
      }
      {(!sharedDumplings || sharedDumplings.length === 0) &&
        <div style={{ display: 'flex', maxWidth: '100%', justifyContent: 'center'}}>
          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '400px' }}>
            <NoDumplingH1> No dumplings have been shared with you. </NoDumplingH1>
          </div>
        </div>
      }
    </div>
    ) }
    </div>


    {sharedDumplings.map((dumpling) => { 
    if (dumpling.flag === false) {
      const date = new Date(dumpling.created_at).toLocaleDateString()
      return (
    <Modal
    open={isOpenShared}
    onClose={handleCloseShared}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box sx={modalStyle}>

    <div style={{display: "block"}}>
      <h1 style={{marginBottom: "0px"}}>{`You've been invited`}</h1>
      </div>

          <div>
          <h3> {date} </h3>
          <div>
          <h3> {dumpling.user_id} sent you an invitation for the following dumpling 
        <ul style={{paddingLeft: "0px"}}> <li style={{listStyleType: "none"}} >{dumpling.name}</li> </ul>
        </h3>
        </div>
        <div>
      <Button onClick={() => { handleAcceptShared(dumpling.id) }} style={{marginBottom: "10px", height: "44px", backgroundColor: "#0887E2", color: 'white', width: "100%", borderRadius: "20px", textTransform: "none", fontFamily: "Montserrat-Regular", }} >Accept</Button>
      <Button variant="text" onClick={() => { handleIgnoreShared(dumpling.id, dumpling.user_id)}} style={{marginBottom: "10px", color: 'white', width: "100%", borderRadius: "10px", textTransform: "none", fontFamily: "Montserrat-Regular", }} >Ignore</Button>
      </div>
        </div>

    </Box>
    </Modal>
    )
    } 
    }) 
  }
      <SortDrawer openSort={openSort} setSort={setSort} toggleSort={toggleSort} sortType={sort}/>
    </div>
  );
}

export default SharedDumplings;

