// import { CONTACT_ABI, CONTACT_ADDRESS } from './config';
// import { styled } from '@mui/material/styles';
import {Box,Button,List,ListItem,ListItemText,ListSubheader,Modal,styled} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import CheckIcon from '@mui/icons-material/Check';
import {Drawer,ListItemButton} from '@mui/material';
import Typography from '@mui/material/Typography';
import '@uniswap/widgets/fonts.css';
import {Auth} from 'aws-amplify';
import * as React from 'react';
import {useEffect,useState} from 'react';
import {useLocation,useNavigate} from 'react-router-dom';
import {env} from '../environment';
import {getAllWallets} from "../Utils/getAllWallets.js";

const currencyStyle = {
    display: 'inline',
    fontSize: "14px",
    marginRight: "50px"
};

const dumplingTitle = {
  padding: "6px",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 600,
  marginBottom: "0px",
  color: "white",
  whiteSpace: "nowrap"
}

const providerTitle = {
  padding: "6px",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 600,
  marginBottom: "0px",
  color: "#00578E",
  whiteSpace: "nowrap"
}

const headerAssets= {
  fontSize: "14px",
  marginRight: "30px",
  color: "#00578E",
  display: "block",
  inlineSize: "min-content",
  float: "right",
  marginTop: "-32px"
}

const assetValue= {
  float: "right",
  marginRight: "20px"

}

const currValue= {
  marginRight: "20px"

}

const title = {
  fontSize: "40px"
}

const sendButton = {
  height: 48,
  marginBottom: 25,
  borderRadius: 10,
  fontSize: "16px",
  marginTop: "20px",
  marginLeft: "10px",
  backgroundColor: "white", 
  color: '#232933', 
}

const transactButton = {

  marginBottom: "10px",  
  backgroundColor: "white", 
  color: '#232933', 
  width: "100%", 
  borderRadius: "10px"

}

const ManageDumpling = ({ openDrawer, toggleDrawer }) => {
  const [ allWallets, setAllWallets ] = React.useState([]);
  const [checked, setChecked] = React.useState([]);

 const handleToggle = (value) => () => {

      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      console.log("newChecked", newChecked)
      setChecked(newChecked);
    };

  //  const handleToggle = (event) => {
  //    console.log("event", event)
  //   // setChecked(event.target.checked);
  // };
  const navigate = useNavigate();
  const [authInfo, setAuthInfo] = useState();

  const [ openEditModal, setOpenEditModal ] = React.useState(false);
  const toggleEdit = () => setOpenEditModal(!openEditModal);

  const [ openDeleteModal, setOpenDeleteModal ] = React.useState(false);
  const handleOpenDelete = () => setOpenDeleteModal(true);
  const handleCloseDelete = () => setOpenDeleteModal(false);

  const [ openCompletedDeleteModal, setOpenCompletedDeleteModal ] = React.useState(false);
  const handleOpenCompletedDelete = () => setOpenCompletedDeleteModal(true);
  const handleCloseCompletedDelete = () => setOpenCompletedDeleteModal(false);

  const [isOpen, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isOpenShared, setOpenShared] = React.useState(false);
  const handleOpenShared = () => setOpenShared(true);
  const handleCloseShared = () => setOpenShared(false);

const [myDumpling, setMyDumpling] = useState(0);
const [walletInfo, setWalletInfo] = useState(0);

const [ walletValues, setWalletValues ] = useState([]);

const getDumpling = async () => {
  const auth = await Auth.currentSession();
  setAuthInfo({
    idToken: auth.idToken.jwtToken,
    accessToken: auth.accessToken.jwtToken,
    username: auth.idToken.payload.email
  });
  const options = {
    headers: {
      "Authorization": auth.idToken.jwtToken,
      "accessTokenStr": auth.accessToken.jwtToken
    }
  }

  await fetch(`${env.server}/get_dumpling?userId=${auth.idToken.payload.email}&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
    setMyDumpling(json)
    setName(json.name)
    setWalletInfo(json.wallet_info[0].currencies[0])
    setChecked([json.wallet_info[0]]);
    getAllWalletNumbers()
  });
}

  useEffect(() => {
    const totalWalletVals = getAllWallets()
    totalWalletVals.then(function(result) {
      setWalletValues(result)
    });

    getDumpling();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%', 
  transform: 'translate(-50%, -50%)',
  minWidth: '300px',
  bgcolor: 'background.paper',
  borderRadius: 5,
  borderColor: "white",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#3F4B5C", 
  color: "white",
  fontFamily: "Montserrat-Regular",
};

const modalStyleCenter = {
  position: 'absolute',
  top: '50%',
  left: '50%', 
  transform: 'translate(-50%, -50%)',
  minWidth: '300px',
  bgcolor: 'background.paper',
  borderRadius: 5,
  borderColor: "white",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#3F4B5C", 
  color: "white",
  fontFamily: "Montserrat-Regular",
  textAlign: "center"
};

const header ={
  fontSize: "14px",
  marginRight: "30px",
  color: "#00578E",
  display: "block",
  inlineSize: "min-content",
  textAlign: "left",
  whiteSpace: "nowrap",
  marginLeft: "20px"

}

const { state } = useLocation();

const [name, setName] = React.useState('');
const [sharedWith, setSharedWith] = React.useState('');

  async function addDumplingContents(){
    var myHeaders = new Headers();

    // let token = window.localStorage.getItem('CognitoIdentityServiceProvider.43pbrj3geo8pbu4j649lcm65c6.42ee8221-234c-459a-95b3-f07c442e4f90.accessToken')
    // console.log("access token", token)
    


    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", token);

    var raw = JSON.stringify({
      "dumplingId": state.dumpling_id,
      "wallets": checked,
      "userId": authInfo.username, 
    });

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      },
      body: raw,
      redirect: 'follow'
    };

    await fetch(`${env.server}/replace_wallets`, requestOptions)
      .then(response => response.text())
      .then(result => console.log("added result", result))
      .catch(error => console.log('error', error));

  }


  async function deleteDumpling() {
    const options = {
      headers: {
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      }
    }

    await fetch(`${env.server}/delete_dumpling?userId=${authInfo.username}&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
      handleOpenCompletedDelete();
    });
  }

  async function getAllWalletNumbers() {
    const options = {
      headers: {
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      }
    }

    await fetch(`${env.server}/get_user_deduped_wallets?userId=${authInfo.username}`, options).then(res => res.json()).then(json => {
      setAllWallets(json);
    });
  }


  const EditMenuButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    color: 'white',
    width: '100%',
    fontSize: '16px',
    fontFamily: 'Lexend-regular',
    paddingLeft: '20px',
    paddingRight: '20px',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)'
    }
  }));

  const inputStyles = {
    border: '2px solid white',
    borderRadius: '10px',
    backgroundColor: 'transparent',
    paddingLeft: '10px',
    fontSize: '16px',
    color: 'white',
    minHeight: '44px'
  }

  const list = (anchor) => (
    <Box
      sx={{ 
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        backgroundColor: '#252B43',
        borderRadius: '20px 20px 0px 0px',
        border: '1px solid rgba(255,255,255,0.25)',
        color: 'white'
      }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
        <ListSubheader style={{ width: '100%', display: 'flex', fontFamily: "Montserrat-Regular", justifyContent: 'space-between', alignItems: 'center', color: 'white', fontSize: '14px', fontWeight: 600 }}>
          What would you like to do? <Close onClick={toggleDrawer}/>
        </ListSubheader>
        {['Modify', 'Share', 'Delete'].map((text, index) => (
          <ListItem  key={text} disablePadding>
            <ListItemButton sx={{ paddingLeft: '0px'}} onClick={() => {
              switch(text) {
                case 'Modify': 
                  navigate('/manageAssets', { state: { dumpling_id: state.dumpling_id, username: authInfo.username }});
                  break;
                case 'Share': navigate('/manageSharing',
                  {
                      state: {
                        dumpling_id: state.dumpling_id,
                        username: authInfo.username
                      }
                  });
                  break;
                case 'Delete': 
                  handleOpenDelete();
                  break;
                default: break;
              }
            }}>
              <ListItemText primary={<Typography style={{ fontFamily: "Montserrat-Regular" }}>{text}</Typography>}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Drawer PaperProps={{ sx: { bgcolor: 'transparent' } }} anchor="bottom" open={openDrawer} onClose={toggleDrawer}>
        {list('bottom')}
      </Drawer>
      <Modal
        open={openDeleteModal}
        onClose={(event, backdropClick) => {handleCloseDelete()}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
            <div style={{display: "flex", justifyContent: 'space-between'}}>
              <h1 style={{marginBottom: "10px", marginTop: '0px', fontSize: '20px'}}>Are you sure?</h1>
              <Close onClick={handleCloseDelete}/>
            </div>
            <div>Deleting this dumpling might affect other dumplings.</div>
            <Button style={{ marginTop: '40px',  textTransform: "none", fontFamily: 'Montserrat-Regular', borderRadius: '30px', backgroundColor: '#0887E2', color: 'white', height: '44px', fontSize: '16px' }} variant="contained" onClick={deleteDumpling}>
              Delete
            </Button>
            <Button style={{ marginTop: '10px', marginBottom: '30px',  textTransform: "none", fontFamily: 'Montserrat-Regular', borderRadius: '30px', color: 'white', border: '2px solid #0887E2', height: '44px', fontSize: '16px' }} onClick={handleCloseDelete}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
             open={openCompletedDeleteModal}
             onClose={handleCloseCompletedDelete}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
          >
          <Box sx={modalStyleCenter}>
          <CheckIcon style={{marginBottom: "0px", color: "#02EDBA"}} fontSize="large"/>

            <h3 style={{marginBottom: "0px", textAlign: 'center'}}>{`Dumpling Deleted!`}</h3>
              
            <h3> You have deleted {name} 
            </h3>
    
            <Button href='/dashboard' style={{ marginTop: '40px', textTransform: "none", fontFamily: 'Montserrat-Regular', width: "100%", borderRadius: '30px', backgroundColor: '#0887E2', color: 'white', height: '44px', fontSize: '16px' }} variant="contained">
              Ok
            </Button>
          </Box>
          </Modal>
    </>
  );
}

export default ManageDumpling;

