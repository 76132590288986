import {makeStyles} from '@material-ui/core/styles';
import detectEthereumProvider from '@metamask/detect-provider';
import {ChevronLeft} from "@mui/icons-material";
import {Container} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useWeb3React} from "@web3-react/core";
import {Auth} from 'aws-amplify';
import {ethers,providers} from 'ethers';
import * as React from 'react';
// import './App.css';
import {useEffect,useState} from 'react';
import {
  useLocation,useNavigate
} from 'react-router-dom';
import {env} from '../environment';
import {connectors} from "./Connectors";


const useStyles = makeStyles({
    select:{
      border: "2px solid white",
      color: "white",
      ".MuiSvgIcon-root": {
        fill: "white"
      }
    },

    selectMenu: {
        "& ul": {
            backgroundColor: "#3A4056",
            color: "white",
            fontFamily: "Montserrat-Regular",
        },
        "& li": {
            fontSize: 18,
            fontFamily: "Montserrat-Regular",
            display: "block",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    },
    
});

const connectWalletButton = {
  width: '100%',
  height: '46px',
  marginTop: '10px',
  borderRadius: 30,
  backgroundColor: '#0887E2',
  color: '#FFFFFF',
  fontSize: '16px',
  weight: 700,
  fontFamily: "Montserrat-Regular",
  textTransform: "none",
}


function TransactSource() {

  const classes = useStyles();

  let navigate = useNavigate(); 
  const [account, setAccount] = useState({
    address: '',
    provider: ''
  })
  const { state } = useLocation();

  const [ allWallets, setAllWallets ] = React.useState([]);
  const [ activeWallet, setActiveWallet] = React.useState('');
  // const [ activeProvider, setActiveProvider] = React.useState('');


  // const setProvider = (type) => {
  //   window.localStorage.setItem("uniswap-provider", type);
  // };

  const {
    // library,
    // chainId,
    // selectedAccount,
    activate,
    deactivate,
    // active
  } = useWeb3React();

  // const refreshState = () => {
  //   window.localStorage.setItem("provider", undefined);
  // };

  // const disconnect = () => {
  //   // setOpen(false);
  //   refreshState();
  //   deactivate();
  // };
  
  const handleOpen = (event) => {
    
    deactivate()


  }

  const handleChange = (event) => {
    // console.log(event)
    // setActiveProvider(event.target.value.provider)
    // console.log("activeProvider", activeProvider)
    console.log(event.target.value.provider)

    if (event.target.value.provider === "Coinbase Exchange"){
      activate(connectors.injected); 
    }
    if (event.target.value.provider === "walletConnect"){
      activate(connectors.walletConnect);
    }
    if (event.target.value.provider === "Metamask"){
      activate(connectors.injected);
    }


    setActiveWallet(event.target.value);    
    const JsonRpcEndpoint = `https://mainnet.infura.io/v3/`
    const JsonRpcProvider = new providers.JsonRpcProvider(JsonRpcEndpoint);
    const provider = new ethers.providers.Web3Provider(JsonRpcProvider);

    setAccount({    
      address: '',
      provider: provider
    });
  };

  const getDedupedWallets = async () => {
    const auth = await Auth.currentSession();
    const options = {
      headers: {
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      }
    }

    await fetch(`${env.server}/get_user_deduped_wallets?userId=${auth.idToken.payload.email}`, options).then(res => res.json()).then(json => {
      let walletAddresses = [];
      json.map((value) => {
        if (Number(value.address.charAt(0)) === 0) {
          walletAddresses.push(value)
        }
      })
      setAllWallets(walletAddresses);
    });
  }

  useEffect(() => {
    getDedupedWallets();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function connectWallet() {
    const ethereumProvider = await detectEthereumProvider();

    console.log("ethereumProvider", ethereumProvider)

    if (ethereumProvider) {
      const address = await window.ethereum.request({
        method: 'eth_requestAccounts'
      })

      setAccount({
        address: address[0],
        provider: ethereumProvider
      })
    }

    const source = activeWallet.address
    const transactionType = state.transactionType
    const provider = account.provider


      navigate(`/transact/${state.transactionType}`, {state: {transactionType: transactionType, source: source }})
    
  }
  let walletAddresses = []

  allWallets.map((value, index) => (
    walletAddresses.push(value.address)
  ))

  // const classes = useStyles();

  // console.log("ldld", account.provider === "")

  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        backgroundColor='#171E37'
        color='white'
      >


      <Container sx={{ height: '100vh', marginBottom: '70px' }}>
  
      <div style={{ width: '100%' }}>
            <ChevronLeft onClick={() => navigate(-1)} style={{ float: 'left', position: 'relative', left: 16, color: '#FFFFFF' }}/>
            <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Transact</h1>
            <hr style={{ width: '100%', borderColor: 'rgba(255,255,255,0.2)' }}/>
          </div>

          <Container style={{ marginTop: '20px' }}>

          <h1 style={{ textAlign: 'left', width: '100%', fontFamily: "Montserrat-Regular" }}>Choose a source</h1>
         <div style={{display:"flex", flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
          <h3 style={{ textAlign: 'left', width: '100%', fontFamily: "Montserrat-Regular" }}>Select your Wallet</h3>
        <FormControl fullWidth sx={{marginBottom: "20px", borderColor: "white"}}>


       {/* <InputLabel sx={{color: "white"}} id="demo-simple-select-label"></InputLabel> */}
       <Select
         // sx={[classes.select]}
         className={classes.select}
         sx={{ fontFamily: "Montserrat-Regular", borderRadius: "18px", color: "white", ".MuiSvgIcon-root": {fill: "white"}}}
         MenuProps={{ classes: { paper: classes.selectMenu } }}
         variant="outlined"
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={activeWallet}
         label="Age"
         onChange={handleChange}
         onOpen={handleOpen}
       >

            {allWallets.map((value, index) => {
             return(
             <MenuItem key={index} value={value}>
               {value.address}
             </MenuItem>
             )})}

             </Select>
           </FormControl>

            <Button color="secondary" style={connectWalletButton} onClick={connectWallet}>Connect Wallet</Button>
            <h3 style={{ fontFamily: "Montserrat-Regular", textAlign: 'left', width: '100%', fontSize: "12px" }}>Note: exchanges are not supported at this time.</h3>
          </div>
          </Container>
        
        </Container>
      </Box>
    </div>
  );
}

export default TransactSource;