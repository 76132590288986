import {styled} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {Close} from '@material-ui/icons';
import {ChevronLeft} from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import {Container} from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Amplify,{Auth} from 'aws-amplify';
import * as React from 'react';
import {useEffect,useState} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import {env} from '../environment';
import {NoDumplingH1,NoDumplingH2,NoDumplingH3,noDumplingTextCard} from './Dumpling.js';


Amplify.configure({
    Auth: {
      userPoolId: 'us-east-1_8MrfRwh7H', //UserPool ID
      region: 'us-east-1',
      userPoolWebClientId: '43pbrj3geo8pbu4j649lcm65c6' //WebClientId
    }
  });
  
    const useStyles = makeStyles({
      select:{
      border: "none",
      color: "white",
      "before": {
        border: "none",
      }
      },
      selectMenu: {
        "& ul": {
            backgroundColor: "#3A4056",
            color: "white",
            fontFamily: "Montserrat-Regular",
        },
        "& li": {
            fontFamily: "Montserrat-Regular",
            display: "block",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
      },
  
    });
  
    export const cardStyle = {
      padding: "20px", 
      marginBottom: "20px", 
      borderRadius: "20px",
      marginLeft: "0px",
      color: "white",
      backgroundColor: "#3A4056",
      fontSize: "18px",  
      fontFamily: "Montserrat-Regular",
      fontWeight: 400,
    }
  
  const SharedWith = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [ dumpling, setDumpling ] = useState();
    const [ authInfo, setAuthInfo ] = React.useState();
    const [isOpenShared, setOpenShared] = React.useState(false);
    const handleOpenShared = () => setOpenShared(true);
    const handleCloseShared = () => setOpenShared(false);
  const [sharedWith, setSharedWith] = React.useState('');
  const [sharedWithFname, setSharedWithFname] = React.useState('');
  const [sharedWithLname, setSharedWithLname] = React.useState('');
  const [sharedWithCount, setSharedWithCount] = React.useState(0);
  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [permisison, setPermission] = React.useState('view');
  const classes = useStyles();
  
  const [ openDeleteModal, setOpenDeleteModal ] = React.useState(false);
    const handleOpenDelete = () => setOpenDeleteModal(true);
    const handleCloseDelete = () => {
      setPermission("view");
      setOpenDeleteModal(false);
    }
  
    const [removeDumplingID, setremoveDumplingID] = React.useState('');
    const [removePersonID, setremovePersonID] = React.useState('');
  
  // const handleChange = (event) => {
  //   setPermission(event.target.value);
  //   console.log("event", event)
  //   if (event === 'remove') {
  //     console.log("remove")
  //   }
  // };
  
  async function removeSharedWith(){
      var myHeaders = new Headers();
    
      myHeaders.append("Content-Type", "application/json");
  
      console.log("TO REMOVE", removePersonID.receiver_id, removeDumplingID)
    
      var raw = JSON.stringify({
        "receiverId" : removePersonID.receiver_id,
        "senderId" : authInfo.username,
        "dumplingId" : removeDumplingID,
        "actionType" : "Ignore"
      });
    
      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": authInfo.idToken,
          "accessTokenStr": authInfo.accessToken
        },
        body: raw,
        redirect: 'follow'
      };
    
      await fetch(`${env.server}/ignore_dumpling`, requestOptions).then(res => res.json()).then(json => {
          
        console.log("ignore", json)
        handleCloseDelete()
        window.location.reload()
        //setOpen(true);// create new modal  for succesfful change
    
      }).catch(error => console.log('error', error));
    
  }
  
  
  
  const handleChange = (person, event, dumplingID) => {
    console.log("test", person, event.target.value, dumplingID)
    setPermission(event.target.value);
  
    if (event.target.value === 'remove') {
      setremoveDumplingID(dumplingID)
      setremovePersonID(person)
  
      handleOpenDelete()
    }
  };
  
  const modalStyle = {
    top: '50%',
    left: '50%', 
    position: 'absolute',
    fontFamily: "Montserrat-Regular",
    transform: 'translate(-50%, -50%)',
    width: "400",
    bgcolor: 'background.paper',
    borderRadius: 5,
    borderColor: "white",
    boxShadow: 24,
    p: 4,
    backgroundColor: "#3A4056", 
    color: "white",
    textAlign: 'center'
  };
  
  /*
  async function shareDumpling(){
    var myHeaders = new Headers();
    
    myHeaders.append("Content-Type", "application/json");
  
    var raw = JSON.stringify({
      "dumplingId": state.dumpling_id,
      "receiverID": sharedWith,
      "sentFirstName": sharedWithFname,
      "sentLastName": sharedWithLname,
      "senderID": authInfo.username,
      "senderFirstName": firstName,
      "senderLastName": lastName,
    });
  
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      },
      body: raw,
      redirect: 'follow'
    };
  
    await fetch(`${env.server}/share_dumpling`, requestOptions).then(res => res.json()).then(json => {
        
      console.log("TEST SHARE", json)
      handleOpenShared();
  
    }).catch(error => console.log('error', error));
  
  }
  */
  const sendButton = {
    height: 48,
    width: "100%",
    marginBottom: 200,
    borderRadius: 25,
    fontSize: "16px",
    fontFamily: "Montserrat-Regular",
    marginTop: "20px",
    backgroundColor: "#0887E2", 
    color: 'white', 
    textTransform: "none"
  }
  
  
  const dumplingTitle = {
    paddingBottom: '0px',
    // marginTop: "84px",
    fontFamily: "Montserrat-Regular",
    textAlign: "left",
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "0px",
    marginTop: '0px',
    color: "white",
    whiteSpace: "nowrap"
  }
  
  const DumplingTitle = styled('h3')(({ theme }) => ({
    ...dumplingTitle,
    [theme.breakpoints.down('xs')]: {
      fontSize: "24px",
    }
  }));
  
  const dumplingSubTitle = {
    marginTop: "2px",
    fontFamily: "Montserrat-Regular",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 400,
    marginBottom: "0px",
    color: "white",
  }
  
  const DumplingSubTitle = styled('h3')(({ theme }) => ({
    ...dumplingSubTitle,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  }));
  
    const getDumpling = async () => {
      const auth = await Auth.currentSession();
      setAuthInfo({
        idToken: auth.idToken.jwtToken,
        accessToken: auth.accessToken.jwtToken,
        username: auth.idToken.payload.email
      });
      const options = {
        headers: {
          "Authorization": auth.idToken.jwtToken,
          "accessTokenStr": auth.accessToken.jwtToken
        }
      }
  
      await fetch(`${env.server}/get_dumpling?userId=${auth.idToken.payload.email}&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
        setDumpling(json)
        setSharedWithCount(json.shared_with.length)
        console.log("test",json)
      });
    }

    useEffect(() => {
        Auth.currentUserInfo().then((result) => {
          let lastname = result.attributes.name !== "" ? result.attributes.name.split(' ').pop() : ""
          var index = result.attributes.name.lastIndexOf(" ");
          let firstname = result.attributes.name.substring(0, index);
          console.log(result)
          setFirstName(result.attributes.name)
          setFirstName(firstname)
          setLastName(lastname)
        }).catch((err) => {
          // Something is Wrong
          console.log('error getting user', err);
        })
  
        getDumpling();
      }, []); // eslint-disable-line react-hooks/exhaustive-deps

      return(

        <div> 
          {sharedWithCount === 0 && dumpling !== undefined &&
    
        <div style={{ backgroundColor: '#171E37', height: '100vh', width: '100%' }}>
          <Container>
          
            <Box sx={{marginLeft: "10px", marginTop: "10px"}}>
              {/* {(!dumpling || dumpling.length === 0) && */}
                <Card style={noDumplingTextCard}>
                  <NoDumplingH3 style={{color: "white"}}> This dumpling has not been shared with anyone yet. </NoDumplingH3>
                </Card>
            </Box>
          </Container>
          </div>
        }

        {sharedWithCount > 0 &&
         <div style={{ backgroundColor: '#171e37', height: '100vh', width: '100%' }}>
            
          <Container>
    
          <Box sx={{marginLeft: "10px", marginTop: "10px"}}>
    
            {dumpling.shared_with.map((person) => {
            return (
            <Card style={cardStyle}>
            <div  style={{ float: "left", width: "70%", display: "block", overflowWrap: "break-word"}}>
            <NoDumplingH1 style={{color: "white"}}> {person.first_name} {person.last_name} </NoDumplingH1>
            <NoDumplingH3 style={{color: "white"}}> {person.receiver_id} </NoDumplingH3>
            {/* <NoDumplingH3 style={{color: "white"}}>  First Last </NoDumplingH3> */}
            <NoDumplingH3 style={{color: "white"}}> Shared on {(Date(person.shared_date)).slice(0, 16)} </NoDumplingH3>
            </div>
    
            <FormControl variant="standard" sx={{ m: 1, float: 'right', borderBottom: 'none', borderColor: 'rgba(158, 150, 150, 0)', color: "white"}}>
            <Select
              sx={{ fontSize: "10px", marginTop: "20px", fontFamily: "Montserrat-Regular", borderRadius: "18px", color: "white", ".MuiSvgIcon-root": {fill: "white"}}}
              MenuProps={{ classes: { paper: classes.selectMenu } }}
              disableUnderline
              className={classes.select}
              value={permisison}
              onChange={ (event)=> handleChange(person, event, dumpling.id)}    
              inputProps={{ 'aria-label': 'Without label'}}
            >
              <MenuItem value={"view"}>Can View</MenuItem>
              <MenuItem value={"remove"}>Remove</MenuItem>
            </Select>
          </FormControl>
    
            </Card>
            )
    
            })}
            </Box>
            </Container>
            </div>
        
        }
    
    
    <Modal
            open={openDeleteModal}
            onClose={(event, backdropClick) => {handleCloseDelete()}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
                <div style={{display: "flex", justifyContent: 'space-between'}}>
                  <h1 style={{marginBottom: "10px", marginTop: '0px', fontSize: '20px', fontFamily: "Montserrat-Regular"}}>Are you sure?</h1>
                  <Close onClick={handleCloseDelete}/>
                </div>
                <div style={{marginBottom: "10px", marginTop: '0px', fontSize: '20px', fontFamily: "Montserrat-Regular"}}>Removing will notify this user via email that you have stopped sharing this dumpling.</div>
                <Button style={{ marginTop: '40px', textTransform: "none", borderRadius: '30px',  fontFamily: "Montserrat-Regular", backgroundColor: '#0887E2', color: 'white', height: '44px', fontSize: '16px' }} variant="contained" onClick={removeSharedWith}>
                  Remove
                </Button>
                <Button style={{ marginTop: '10px', textTransform: "none", marginBottom: '30px',   fontFamily: "Montserrat-Regular", borderRadius: '30px', color: 'white', border: '2px solid #0887E2', height: '44px', fontSize: '16px' }} onClick={handleCloseDelete}>
                  Cancel
                </Button>
              </div>
            </Box>
          </Modal>
          </div>
    
      )
    }
    
    export default SharedWith;