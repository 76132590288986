import Amplify, {Auth} from 'aws-amplify';

export function AmplifyAuthKeys() {
    Amplify.configure({
        Auth: {
          userPoolId: 'us-east-1_l8TVD6ZwX', //UserPool ID
          region: 'us-east-1',
          userPoolWebClientId: '74abi9q7rroa1ua9km9mk0ah21' //WebClientId
        }
      });
} 
    


    