import {Navigate,Outlet} from "react-router-dom";

const ProtectedRoute = ({ isAuthenticated, children }) => {
  const idToken = localStorage.getItem('idToken');

  if(!isAuthenticated && !idToken) {
    return <Navigate to="/login" replace />
  }

  return children ? children : <Outlet />;
}

export default ProtectedRoute;