import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import {useEffect} from 'react';
import Carousel from 'react-material-ui-carousel';
import {Link,useNavigate} from 'react-router-dom';
import {ReactComponent as CarouselOne} from './Components/svgs/carousel-1.svg';
import {ReactComponent as CarouselTwo} from './Components/svgs/carousel-2.svg';
import {ReactComponent as CarouselThree} from './Components/svgs/carousel-3.svg';
import {ReactComponent as CarouselFour} from './Components/svgs/carousel-4.svg';
import {ReactComponent as CarouselFive} from './Components/svgs/carousel-5.svg';
import {ReactComponent as DumplingLogo} from './Components/svgs/dumpling-logo.svg';

const buttonStyle ={
    marginTop: '15px',
    width: "359px",
    height: '48px',
    borderRadius: "30px",
    borderColor: "#0887E2",
    color: "#FFFFFF",
    fontFamily: 'Montserrat-Regular',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 600
}

const buttonPrimStyle = {
    backgroundColor: "#0887E2", 
    color: 'white', 
    width: "359px",
    height: '48px',
    borderRadius: "30px",
    fontFamily: 'Montserrat-Regular',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 600
}

var items = [
    {
        name: "One view of your digital assets",
        svg: <CarouselOne />,
        description: "Got crypto and other digital assets in different wallets and exchanges? Now you can securely view everything in one place and make transactions."
    },
    {
        name: "Group and manage your digital assets",
        svg: <CarouselTwo />,
        description: "Creating a dumpling is similar to creating a photo album on your phone – it’s a way to virtually group your crypto and other digital assets so you can view and control them in different ways."
    },
    {
        name: "Buy or swap",
        svg: <CarouselThree />,
        description: "Connect accounts to view and organize your assets without moving currencies. You can also buy, send, or swap crypto across exchanges, without leaving the app."
    },
    {
        name: "Private sharing",
        svg: <CarouselFour />,
        description: "You can invite friends and contacts to securely view your specific investment activity, but not modify your transactions."
    },
    {
        name: "Get inspired",
        svg: <CarouselFive />,
        description: "Explore featured dumplings and get ideas on how to structure your investments"
    }
];

const DataUsageLink = styled(Link)(({ theme }) => ({ 
    color: 'rgba(255,255,255,0.5)', 
    fontSize: "14px",
    marginTop: '20px', 
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: '#0887E2'
    }
}));

const carouselTitle = {
    mx: 'auto',
    marginBottom: '21px',
    marginTop: '0px',
    maxWidth: '350px',
    height: '58px',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '24px',
    fontFamily: 'Montserrat-Regular'
}

const carouselSubTitle = {
    fontSize: '14px',
    fontWeight: 400,
    maxWidth: '350px',
    overflowWrap: 'normal',
    marginTop: '20px',
    marginBottom: '20px',
    color: '#FFFFFF',
    textAlign: 'center',
    fontFamily: 'Montserrat-Regular'
}

function Item(props)
{
    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: 461.43
            }}>
                <h2 style={carouselTitle}>{props.item.name}</h2>
                <div 
                    id="img-placeholder" 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '285px', 
                        height: '236px',
                        marginBottom: '10px' 
                    }}>
                    {props.item.svg}
                </div>
                <p style={carouselSubTitle}>{props.item.description}</p>
            </div>
        </>
    )
}

const Home = ({ isAuthenticated }) => {
    const navigate = useNavigate();

    useEffect(() => {
        console.log('is authenticated?', isAuthenticated);
        if(isAuthenticated){
            navigate('/publicDumplings');
        }
    }, [isAuthenticated]);

    return (
      <Paper
        sx={{
            bgcolor: 'transparent',
            color: '#FFFFFF',
            textAlign: 'center',
            fontSize: '0.875rem',
            fontWeight: '700',
            boxShadow: "none",
      }}>
      <div style={{ backgroundColor: '#171E37', overflowY: 'scroll' }}>
        <DumplingLogo style={{ marginTop: '50px', marginBottom: 33, width: 207, height: 43 }}/>
        {/* <h1 style={title}>Dumpling</h1> */}
      
        <Carousel 
            autoPlay={false}
            indicatorContainerProps={{ style: { position: 'relative', top: '-35px' } }}
            indicatorIconButtonProps={{ style: { color: '#171E37', border: '1px solid white', margin: 6 } }}
            activeIndicatorIconButtonProps={{ style: { color: '#FFFFFF', backgroundColor: 'white' } }}>
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
        
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
            <Button 
                sx={{ 
                    backgroundColor: '#FFFFFF',
                    color: "#232933",
                }} 
                style={buttonPrimStyle} 
                variant="contained" 
                href='/login?tab=0'>
                    Sign in
            </Button>
            <Button style={buttonStyle} variant="outlined" href='/login?tab=1'>Sign up</Button>
            <DataUsageLink to="/data-usage" style={{ marginBottom: '96px' }}>
                How We Use Your Data
            </DataUsageLink>
        </div>
      </div>
      </Paper>
    );
  }
  
  export default Home;