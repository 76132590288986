import {ChevronLeft} from "@mui/icons-material";
import {Container} from '@mui/material';
import Box from '@mui/material/Box';
import {SwapWidget} from "@uniswap/widgets";
import {useWeb3React} from "@web3-react/core";
import {ethers} from "ethers";
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {connectors} from "./Connectors";


const rpcUrl = "https://bsc-dataseed.binance.org/";
const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

// const infuraId = process.env.REACT_APP_INFURA_ID;
// const jsonRpcEndpoint = `https://mainnet.infura.io/v3/${infuraId}`;
// const jsonRpcProvider = new providers.JsonRpcProvider(jsonRpcEndpoint);
// const provider = new ethers.providers.Web3Provider(jsonRpcProvider);


export default function UniswapWidget() {
  let navigate = useNavigate(); 

  const title={
    fontSize: "24px",
    color: "white",
    marginTop: '50px',
    textAlign: 'center'
}

  const [ newProvider, setProvider ] = useState("");

  const {
    account,
    activate,
    active,
    chainId,
    connector,
    deactivate,
    error,
    provider,
    setError,
} = useWeb3React();

    async function connectMyWallet() {
      const provider= "coinbase"

      if (provider === "metamask"){
        activate(connectors.injected);
        console.log("metamask", account)
      }   
      if (provider === "coinbase"){
        activate(connectors.coinbaseWallet);
        console.log("coinbase", account)
        
      }   
      if (provider === "walletconnect"){
        activate(connectors.walletConnect);
        console.log("wallet", account)
      }        

      console.log("acc", account, provider)
      console.log("provider", connector.getProvider())
    
    
      connector.getProvider().then(function(result) {
        console.log("result", result)
        setProvider(result)
      });
    
    
      console.log("newProvider", newProvider)
      

  }




  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        backgroundColor='#171e37'
        color='white'
      >
      <Container sx={{ height: '100vh', marginBottom: '70px' }}>
      <div style={{ width: '100%' }}>
            <ChevronLeft onClick={() => navigate(-2)} style={{ float: 'left', position: 'relative', left: 16, color: '#FFFFFF' }}/>
            <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Transact</h1>
            <hr style={{ width: '100%', borderColor: 'rgba(255,255,255,0.2)' }}/>
            </div>
          <Container style={{ marginTop: '20px', margin: "auto" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
            <SwapWidget
              provider={newProvider}
              jsonRpcEndpoint={rpcUrl}
              onConnectWallet={() => { connectMyWallet() }}
              // defaultTokenList={MY_TOKEN_LIST}
              // defaultInputTokenAddress={NATIVE}
              // defaultOutputTokenAddress={WBTC}
            />
            </Box>
          </Container>
        
        </Container>
      </Box>
    </div>
  );

}
