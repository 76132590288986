// import { balance }from 'balance-crypto'
// import fetch from 'node-fetch'

// export function getMyBalance(account, crypto) {
//    const result =  balance('3PxedDftWBXujWtr7TbWQSiYTsZJoMD8K5', 'BTC', {
//     keys: {
//         etherscan: "SUBDFYQCAHW8UU5EPKEW58AXPCAJVPM4GP",
//         blockcypher: "34c7c6feec4f45fd960294d357bed499"
//     },
//     verbose: true
//     }).then((res) => console.log(res))

// return result 
// }


import web3 from 'web3';

  const Web3 = require('web3') 
  const rpcURL = `https://mainnet.infura.io/v3/53823807c2ac4a0789514dd0121f45a7`
  const objWeb3 = new Web3(rpcURL);
  

 async function getBalance(address) {
     try {
         return await objWeb3.eth.getBalance(address)
     } catch (err) {
         console.log("error=" + err);
     }
 }
 
 export async function getBalanceTotal(walletAddress) {

      const balance = await getBalance(walletAddress);
      // const ethBalance =  web3.utils.fromWei(balance, 'ether');

      const ethBalance = web3.utils.fromWei(
                            web3.utils.toBN(balance), // converts Number to BN, which is accepted by `toWei()`
                            'ether'
                        );
      // console.log("total balance", ethBalance)
      return ethBalance
 }


  export async function getMyAvailableBalances() {

    getTokenBalances()

      // console.log(address)

      let address = "0x7ddA22f0410f232F956e31824F78FDD95Fc0f7D1"

      const balance = await getBalance(address);

    //   console.log("balance", balance)
      
      const ethBalance = web3.utils.fromWei(
          web3.utils.toBN(balance), // converts Number to BN, which is accepted by `toWei()`
          'ether'
      );

      const usdBalance = await getUSD()

    //   console.log("usdBalance", usdBalance)
    // getTokenBalances()

      return [ethBalance, usdBalance/100]
 }


    function getUSD() {
       const {getEthPriceNow}= require('get-eth-price');
      return getEthPriceNow().then(function(value) {
           let usdConvRate = Object.values(value)[0].ETH.USD
          return usdConvRate
        });
    }



// export async function getUSD(ethBalance) {

// const {getEthPriceNow,getEthPriceHistorical}= require('get-eth-price');
// let total = []


// const test = getEthPriceNow().then(function(value) {
//   console.log("succ", value); // "Success"
//   return value
// });

// console.log("jenna", test)

// }

export async function getTokenBalances() {

    let account = "0x7ddA22f0410f232F956e31824F78FDD95Fc0f7D1"

      const ABI = [
        {
          constant: true,
          inputs: [
            {
              name: "_owner",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              name: "balance",
              type: "uint256",
            },
          ],
          payable: false,
          type: "function",
        },
      ];

      // console.log(web3)

      const address = '0x1934e252f840aa98dfce2b6205b3e45c41aef830'
        const contract = new objWeb3.eth.Contract(ABI, address)
        // console.log("contract", contract)
        const balance = await contract.methods.balanceOf(account).call()

        // console.log("R", balance)
        const balanceInEth = web3.utils.fromWei(balance, "ether")

        // console.log("balanceInEth", balanceInEth)
      
    //   const contracts = tokenAddresses.map((token) => (new web3.eth.Contract(ABI, token.address)))
      
    //   Promise.all(contracts.map((contract) => (contract.methods.balanceOf(account).call())))
    //   .then((responses) => {
    //     res.send({
    //     const balances = responses.map((balance, i) => ({
    //         balance: ethWeb3.utils.fromWei(balance, "ether"), token: tokenAddresses[i].token 
    //       })
    //     })
    //   })
    
    }
