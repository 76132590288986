import {Button, Checkbox, styled, ToggleButton, ToggleButtonGroup} from '@mui/material';
import { React, useState } from 'react';

export const sortByTag = (tags, dumplings) => {
    const filtered = dumplings.filter(dumpling => dumpling.tags.some(tag => tags.includes(tag)));
    return filtered;
}

const DumplingTags = ({ defaultTags, handleFn }) => {
    const staticTags = ["Cryptos", "Stablecoins", "NFTs", "Short-term", "Long-term"];
    const toggleGroupStyles = {
        display: 'flex',
        width: '85%',
        flexWrap: 'wrap'
    }

    const CustomToggleButton = styled(ToggleButton)({
        height: '30px',
        border: 'none',
        marginRight: 4,
        marginBottom: 4,
        backgroundColor: '#3A4056',
        color: '#FFFFFF',
        fontFamily: 'Montserrat-Regular',
        fontSize: 13,
        textTransform: 'none',
        paddingLeft: 20,
        paddingRight: 20,
        '&.Mui-selected': {
            backgroundColor: '#309AE7',
            color: '#FFFFFF'
        },
        '&.Mui-selected:hover': {
            backgroundColor: '#309AE7',
        },
        '&:hover': {
            backgroundColor: '#3A4056'
        }
    })

    return(
        <div className='checkbox-group' style={{ display: 'flex', width: '100%' }}>
            <ToggleButtonGroup style={toggleGroupStyles} value={defaultTags} onChange={handleFn} aria-label="dumpling-tags">
                { staticTags.map(tag => (
                    <CustomToggleButton style={{ borderRadius: 15 }} value={tag}>
                        {tag}
                    </CustomToggleButton>
                )) }
            </ToggleButtonGroup>
        </div>
    )
}

export default DumplingTags;
