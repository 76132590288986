import {Box} from "@material-ui/core";
import {ChevronLeft} from "@material-ui/icons";
import {Link,useNavigate} from "react-router-dom";
import {ReactComponent as DataUsageIcon} from '../Components/svgs/data-usage-icon.svg';

const DataUsage = () => {
  const navigate = useNavigate();

  return(
    <Box sx={{ bgcolor: 'inherit', width: '100%', overflowX: 'hidden', fontFamily: 'Montserrat-Regular' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{ width: '100%' }}>
          <ChevronLeft onClick={() => navigate(-1)} style={{ float: 'left', position: 'relative', marginTop: '47px', left: 16, color: '#FFFFFF' }}/>
          <h1 style={{ fontSize: '14px', textAlign: 'center', textTransform: 'uppercase', letterSpacing: '0.12em', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>How we use your data</h1>
        </div>
        <hr style={{ width: '100%', borderColor: 'rgba(255,255,255,0.2)' }}/>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16, marginRight: 16, justifyContent: 'space-evenly', alignItems: 'center', fontSize: 13 }}>
        <DataUsageIcon />
        <article style={{ color: '#FFFFFF', maxWidth: '358px' }}>
          <h2 style={{ fontWeight: 600, fontSize: 16 }}>Your data is your data. We don't store your investment data and will never rent, trade or sell your personal information.</h2>
          <p>The data we collect and use will be used to allow you to access your different digital assets through Dumpling and improve the quality of the products and services we provide to you.</p>
          <p>We will use your personal data or one or more of the following purposes:</p>
          <ul>
            <li>To allow you to access the app through your email address or phone number, or when it is necessary to get in touch with you</li>
            <br/>
            <li>To allow you to access your digital assets in different exchange and wallet accounts through the Dumpling app</li>
            <br/>
            <li>To allow you to fund one of your existing wallets through an existing mobile wallet or credit/debit card</li>
          </ul>
        </article>
        <div style={{ maxWidth: '358px', marginBottom: '96px' }}>
          <p style={{ color: '#FFFFFF' }}>
            Security and privacy are critical aspects of this experience. <Link style={{ color: '#0887E2', textDecorationColor: '#0887E2' }} to="/terms-and-conditions">Read our terms to learn more about how your data is protected.</Link>
          </p>
        </div>
      </div>
    </Box>
  )
}

export default DataUsage;