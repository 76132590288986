import {styled} from '@material-ui/core';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as DumplingLogo} from '../Components/svgs/dumpling-logo.svg';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';


function TabPanel(props) {
  let { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      // style={{ border: '2px solid red' }}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {

  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SignUpContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '358px',
  minHeight: '297px',
  paddingBottom: '15px'
}));

const LoginTabs = styled(Tabs)({
  borderBottom: 1,
  borderColor: 'white',
  '& .MuiTabs-indicator': {
    backgroundColor: 'white',
  }
});

const loginTab = { 
  width: '50%', 
  fontFamily: 'Montserrat-Regular',
  letterSpacing: '0.1em',
  color: 'rgba(255,255,255,0.5)', 
  weight: 700,
  '&.Mui-selected': { 
    color: 'white' 
  } 
}

export default function BasicTabs() {

  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tab = (urlParams.get('tab'))
  const tabValue = parseInt(tab)
  const tabType = isNaN(tabValue) ? 0 : tabValue
  const [value, setValue] = React.useState(tabType);

  React.useEffect(() => {
    if(localStorage.getItem('idToken')) {
      return navigate('/publicDumplings');
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <SignUpContainer>
      <div style={{
        marginBottom: '12px', marginTop: '30px'
      }}>
        <DumplingLogo style={{ width: 207, height: 43 }}/>
      </div>
      
      <FormContainer>
        <LoginTabs
          sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly', borderBottom: 1, borderColor: 'rgba(255,255,255,0.5)' }}
          value={value} 
          onChange={handleChange} 
          centered aria-label="basic tabs example">
          <Tab sx={loginTab} label="Sign In" {...a11yProps(0)} />
          <Tab sx={loginTab} label="Sign Up" {...a11yProps(1)} />
        </LoginTabs>
      
        <TabPanel style={{ width: '326px', minHeight: '233px' }} value={value} index={0}>
          <LoginForm/>
        </TabPanel>
        <TabPanel style={{ width: '326px', minHeight: '233px' }} value={value} index={1}>
          <SignUpForm/>
        </TabPanel>
      </FormContainer>
    </SignUpContainer>
  );
}
