// eslint-disable-next-line react-hooks/exhaustive-deps
// import { styled } from '@mui/material/styles';
import {Check,ChevronLeft,Close} from '@mui/icons-material';
import {Container,Fab,TextField} from '@mui/material';
import Box from '@mui/material/Box';
import '@uniswap/widgets/fonts.css';
import {Auth} from 'aws-amplify';
import * as React from 'react';
import {useEffect,useState} from 'react';
import {
  useLocation,useNavigate
} from 'react-router-dom';
import {Cell,Pie,PieChart,ResponsiveContainer} from 'recharts';
import AssetList,{aggregateCurrencies} from '../Components/AssetList';
import CurrencyCard,{determineChange} from '../Components/CurrencyCard';
import {env} from '../environment';


const dumplingTitle = {
  paddingBottom: '0px',
  textAlign: "left",
  fontSize: "20px",
  fontFamily: 'Montserrat-Regular',
  fontWeight: 600,
  marginBottom: "0px",
  marginTop: '0px',
  color: "white",
  width: 280
}

const dumplingSubTitle = {
  marginTop: "5px",
  textAlign: "left",
  fontSize: "10px",
  fontFamily: 'Montserrat-Regular',
  fontWeight: 400,
  marginBottom: "0px",
  color: "rgba(255,255,255,0.5)",
}


const title = {
  fontSize: "14px",
  fontFamily: 'Montserrat-Regular',
  letterSpacing: '0.12em',
  textAlign: 'center',
  marginTop: '50px'
}

function SinglePublicDumpling() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [authInfo, setAuthInfo] = useState();
    const [isOpen, setOpen] = React.useState(false);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const [name, setName] = useState();
    const [isNameFocused, setIsNameFocused] = React.useState(false);

    const [myDumpling, setMyDumpling] = useState();

  const getSingleDumpling = async () => {
    const auth = await Auth.currentSession()
    setAuthInfo({
      idToken: auth.idToken.jwtToken,
      accessToken: auth.accessToken.jwtToken,
      username: auth.idToken.payload.email
    });
    const options = {
      headers: {
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      }
    }

    if(state.isEverything) {
      await fetch(`${env.server}/get_user_dumplings?userId=${auth.idToken.payload.email}`, options)
        .then(res => res.json())
        .then(json => {
          if(json){
            setMyDumpling(json[json.length - 1]);
          }
        }).catch(err => console.log('could not get everything', err));
    }
    else if(state.isPublic) {
      await fetch(`${env.server}/get_dumpling?userId=abc.xyz@slalom.com&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
          console.log("user", json)
          setMyDumpling(json)
      });
    }
    else if(state.shared) {
      await fetch(`${env.server}/get_dumpling?userId=${state.dumplingOwner}&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
          console.log("user", json)
          setMyDumpling(json)
      });
    }
    else {
      await fetch(`${env.server}/get_dumpling?userId=${auth.idToken.payload.email}&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
          console.log("user", json)
          setMyDumpling(json)
      });
    }
  }

  useEffect(() => {
    getSingleDumpling();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 5,
    borderColor: "white",
    boxShadow: 24,
    p: 4,
    backgroundColor: "#3F4B5C",
    color: "white"
  };

  const fabstyle = {
    fontFamily: 'Montserrat-Regular',
    fontSize: 16,
    textTransform: 'none',
    fontWeight: 600,
    marginTop: '16px',
    marginBottom: '16px',
    backgroundColor: '#0887E2',
    borderRadius: 30,
    padding: "5px",
    height: '44px',
    minWidth: '358px'
  };

  const bigTotal = {
    color: "white",
    fontSize: "40px",
    fontFamily: 'Montserrat-Regular',
    fontWeight: 200,
    textAlign: "center",
    margin: "10px",
  }

const getChartData = () => {
  let { aggregate, totalValue } = aggregateCurrencies(myDumpling.currency_set, myDumpling.wallet_info);
  let data = [];

  //now create chartData
  for(let type in aggregate) {
    let amount = aggregate[type].value/totalValue * 100;
    data.push({
      name: type,
      value: amount
    });
  }

  console.log(data);
  return data;
}

const COLORS = ['#02EDBA', '#3CA1EB', '#9C4FFF', '#EFD54D', '#FF50F8', '#FF9B00'];
const getChartColors = (data) => {
  data.map((entry, index) => {
    entry.color = COLORS[index % COLORS.length];
  });

  return data;
}




    const manageDumpling = (dumplingID, myTotal) => {
      navigate('/manageDumpling',
          {
              state: {
                dumpling_id: state.dumpling_id,
                username: authInfo.username
              }
          });
    }

  const openDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const editDumpling = async () => {
    const options = {
      headers: {
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      }
    }

    await fetch(`${env.server}/change_dumpling_name?userId=${authInfo.username}&dumplingId=${state.dumpling_id}&name=${name}`, options).then(res => res.json()).then(json => {
      console.log("edit", json);
      setIsNameFocused(false);
      window.location.reload(false);
    });
  }
  console.log("state", state)
  return (<>
    { myDumpling
      ? <div style={{ backgroundColor: '#171E37' }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        color='white'
      >
        <Container style={{ backgroundColor: '#171E37' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <ChevronLeft onClick={() => navigate(-1)} style={{ float: 'left', position: 'relative', top: '47px', left: 16, color: '#FFFFFF' }}/>
            <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Public Dumpling</h1>
            <hr style={{ width: '100%', borderColor: 'rgba(255,255,255,0.2)' }}/>
          </div>
        </div>
          <Container style={{ marginTop: '20px', paddingLeft: '0px', paddingRight: '0px', maxWidth: '400px' }}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: "flex" }}>
                  <div style={{ float: "left", marginBottom: "20px", marginRight: "20px" }}>
                    {/* <img src={littleDumplings} width="150px" style={{ float: "left" }} /> */}
                    <Box>
                      { state.isEverything
                        ? <h3 style={dumplingTitle}> All of my assets </h3>
                        : (<>
                          { !isNameFocused
                              ? <h3 style={dumplingTitle}> {myDumpling.name}</h3>
                              : (<>
                                <TextField
                                  InputProps={{ sx: { color: '#FFFFFF', '&::placeholder': { color: '#FFFFFF' }, fontSize: '18px', fontWeight: 400 }}}
                                  autoFocus
                                  variant="standard"
                                  placeholder={myDumpling.name}
                                  onChange={(event) => setName(event.target.value)}/>
                                <Check style={{ color: '#3CA1EB', marginTop: '5px' }} onClick={() => {
                                  editDumpling();
                                }}/>
                                <Close style={{ color: '#3CA1EB', marginTop: '5px', marginLeft: '5px' }} onClick={() => setIsNameFocused(false)}/>
                              </>) }
                        </>) }
                      <h3 style={dumplingSubTitle}> Updated on {(Date(myDumpling.modified_at)).slice(0, 16)} </h3>
                    </Box>
                  </div>
                </div>

              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'flex-start' }}>
                  <h2 style={{ marginTop: '0px', marginBottom: '5px', fontSize: '32px', fontWeight: 400 }}>
                    {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(parseFloat(myDumpling.total_balance))}
                  </h2>
                  {determineChange(myDumpling.old_total_balance, myDumpling.total_balance)}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'flex-end' }}>
                  <div style={{display:"flex", width: 125, justifyContent: 'center', alignItems: 'flex-start', height: 125}}>
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={getChartData()}
                          innerRadius="85%"
                          outerRadius="100%"
                          paddingAngle={5}
                          dataKey="value"
                          stroke="none"
                        >
                          {getChartData().map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        {/* <Legend verticalAlign="middle" layout="vertical"/> */}
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              { myDumpling.public_desc && (<p style={{ fontSize: 14, fontFamily: 'Montserrat-Regular', marginTop: 36, marginBottom: 36 }}>{myDumpling.public_desc}</p>) }
              <AssetList currencies={myDumpling.currency_set} walletInfo={myDumpling.wallet_info} colorMap={getChartColors(getChartData())}/>
              <div style={{ marginBottom: '150px' }}>
                <h3 style={{ fontSize: '12px', fontFamily: 'Montserrat-Regular', fontWeight: 600, marginTop: '36px' }}>ASSETS</h3>
                <CurrencyCard currencies={myDumpling.currency_set} walletInfo={myDumpling.wallet_info} isPublic={true}/>
              </div>
          </Container>
          </Container>
    </Box>
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: '#171E37', position: 'fixed', top: 'auto', bottom: 96, borderTop: '1px solid rgba(255,255,255,0.25)' }}>
      <Fab sx={{ color: '#FFFFFF' }} onClick={() => navigate('/connect')} style={fabstyle} variant="extended" aria-label="add">
        <p>Create a dumpling</p>
      </Fab>
    </div>
    </div>
      : <div></div> }
  </>);
}

export default SinglePublicDumpling;
