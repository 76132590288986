import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Box,Card} from "@mui/material";
import {Cell,Legend,Pie,PieChart,ResponsiveContainer} from "recharts";
import featDumplingBg from '../Components/svgs/featured-dumpling-graph.svg';
import {aggregateCurrencies} from './AssetList';
import {determineChange} from './CurrencyCard';
import './everything-card.css';

const currSubtitle = {
  marginTop: "0px",
  marginBottom: '0px',
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "12px",
  fontWeight: 500,
  color: "#FFFFFF",
  float: "left"
}

const dumplingTitle = {
  padding: "6px",
  paddingBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "20px",
  fontWeight: 600,
  marginBottom: "0px",
  marginTop: '0px',
  color: "white",
  whiteSpace: "nowrap"
}

const chartdata = [
  { name: "ETH", value: 40 },
  { name: 'BTC', value: 20 },
  { name: 'SOL', value: 5 },
  { name: 'DOGE', value: 15 },
  { name: 'SHIB', value: 5 },
  { name: 'USDT', value: 15 }
];

const getChartData = (dumpling) => {
  let { aggregate, totalValue } = aggregateCurrencies(dumpling.currency_set, dumpling.wallet_info);
  let data = [];

  //now create chartData
  for(let type in aggregate) {
    data.push({
      name: type,
      value: aggregate[type].value/totalValue * 100
    });
  }

  return data;
}

export const COLORS = ['#02EDBA', '#3CA1EB', '#9C4FFF', '#EFD54D', '#FF50F8', '#FF9B00'];

const DataLegendItem = ({ currencyName, dataColor, isLastItem, count }) => {
  const plusMore = {
    position: 'relative',
    display: 'flex',
    borderRadius: '50%',
    backgroundColor: 'rgb(82, 89, 109)',
    border: '2px solid rgba(255, 255, 255, 0.25)',
    /* text-align: center; */
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '12px',
    /* padding: 6px; */
    minWidth: '26px',
    minHeight: '26px'
  }

  return(
    <>
      <div style={{ 
        background: 'rgba(255, 255, 255, 0.1)', 
        display: 'flex', 
        alignItems: 'center',
        padding: '5px 10px 5px 10px', 
        borderLeft: `4px solid ${dataColor}`, 
        marginRight: '5px',
        color: 'white' }}>
          {currencyName}
      </div>
      {isLastItem && <span style={plusMore}>+{count}</span>}
    </>
  )
}

const EverythingCard = ({ dumpling, roundedTotal, total, goToDumpling }) => {
  const cardStyles = { 
    backgroundColor: 'transparent', 
    border: '1px solid rgba(255,255,255,0.2)', 
    backgroundImage: `url(${featDumplingBg})`,
    backgroundRepeat: 'none',
    backgroundSize: 'cover'
  }

  return (
    <Card onClick={() => goToDumpling(dumpling.id, roundedTotal, total, true)} className='gradient-border-mask' sx={cardStyles} style={{ padding: "20px", marginTop: "10px", marginBottom: "20px", borderRadius: "10px", boxShadow: 'none' }}>
      <div style={{ maxWidth: '358px' }}>
        <div style={{ display: "flex", width: '100%' }}>
          <div style={{ width: '100%' }}>
            {/* <img src={littleDumplings} width="150px" style={{ float: "left" }} /> */}
            <Box>
              <h3 style={dumplingTitle}> All of my assets <ArrowForwardIosIcon style={{float:"right", color: 'white'}}/></h3>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                {dumpling && (
                  <div style={{ width: '40%', height: 120 }}>
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={getChartData(dumpling)}
                          innerRadius={41}
                          outerRadius={51}
                          paddingAngle={5}
                          dataKey="value"
                          stroke="none"
                        >
                          {getChartData(dumpling).map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                )}
                
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '50%' }}>
                  <h3 style={currSubtitle}>Keep track of all your assets in one place. This will automatically update whenever you add an account, or when your balances change.</h3>
                  {/* <h3 style={{ color: '#02EDBA', fontSize: '12px' }}>+$27.18 (3.075%)</h3> */}
                </div>
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <h2 style={{ fontSize: '24px', fontWeight: 400, color: '#FFFFFF', marginBottom: '0px' }}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dumpling.total_balance) || '$0.00'}</h2>
                <p style={{ color: '#FF5E5E', fontSize: '12px', fontWeight: 400, marginBottom: '0px' }}>{determineChange(dumpling.old_total_balance, dumpling.total_balance)}</p>
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
                {dumpling && getChartData(dumpling).map((entry, index) => {
                  return index <= 3 && <DataLegendItem currencyName={entry.name} dataColor={COLORS[index % COLORS.length]} isLastItem={index === 3 && getChartData(dumpling).length > 4} count={getChartData(dumpling).length - 4} />
                })}
              </div>
              <Legend content={getChartData(dumpling)} verticalAlign="bottom" layout="horizontal"/>
            </Box>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default EverythingCard;