// contexts/User/reducer.js

export const reducer = (state, action) => {
  switch (action.type) {
    case "toggle_button":
      return {
        ...state,
        active: !state.active
      }
    case 'ADD_ITEM':
    return {
            dumplingList: [action.payload, ...state.dumplingList]
    }
    case 'REMOVE_ITEM':
    return {
        dumplingList: state.dumplingList.filter(item => item !== action.payload)
    }

    default:
      return state
  }
}

export const initialState = {
  active: false,
  dumplingList : (JSON.parse(localStorage.getItem('dumplingState'))) != null ? (JSON.parse(localStorage.getItem('dumplingState'))) : []
}