import {Box,List,ListItem,ListItemText,ListSubheader} from "@material-ui/core";
import {Close} from "@mui/icons-material";
import {Drawer,ListItemButton} from "@mui/material";

export const sortMostRecent = (dumplings) => {
  return dumplings.sort((a, b) => (new Date(a.created_at).getTime() < new Date(b.created_at).getTime()) ? 1 : -1);
}

export const sortAlphabetical = (dumplings) => {
  return dumplings.sort((a, b) => ( a.name > b.name ) ? 1 : -1);
}

export const sortBalance = (dumplings, highOrLow) => {
  if(highOrLow === 'high') {
    return dumplings.sort((a, b) => ( parseFloat(a.total_balance) < parseFloat(b.total_balance) ) ? 1 : -1); 
  }
  else if(highOrLow === 'low') {
    return dumplings.sort((a, b) => ( parseFloat(a.total_balance) > parseFloat(b.total_balance) ) ? 1 : -1);
  }
}

const SortDrawer = ({ openSort, setSort, toggleSort, sortType }) => {
  const list = (anchor) => (
    <Box
      sx={{ 
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        backgroundColor: '#252B43',
        borderRadius: '20px 20px 0px 0px',
        border: '1px solid rgba(255,255,255,0.25)',
        color: 'white'
      }}
      role="presentation"
      onClick={toggleSort}
      onKeyDown={toggleSort}
    >
      <List>
        <ListSubheader style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'white' }}>
          Sort by <Close onClick={toggleSort}/>
        </ListSubheader>
        {['Most recent', 'Highest balance', 'Lowest balance', 'A-Z'].map((text, index) => (<>
          { sortType === text
            ? (<ListItem style={{ backgroundColor: 'rgba(255,255,255,0.1)' }} key={text} disablePadding>
                <ListItemButton onClick={() => setSort(text)}>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>)
            : (<ListItem key={text} disablePadding>
                <ListItemButton onClick={() => setSort(text)}>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>) }
        </>))}
      </List>
    </Box>
  );

  return (
    <Drawer PaperProps={{ sx: { backgroundColor: 'transparent' } }} anchor="bottom" open={openSort} onClose={toggleSort}>
      {list('bottom')}
    </Drawer>
  )
}

export default SortDrawer;