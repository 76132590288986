import {Web3Provider} from "@ethersproject/providers";
import {Web3ReactProvider} from '@web3-react/core';
import React from 'react';
import ReactDOM from 'react-dom';
import {QueryClient,QueryClientProvider} from 'react-query';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import {GlobalProvider} from './contexts/User/index.js';
import './fonts/Montserrat/Montserrat-VariableFont_wght.ttf';
import './index.css';
import reportWebVitals from './reportWebVitals';




// import rootReducer from './reducers'
// import { createStore } from 'redux'
const queryClient = new QueryClient()

// const store = createStore(rootReducer)


function getLibrary(provider) {
  return new Web3Provider(provider);
}


ReactDOM.render(
    <GlobalProvider>
<Web3ReactProvider getLibrary={getLibrary}>
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
  </Web3ReactProvider>
  </GlobalProvider>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();