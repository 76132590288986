import {styled} from '@material-ui/core';
import {ChevronLeft} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import {useLocation,useNavigate} from 'react-router-dom';
import Accounts from "./Accounts.js";
import Profile from "./Profile.js";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      // style={{ border: '2px solid red' }}
    >
      {value === index && (
        <Box width={358}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SignUpContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '#171e37' 
}));

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '358px',
  minHeight: '297px',
  paddingBottom: '15px'
}));

const LoginTabs = styled(Tabs)({
  borderBottom: 1,
  borderColor: 'white',
  '& .MuiTabs-indicator': {
    backgroundColor: 'white',
  }
});

const loginTab = { 
  width: '50%', 
  color: 'rgba(255,255,255,0.5)', 
  fontFamily: 'Montserrat-Regular',
  weight: 700, 
  '&.Mui-selected': { 
    color: 'white' 
  } 
}

export default function Account() {
  const { state } = useLocation();
  const [value, setValue] = React.useState(state === null ? 0 : (state !== null && state.isAccount) ? 0 : 1);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {

  // }, [value])

  return (
    <SignUpContainer>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', width: '100%' }}>
        <div style={{ width: '100%' }}>
          <ChevronLeft onClick={() => navigate(-1)} style={{ float: 'left', position: 'relative', top: '47px', left: 16, color: '#FFFFFF' }}/>
          <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Settings</h1>
          <hr style={{ borderColor: 'rgba(255,255,255,0.2)' }}/>
        </div>
      </div>
      <FormContainer>
        <LoginTabs
          sx={{ display: 'flex', width: '100%', maxWidth: '400px', fontFamily: 'Montserrat-Regular', justifyContent: 'space-evenly', borderBottom: 1, borderColor: 'rgba(255,255,255,0.5)' }}
          value={value} 
          onChange={handleChange} 
          centered aria-label="basic tabs example">
          <Tab sx={loginTab} label="Accounts" {...a11yProps(0)} />
          <Tab sx={loginTab} label="Profile" {...a11yProps(1)} />
        </LoginTabs>
      
        <TabPanel style={{ minHeight: '233px'}} value={value} index={0}>
         <Accounts/>
        </TabPanel>
        <TabPanel style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '358px' }} value={value} index={1}>
          <Profile/>
        </TabPanel>
      </FormContainer>
    </SignUpContainer>
  );
}
