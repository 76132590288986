import {styled} from '@material-ui/core';
import {AddCircle,ArrowDropDown} from "@mui/icons-material";
import {AccordionSummary,Container,Link} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {useWeb3React} from "@web3-react/core";
import {Auth} from 'aws-amplify';
import * as React from 'react';
// import './App.css';
import {useEffect,useState} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {env} from '../environment';
import CoinbaseLogo from '../images/Coinbase.svg';
import MetamaskLogo from '../images/MetamaskLogo.svg';
import WalletConnectLogo from '../images/WalletConnectLogo.svg';


const dumplingTitle = {
  padding: 0,
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 400,
  marginTop: 20,
  marginBottom: 20,
  color: "white"
}

const DumplingTitle = styled('h3')(({ theme }) => ({
  ...dumplingTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '24px'
  }
}));

const providerTitle = {
  paddingBottom: '0px',
  marginBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 600,
  color: "white",
}

const walletAddressStyle = {
  paddingBottom: '0px',
  marginBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 600,
  color: "white",
}

const WalletImg = styled('img')(({ theme }) => ({
  borderRadius: '3px',
  height: '90px',
  width: '200px',
  [theme.breakpoints.down('sm')]: {
    height: '28px',
    width: '140px',
    padding: '10px'
  }
}));

const ProviderTitle = styled('h3')(({ theme }) => ({
  ...providerTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '20px',
    width: "50%",
    overflowWrap: "break-word"
  }
}));

const addresstitle = {
  paddingBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "12px",
  fontWeight: 600,
  color: "white",
  whiteSpace: "nowrap"
}

const AddressTitle = styled('h3')(({ theme }) => ({
  ...addresstitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));



const dumplingSubTitle = {
  marginTop: "2px",
  paddingLeft: "8px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "20px",
  color: "white",
}

const DumplingSubTitle = styled('h3')(({ theme }) => ({
  ...dumplingSubTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));


const currencyStyle = {
  fontFamily: "Montserrat-Regular",
  fontSize: "14px",
  // marginLeft: "50px",
  color: "white",
  whiteSpace: "nowrap",
  float: "right"
};

const currencyStyleUSD  = {
fontFamily: "Montserrat-Regular",
fontSize: "18px",
marginLeft: "20px",
color: "white",
whiteSpace: "nowrap",
float: "right",
textAlign: "right"
};

const currencyStyleBig = {
fontFamily: "Montserrat-Regular",
fontSize: "18px",
marginLeft: "10px",
color: "white",
whiteSpace: "nowrap",
};

const currencyStyleHeader = {
fontFamily: "Montserrat-Regular",
fontSize: "18px",
marginLeft: "50px",
color: "white",
whiteSpace: "nowrap",
float: "right"
};


// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   select: {
//     '.MuiOutlinedInput-notchedOutline': {
//       color: 'white',
//       border: "1px white solid"
//     },
//     '.MuiSvgIcon-root-MuiSelect-icon': {
//       color: 'white',

//     },
//     '&:hover .MuiOutlinedInput-notchedOutline': {
//       borderColor: 'white',
//       borderWidth: '0.15rem',
//     },
//   },
// }));

const transactButton = {
    height: 48,
    marginBottom: 25,
    borderRadius: 25,
    fontSize: "16px",
    border: "2px #0887E2 solid",
    width: "50%",
    marginRight: "10px",
    fontFamily: "Montserrat-Regular",
    fontWeight: 600,
    textTransform: "none"
}


const title={
    fontSize: "24px",
    color: "white",
    marginTop: '50px',
    textAlign: 'center'
}

// const infuraId ='53823807c2ac4a0789514dd0121f45a7';
// console.log("infuraId", infuraId)
// const JsonRpcEndpoint = `https://mainnet.infura.io/v3/${infuraId}`;
// const JsonRpcProvider = new providers.JsonRpcProvider(JsonRpcEndpoint);

// console.log("JsonRpcProvider", JsonRpcProvider)
// const provider = new ethers.providers.Web3Provider(JsonRpcProvider);
// console.log("prov", provider)


//
 
function getProvImage(provider) {
  const logo = (provider === "Metamask") ? MetamaskLogo : (provider === "walletConnect") ? WalletConnectLogo : (provider === "Coinbase") ? CoinbaseLogo : null
  return (
    <WalletImg
    src={logo}
    alt=" Logo"
    />
  )
}

async function getWalletBalances(walletAddresses) {
  var myHeaders = new Headers();
  const auth = await Auth.currentSession();

  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "wallets" : walletAddresses
  }
  );

  var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": auth.idToken.jwtToken,
      "accessTokenStr": auth.accessToken.jwtToken
    },
    body: raw,
    redirect: 'follow'
  };

  await fetch(`${env.server}/get_wallet_balance`, requestOptions).then(res => res.json()).then(json => {
    return json
  }).catch(error => console.log('error', error));
}

function Transact() {
  let navigate = useNavigate(); 


  const [account, setAccount] = useState({
    address: '',
    provider: ''
  })

  const [ authInfo, setAuthInfo ] = React.useState();
  const [ allWallets, setAllWallets ] = React.useState([]);
  const [ activeWallet, setActiveWallet] = React.useState('');
  const [ totalBal, setWalletBalances] = React.useState([]);
  const [ prov, setProv] = React.useState([]);
  const [allWalletInfo, setAllWalletInfo] = React.useState([]);


  // const [ activeProvider, setActiveProvider] = React.useState('');
 

  // const setProvider = (type) => {
  //   window.localStorage.setItem("uniswap-provider", type);
  // };

  const {
    // library,
    // chainId,
    // selectedAccount,
    activate,
    deactivate,
    // active
  } = useWeb3React();

  const accordionContainer = {

    // paddingRight: "0px", 
    // backgroundColor: "#252B43", 
    // marginBottom: "10px",
    // color: "white", 
    // borderRadius: 10, 
    // width: "354px",
    
    width: 300,
    backgroundColor: '#171E37', 
    boxShadow: 'none',
    '&.MuiPaper-root': {
      backgroundColor: '#171E37',
      width: '100%',
      marginBottom: '16px'
    }
  }

  const accordionTitle = {
    borderRadius: "10px", 
    display: 'flex',
    justifyContent: 'start',
    backgroundColor: '#252B43', 
    alignItems: 'center',
    margin: "0px",
    width: 'inherit'
  }

  const accordionDetails = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#171E37', 
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '0px',
  }

  async function getAllWallets(){
    const auth = await Auth.currentSession();
    const options = {
      headers: {
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      }
    }

    await fetch(`${env.server}/get_user_deduped_wallets?userId=${auth.idToken.payload.email}`, options).then(res => res.json()).then(json => {
      
    let walletAddresses = []
    let walletProvidersMap = {}


    json.map((value) => {

      if (Number(value.address.charAt(0)) === 0) {
        walletAddresses.push(value.address)
      }
      walletProvidersMap[value.address] = value.provider
    })

    setProv(walletProvidersMap)
    console.log(walletProvidersMap)

      getWalletBalances(walletAddresses)
  
    });

  }

  async function getWalletBalances(wallets) {
    const auth = await Auth.currentSession();
    var myHeaders = new Headers();
  
    myHeaders.append("Content-Type", "application/json");

  
    var raw = JSON.stringify({
      "wallets": wallets
    }
    );
  
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      },
      body: raw,
      redirect: 'follow'
    };
  
    await fetch(`${env.server}/get_wallet_balance`, requestOptions).then(res => res.json()).then(json => {
      setAllWalletInfo(json);
    }).catch(error => console.log('error', error));
  
  }


  useEffect(() => {

    getAllWallets()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor='#171E37'
        color='white'
      >
      <Container sx={{ marginBottom: '96px', paddingLeft: 0, paddingRight: 0 }}>
          <div>
  
          <div style={{ width: '100%' }}>
            <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Transact</h1>
            <hr style={{ borderColor: 'rgba(255,255,255,0.2)' }}/>
          </div>
          </div>
          <Container style={{ marginTop: '20px', paddingLeft: 5, paddingRight: 5, width: 354 }}>
          <DumplingTitle>What would you like to do?</DumplingTitle>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Button 
              onClick={() => navigate('/transactSource', {state: {transactionType: "buy" }})}
              sx={{
                '&:disabled': {
                  color: 'white',
                  opacity: 0.3
                }
              }}
              style={transactButton} 
              variant="outlined" disabled={allWalletInfo.length === 0}>Buy</Button>
              <Button 
                onClick={() => navigate('/transactSource', {state: {transactionType: "swap" }})} 
                sx={{
                  '&:disabled': {
                    color: 'white',
                    opacity: 0.3
                  }
                }}
                style={transactButton} 
                variant="outlined" disabled={allWalletInfo.length === 0}>
                Swap
              </Button>
            </div>

            <DumplingTitle style={{ fontSize: 20, marginTop: 10, marginBottom: 17 }}>Your connected wallets</DumplingTitle>
        <div>
        <Box sx={{ flexGrow: 1,display: 'flex', 
          flexDirection: 'column',
          alignItems: "center", justifyContent: "center", paddingBottom: "100px",
          color: "white"}}>
            {allWalletInfo.length === 0 && (
              <div style={{ display: 'flex', width: '100%', justifyContent: 'start', alignItems: 'center' }}>
                <Link 
                href='/account'
                style={{
                  display: 'flex',
                  width: 112,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontSize: 14,
                  color: '#3CA1EB',
                  fontWeight: 600,
                  textDecoration: 'none'
                }}>
                  <AddCircle /> Add account
                </Link>
              </div>
            )}
              {allWalletInfo.map((wallet, index) => {

                   return (
                    <Accordion sx={accordionContainer} defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ArrowDropDown sx={{ color: 'white' }}/>} sx={accordionTitle} aria-controls="assetTotal-content" id="assetTotal-header">
                    <div style={{width: 265, overflowWrap: "break-word"}}>
                      <ProviderTitle> {getProvImage(prov[wallet.wallet_address])} </ProviderTitle>
                      <h3 style={walletAddressStyle}> {wallet.wallet_address} </h3>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={accordionDetails}>
                  <List sx={{ width: '100%',  color: "white", backgroundColor: "#252B43"}}>


                  {wallet.wallet_info.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.name}`;
                    const totalCrypto = (Number(value.balance)).toFixed(4)
                    const totalUSD = (Number(value.usd_amount)).toFixed(2) 
  

                    return (

                      <ListItem
                        key={labelId}
                        sx={{backgroundColor: (index % 2 === 0) ? "#3B4055" : "#252B43", width: "auto", maginRight: "10px"}}
                        disablePadding
                      >
                        <ListItem role={undefined} dense>

    

                      <ListItemText
                        sx={currencyStyle}
                        primary={
                        <React.Fragment>
                            <Typography
                              sx={currencyStyleBig}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                            {value.legalName}
                            </Typography>
                          </React.Fragment>}

                        />
      
                        <div style={{display: "grid"}}>
                        <ListItemText
                        primary={
                          <React.Fragment>
                              <Typography
                                sx={currencyStyleUSD}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                            {`$ ${totalUSD}`}
                              </Typography>
                            </React.Fragment>}

                        secondary={
                          <React.Fragment>
                            <Typography
                                sx={currencyStyle} 
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                            {`${totalCrypto} ${value.name}`}
                            </Typography>
                          </React.Fragment>
                        }
                        />
                        </div>

        
                        </ListItem>
                      </ListItem>
                    );
                  })}
                </List>

                {/* </Card> */}
                </AccordionDetails>
                </Accordion>

                );
                })}
                

                {/* <Button mx='auto' variant="contained" color="primary" style={{ marginBottom: "10px", width: "250px", borderRadius: "20px", fontFamily: "Montserrat-Regular", }} onClick={() => { sendData() }} >Continue</Button> */}

                {/* <Button onClick={() => navigate(-1)} style={{marginBottom: "10px", height: "44px", backgroundColor: "#0887E2", color: 'white', width: "80%", borderRadius: "20px", fontFamily: "Montserrat-Regular", }} >View my Dumpling</Button> */}

                                     
        </Box>
        

        
      </div>





          </Container>
        
        </Container>
      </Box>
    </div>
  );
}

export default Transact;