import { Fab, styled } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import { Auth } from 'aws-amplify';
import {AmplifyAuthKeys} from '../Components/AmplifyAuthKeys'

import PasswordValidator from "password-validator";
import { Component } from "react";
import {
  useNavigate
} from 'react-router-dom';

/*
Amplify.configure({
  Auth: {
    userPoolId: 'us-east-1_l8TVD6ZwX', //UserPool ID
    region: 'us-east-1',
    userPoolWebClientId: '74abi9q7rroa1ua9km9mk0ah21' //WebClientId
  }
});
*/

AmplifyAuthKeys();

const dumplingTitle = {
  padding: "6px",
  paddingBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 600,
  marginBottom: "0px",
  marginTop: '0px',
  color: "white",
  whiteSpace: "nowrap"
}

const DumplingTitle = styled('h3')(({ theme }) => ({
  ...dumplingTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px'
  }
}));

const dumplingSubTitle = {
  marginTop: "2px",
  paddingLeft: "8px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "20px",
  color: "white",
}

const DumplingSubTitle = styled('h3')(({ theme }) => ({
  ...dumplingSubTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%', 
  transform: 'translate(-50%, -50%)',
  minWidth: '300px',
  bgcolor: 'background.paper',
  borderRadius: 5,
  borderColor: "white",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#3A4056", 
  color: "white",
  fontFamily: "Montserrat-Regular",
};

class MyProfile extends Component {

  constructor() {
    super();
    this.logout = this.logout.bind(this);
  }

  state = {
    email: "",
    password: "",
    phone: "",
    isAuthenticated: false,
    jwtToken: "",
    pageIndex: 0,
    openDialog: false,
    verificationCode: "",
    name: "",
    newName: "",
    openChangePassword: false,
    openSendCode: false,
    code: "",
    successModalOpen: "",
    verifyPassword: "",
    successModalMsg: ""
  };



 

  componentDidMount() {
    
    Auth.currentUserInfo().then((result) => {

      console.log(result)
      this.setState({ email: result.attributes.email, password: "********", name: result.attributes.name, phone: result.attributes.phone_number });


    }).catch((err) => {
      // Something is Wrong
      console.log('error getting user', err);
    })
  }



  handleChange = e => {
    
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });

  };

  handleCloseChangePass = () =>  this.setState({ openChangePassword: false });

  handleCloseSendCode= () =>  this.setState({ openSendCode: false });

  setSuccessModalClose = () =>  this.setState({ successModalOpen: false });
  setSuccessModalOpen = () =>  this.setState({ 
    successModalOpen: true, 
    code: "", 
    newpassword: "",  
    verifyPassword: ""
  });

  handleChangePassword() {
    this.setState({ openChangePassword: true });
  };

  handleSendCode() {
    this.setState({ openSendCode: true });
  };
  
  

  async updateUser() {

    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, {
      // 'address': '105 Main St. New York, NY 10001',
      'name': this.state.name,
      // 'phone': this.state.phone
    }).then((result) => {
      this.setState({ successModalMsg: "name" });
      this.setSuccessModalOpen()
      console.log(result)
    }).catch((err) => {
      // Something is Wrong
      console.log('error updating ', err);
    })
  }


  async logout() {
    localStorage.removeItem('username');
    localStorage.removeItem('idToken');
    await Auth.signOut().catch((err) => {
      // Something is Wrong
      console.log('error logging out ', err);
    })
  }

async sendResetPasswordCode() {

  console.log("resetPass")

  const email = this.state.email
  await Auth.forgotPassword(email, {
  }).then((result) => {

    console.log(result)
    this.handleChangePassword()
    this.handleCloseSendCode()

  }).catch((err) => {
    // Something is Wrong
    console.log('error resetting ', err);
  })

}

async resetPassword() {

  this.handleCloseChangePass()
      
  await Auth.forgotPasswordSubmit( this.state.email,
    this.state.code,
    this.state.newpassword, {
  }).then((result) => {

    console.log(result)
    this.setState({ successModalMsg: "password" });
    this.setSuccessModalOpen()
    this.setState({ code: "", newpassword: "",  verifyPassword: "" });
  }).catch((err) => {
    // Something is Wrong
    console.log('error resetting ', err);
  })

}


  confirmSignUp = e => {
    Auth.confirmSignUp(this.state.email, this.state.verificationCode).then((result) => {


      if (result === "SUCCESS"){

        this.handleClose()
        this.props.navigate('/dashboard');
      }

    }).catch((err) => {
      // Something is Wrong
      console.log('error verrifying your code', err);
    })
  }


//   onTextChange(text) {
//     var cleaned = ('' + text).replace(/\D/g, '')
//     var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
//     if (match) {
//         var intlCode = (match[1] ? '+1 ' : ''),
//             number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');

//         this.setState({
//             phoneNum: number
//         });

//         return;
//     }

//     this.setState({
//         phoneNum: text
//     });
// }



  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleTextFieldChange = e => {
    this.setState({
      verificationCode: e.target.value
    });
  }

  checkPassword = () => {
    const validatePass = new PasswordValidator();
    validatePass
    .is().min(8)                                    // Minimum length 8
    .is().max(100)                                  // Maximum length 100
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits(1)                                // Must have at least 1 digits
    .has().symbols(1)                                // Must have at least 1 digits
    .has().not().spaces();
  const valid = (validatePass.validate(this.state.newpassword));
  return valid
  }

  render() {
    const formStyle = {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'flex-start',
      fontFamily: "Montserrat-Regular"
    }

    const inputContainer = {
      width: '100%',
      marginTop: '24px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: 'white',
      fontSize: '14px',
      fontFamily: 'Montserrat-Regular'
    }

    const textField = {
      width: '92%',
      height: '44px',
      paddingRight: '12px',
      border: 'none',
      borderBottom: '1px solid white',
      backgroundColor: 'transparent',
      color: 'white',
      fontSize: '16px'
    }

    const textFieldPassChange = {
      border: "1px white solid", 
      borderRadius: "10px", 
      width: "100%",
      paddingLeft: "5px"
    }

    const buttonStyles = {
      width: '100%',
      height: '46px',
      marginTop: '30px',
      marginBottom: '175px',
      borderRadius: 30,
      backgroundColor: '#0887E2',
      color: '#FFFFFF',
      fontSize: '16px',
      weight: 700,
      fontFamily: "Montserrat-Regular",
      textTransform: "none",
      fontWeight: '600'
    }

    const modalButtonStyles = {
      width: '100%',
      height: '46px',
      marginTop: '30px',
      marginBottom: '0px',
      borderRadius: 20,
      backgroundColor: '#0887E2',
      color: 'white',
      fontSize: '16px',
      weight: 700,
      textTransform: "none",
      fontFamily: "Montserrat-Regular",
    }

    const fabstyle = {
      marginTop: '16px',
      marginBottom: '16px',
      backgroundColor: '#0887E2',
      borderRadius: 30,
      padding: "5px",
      height: '44px',
      minWidth: '358px',
      fontFamily: "Montserrat-Regular",
      fontSize: 16,
      fontWeight: 600
    };
    return (
      <div>
        <div style={{ padding: 16 }}>
          <form style={formStyle} className="form">
            <label style={inputContainer}>
              <span style={{ marginBottom: '12px' }}>Name</span>
              <input style={textField} id="name" placeholder={this.state.name} type='text' disabled onChange={this.handleChange}/>
            </label>
            <label style={inputContainer}>
              <span style={{ marginBottom: '12px' }}>Email address</span>
              <input style={textField} id="email" placeholder={this.state.email}  type='text' disabled onChange={this.handleChange}/>
            </label>
            <label style={inputContainer}>
              <span style={{ marginBottom: '12px' }}>Password</span>
              <div style={{display: "contents"}}> 
              <input style={textField} id="password" placeholder="********" type='password' disabled onChange={this.handleChange}/>
              <IconButton aria-label="delete" style={{right: "0px", marginTop: '-40px', marginLeft: '50px', float: "right", padding: "0px"}} onClick={() => {this.handleSendCode() }}>
                <EditIcon style={{ color: '#3CA1EB' }}/>
              </IconButton>
              </div>
            </label>
            <label style={inputContainer}>
              <span style={{ marginBottom: '12px' }}>Phone number</span>
              <input style={textField} id="phone" placeholder={this.state.phone}  type='tel' disabled onChange={this.handleChange}/>
            </label>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginTop: 36, color: '#FFFFFF' }}>
              <h3 style={{ marginTop: 0, marginBottom: 8, fontWeight: 400 }}>Need assistance?</h3>
              <p style={{ marginTop: 0, fontSize: 14, fontWeight: 400}}>Email us at <a href="mailto: support@dumplingapp.io" style={{ textDecoration: 'none', fontWeight: 700, color: '#3CA1EB' }}>support@dumplingapp.io</a> to report any account issues or request support.</p>
            </div>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: '#171E37', position: 'fixed', top: 'auto', left: 0, bottom: 96, borderTop: '1px solid rgba(255,255,255,0.25)' }}>
              <Fab sx={{ color: '#FFFFFF' }} href="/login?tab=0" onClick={this.logout} style={fabstyle} variant="extended" aria-label="add">
                <p style={{ color: '#FFFFFF', fontFamily: 'Montserrat-Regular', textTransform: 'none' }}>Log out</p>
              </Fab>
            </div>
          </form>
        </div>
        <div>
          <Dialog open={this.state.openDialog} onClose={this.handleClose}>
            <DialogTitle>Code Sent!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                We sent a verification code to {this.state.phone}. Please enter the code that was sent to your email below.
              </DialogContentText>
              <TextField
                sx={{ input: { color: 'white' } }}
                autoFocus
                margin="dense"
                id="name"
                label="Verification Code"
                type="email"
                fullWidth
                variant="standard"
                onChange={this.handleTextFieldChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button onClick={this.confirmSignUp}>Verify Phone</Button>
            </DialogActions>
          </Dialog>
        </div>

        <Modal
          open={this.state.openSendCode}
          onClose={this.handleCloseSendCode}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={modalStyle}>

            <DumplingTitle style={{marginBottom: "10px"}}>{`Change Password`}</DumplingTitle>
            <DumplingSubTitle style={{marginBottom: "0px"}}>{`You will be sent a verification code to your registered mobile phone number to confirm your identity and initiate change password.`}</DumplingSubTitle>

            <Button style={modalButtonStyles} type="button" color="primary" className="form__custom-button" onClick={() => { this.sendResetPasswordCode() }} >
              Yes, Change Password
            </Button>

            <Button style={modalButtonStyles} type="button" color="primary" className="form__custom-button" onClick={() => { this.handleCloseSendCode() }} >
              Cancel
            </Button>

          </Box>
          </Modal>
          
        <Modal
          open={this.state.openChangePassword}
          onClose={this.handleCloseChangePass}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={modalStyle}>

            <DumplingTitle style={{marginBottom: "10px"}}>{`Change Password`}</DumplingTitle>
            <DumplingSubTitle style={{marginBottom: "0px"}}>{`We have sent you a verification code to confirm your identify. Please enter the code below followed by your new password.`}</DumplingSubTitle>

            <Button  sx={{ textTransform: 'none', marginTop: "10px", fontFamily: "Montserrat-Regular", fontWeight: 700, color: '#3CA1EB' }} varient="text" type="button" color="primary" onClick={() => { this.sendResetPasswordCode() }} >
             Resend Code
            </Button>
            

            <div style={{ marginLeft: "8px", marginTop: "20px" }}>
            <TextField
                InputProps={{ disableUnderline: true, style: { color: 'white', fontFamily: "Montserrat-Regular", marginTop: "-2px", padding: "5px"} }}
                style={textFieldPassChange}
                InputLabelProps={{
                  shrink: true,
                  style: { color: 'white', fontFamily: "Montserrat-Regular", marginTop: "-20px", fontWeight: 600 },
                }}
                autoFocus
                margin="dense"
                id="code"
                label="Verification Code" 
                type="text"
                fullWidth
                variant="standard"
                value={this.state.code}
                onChange={this.handleChange}
              />
              <DumplingSubTitle style={{marginBottom: "30px"}}>&#42; Required field </DumplingSubTitle>
              <TextField
                style={textFieldPassChange}  
                InputLabelProps={{
                  shrink: true,
                  size: "normal",
                  style: { color: 'white', fontFamily: "Montserrat-Regular", marginTop: "-20px", fontWeight: 600 },
                }}
                InputProps={{ disableUnderline: true, style: { color: 'white', fontFamily: "Montserrat-Regular", marginTop: "-4px", padding: "5px" } }}
                autoFocus
                margin="dense"
                id="newpassword"
                label="New Password"
                type="password"
                fullWidth
                variant="standard"
                value={this.state.newpassword}
                onChange={this.handleChange}
              />
              <DumplingSubTitle style={{marginBottom: "20px"}}>&#42; Required field </DumplingSubTitle>
              <div style={{fontSize: '14px', marginBottom: "30px"}}> 
              <strong>Password requirements:</strong>
                  <ul>
                    <li>Must be 8 characters minimum</li>
                    <li>Must contain at least one special character, one number, one capital letter, and at least one lower case letter</li>
                  </ul>
               </div>
              <TextField
                style={textFieldPassChange}  
                InputLabelProps={{
                  shrink: true,
                  style: { color: 'white', fontFamily: "Montserrat-Regular", marginTop: "-20px", fontWeight: 600 },
                }}
                InputProps={{ disableUnderline: true, style: { color: 'white', fontFamily: "Montserrat-Regular", marginTop: "-2px", padding: "5px"} }}
                autoFocus
                margin="dense"
                id="verifyPassword"
                label="Verify New Password"
                type="password"
                fullWidth
                variant="standard"
                value={this.state.verifyPassword}
                onChange={this.handleChange}
              />
              <DumplingSubTitle style={{marginBottom: "30px"}}>&#42; Required field </DumplingSubTitle>

              </div>
            <Button 
                style={modalButtonStyles} 
                sx={{marginLeft: "8px", '&:disabled': {
                  color: 'white',
                  opacity: 0.3
                }}} 
                color="primary" 
                disabled={!(this.state.code !== "" && this.state.newpassword !== "" && this.state.verifyPassword !== "" && 
                (this.state.newpassword === this.state.verifyPassword) && this.checkPassword()
                )} 
                className="form__custom-button" 
                onClick={() => { this.resetPassword() }}
            >
             Confirm
            </Button>
          </Box>
          </Modal>

          <Modal
          open={this.state.successModalOpen}
          onClose={this.setSuccessModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={modalStyle}>
          <div style={{textAlign: "center"}}>
          <CheckIcon style={{marginBottom: "0px", color: "#02EDBA"}} fontSize="large"/>
          </div>
            <h3 style={{marginBottom: "0px", textAlign: 'center'}}>{`Your ${this.state.successModalMsg} has been successfully changed.`}</h3>
            <Button onClick={() => { this.setSuccessModalClose() }} style={modalButtonStyles} type="button" color="primary" className="form__custom-button">
             Close
            </Button>
          </Box>
          </Modal>
          
          
      </div>
    );
  }
}


function Profile(props) {
  let navigate = useNavigate();
  return <MyProfile {...props} navigate={navigate} />
}

export default Profile
