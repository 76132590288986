// import { styled } from '@mui/material/styles';
import { styled } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import '@uniswap/widgets/fonts.css';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  useNavigate
} from 'react-router-dom';
import SharedDumplingCard from '../Components/SharedDumplingCard';
import DashboardDumplingCard from '../Components/DashboardDumplingCard';
import SortDrawer, { sortAlphabetical, sortBalance, sortMostRecent } from '../Components/SortDrawer.js';
import { ReactComponent as SortIcon } from '../Components/svgs/sort-icon.svg';
import { env } from '../environment';
import { getAllWallets, getEverythingBalance } from "../Utils/getAllWallets.js";
import { NoDumplingH1, SortButton } from './Dumpling.js';

const chartdata = [
  { name: "ETH", value: 100 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];
const COLORS = ['#9D4FFF', '#02EDBA', '#FF50F9', '#9C4FFF'];

const dumplingCount = {
  marginTop: "2px",
  paddingLeft: "8px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "10px",
  fontWeight: 600,
  marginBottom: "0px",
  color: "white"
}

const dumplingSubTitle = {
  marginTop: "2px",
  paddingLeft: "8px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "0px",
  color: "white",
}

const DumplingSubTitle = styled('h3')(({ theme }) => ({
  ...dumplingSubTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));


const dumplingSubSubTitle = {
  marginTop: "0px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  color: "white",
  float: "left"
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '300px',
  bgcolor: 'background.paper',
  borderRadius: 5,
  borderColor: "white",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#3F4B5C",
  color: "white",
  fontFamily: "Montserrat-Regular",
};

const dumplingTitle = {
  padding: "6px",
  paddingBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 600,
  marginBottom: "0px",
  marginTop: '0px',
  color: "white",
  whiteSpace: "nowrap"
}

const DumplingTitle = styled('h3')(({ theme }) => ({
  ...dumplingTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px'
  }
}));


const totalBalanceStyle = {
  fontFamily: "Montserrat-Regular",
  color: "white",
  fontSize: "40px",
  fontWeight: 200,
  textAlign: "left",
  marginBottom: "0px",
}

const TotalBalance = styled('h2')(({ theme }) => ({
  ...totalBalanceStyle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '24px'
  }
}));

const PieContainer = styled('div')(({ theme }) => ({
  width: '15%',
  height: 200,
  [theme.breakpoints.down('xs')]: {
    width: '20%',
    height: 100
  }
}));

function FollowDumplings() {

  const navigate = useNavigate();
  const [authInfo, setAuthInfo] = useState();
  const [followedDumplings, setFollowedDumplings] = useState([]);
  const [everythingTotal, setEverythingTotal] = useState([]);
  const [walletValues, setWalletValues] = useState([]);
  const [isOpenShared, setOpenShared] = React.useState(false);
  const handleOpenShared = () => setOpenShared(true);
  const handleCloseShared = () => setOpenShared(false);
  const [sharedWith, setSharedWith] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [sort, setSort] = useState('Most recent');
  const [openSort, setOpenSort] = useState(false);
  const toggleSort = () => setOpenSort(!openSort);

  const getAuthUser = async () => {
    await Auth.currentSession()
      .then(res => {
        setAuthInfo({
          idToken: res.idToken.jwtToken,
          accessToken: res.accessToken.jwtToken,
          username: res.idToken.payload.email
        });
      })
      .catch(err => {
        console.log('could not get user...')
      })
  }

  useEffect(() => {

    sync();
    getAuthUser();
    const totalWalletVals = getAllWallets()

    totalWalletVals.then(function (result) {
      setWalletValues(result)
    });

    getAllWallets();
    getFollowedDumplings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getFollowedDumplings() {
    setIsLoading(true);
    let auth = authInfo;
    let options = {}
    if (authInfo !== undefined) {
      options = {
        headers: {
          "Authorization": authInfo.idToken,
          "accessTokenStr": authInfo.accessToken
        }
      }
    }
    if (!authInfo || authInfo === undefined) {
      auth = await Auth.currentSession();
      options = {
        headers: {
          "Authorization": auth.idToken.jwtToken,
          "accessTokenStr": auth.accessToken.jwtToken
        }
      }
    }
    await fetch(`${env.server}/get_followed_dumplings?userId=${auth.username || auth.idToken.payload.email}`, options).then(res => res.json()).then(json => {

      if (json.statusCode === "404") {
        setFollowedDumplings([])
      } else {
        if (sort === 'Most recent') {
          setFollowedDumplings(sortMostRecent(json));
        }
        else if (sort === 'A-Z') {
          setFollowedDumplings(sortAlphabetical(json));
        }
        else if (sort === 'Highest balance') {
          setFollowedDumplings(sortBalance(json, 'high'));
        }
        else if (sort === 'Lowest balance') {
          setFollowedDumplings(sortBalance(json, 'low'));
        }
      }
      setIsLoading(false);
      console.log(json)
    }).catch(err => setIsLoading(false));
  }


  const sync = () => {
    const ebalance = getEverythingBalance(walletValues)
    //console.log("ebalance", ebalance)
    ebalance.then(function (result) {
      //console.log(result)
      setEverythingTotal(result)
    });

  }

  const getSingleDumpling = (dumplingId, dumplingTotal, dumplingAssets, isEverything = null, dumplingOwner, dumplingName, quantities, values) => {

    navigate('/singleDumpling',
      {
        state: {
          dumpling_id: dumplingId,
          myTotal: dumplingTotal,
          assets: dumplingAssets,
          dumplingOwner: dumplingOwner,
          dumpling_name: dumplingName,
          quantities: quantities,
          values: values,
          followed: true
        }
      });
  }


  return (
    <div>

      <div>
        {isLoading
          ? (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
              <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px' }} variant="text" width={360} height={40} />
              <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px' }} variant="text" width={360} height={40} />
              <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px', marginBottom: '16px' }} variant="rectangular" width={360} height={300} />
              <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px', marginBottom: '16px' }} variant="rectangular" width={360} height={136} />
            </div>
          ) : (
            <div style={{ marginBottom: "100px", maxWidth: '1200px' }}>

              {followedDumplings && followedDumplings.length > 0 &&


                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '17px' }}>
                    <h3 style={dumplingCount}>
                      {followedDumplings.length} Dumpling
                    </h3>
                    <SortButton variant="contained" onClick={() => toggleSort()}>
                      <span style={{ fontSize: '10px' }}>
                        {sort} <SortIcon />
                      </span>
                    </SortButton>
                  </div>

                  {followedDumplings !== undefined && followedDumplings.length > 0 &&
                    <div>
                      {followedDumplings.map((dumpling) => {
                        //console.log("dumpling", dumpling)
                        let total = 0;
                        let roundedTotal = 0;


                          return  (
                            <SharedDumplingCard dumpling={dumpling} roundedTotal={roundedTotal} total={total} goToDumpling={getSingleDumpling}/>
                          )
                          
                      })}
                    </div>
                  }
                </div>
              }
              {(!followedDumplings || followedDumplings.length === 0) &&
                <div style={{ display: 'flex', maxWidth: '100%', justifyContent: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '400px' }}>
                    <NoDumplingH1> You haven't followed any Dumplings yet </NoDumplingH1>
                  </div>
                </div>
              }
            </div>
          )}
      </div>
      <SortDrawer openSort={openSort} setSort={setSort} toggleSort={toggleSort} sortType={sort} />
    </div>
  );
}

export default FollowDumplings;

