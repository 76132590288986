import { ReactComponent as CoinbaseLogo } from './crypto-wallets/coinbase.svg';
import { ReactComponent as MetamaskLogo } from './crypto-wallets/metamask.svg';
import { ReactComponent as WalletConnectLogo } from './crypto-wallets/walletconnect.svg';


export const walletMap = {
  "Coinbase": <CoinbaseLogo />,
  "coinbaseExchange": <CoinbaseLogo />,
  "Metamask": <MetamaskLogo />,
  "walletConnect": <WalletConnectLogo />
}