import {ChevronLeft} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

import {Auth} from 'aws-amplify';
import {AmplifyAuthKeys} from '../Components/AmplifyAuthKeys'

import {useState} from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import styled from "styled-components";
import verifyImg from '../images/verify.jpg';

const fabstyle = {
  backgroundColor: '#0887E2',
  padding: "5px",
  width: "80%",
  borderRadius: '30px',
  fontFamily: "Montserrat-Regular",
  textTransform: "none",
  fontSize: 16,
  fontWeight: 600
};

/*
Amplify.configure({
  Auth: {
    userPoolId: 'us-east-1_l8TVD6ZwX', //UserPool ID
    region: 'us-east-1',
    userPoolWebClientId: '74abi9q7rroa1ua9km9mk0ah21' //WebClientId
  }
});
*/
AmplifyAuthKeys();

const StyledFolder = styled.div`
  width: 384px;
  height: 217px;
  background: rgba(53, 67, 98, 1);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-bottom: 20px;
`;

const DumplingSubTitle = styled.h3`
  margin-top: "2px",
  font-family: "Montserrat-Regular !important",
  font-size: "16px",
  font-weight: 200,
  color: "white",
  text-align: "center"

`;

const StyledButton = styled.button`
  margin-top: '40px', 
  border-radius: '30px', 
  background-color: '#0887E2', 
  color: 'white', 
  height: '44px', 
  fontSize: '16px' 
`;

export const StyledSeconds = styled.div`
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.002em;
  color: rgba(255, 255, 255, 0.4);
`;

const StyledError = styled.div`
  margin-top: 13px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #ef6c65;
`;

const StyledReactInputVerificationCode = styled.div`
  display: flex;
  justify-content: center;

  --ReactInputVerificationCode-itemWidth: 50px;
  --ReactInputVerificationCode-itemHeight: 61px;
  --ReactInputVerificationCode-itemSpacing: 5px;

  .ReactInputVerificationCode__item {
    font-size: 16px;
    font-weight: 500;
    color: #fff;

    background: rgba(255, 255, 255, 0.15);
    border: 1px solid
      ${({ isInvalid }) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
    border-radius: 10px;
    box-shadow: none;
  }

  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 1px solid #36c6d9;
  }
`;

export default function CodeVerify() {
  const { state } = useLocation();

  const [value, setValue] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [error, setError] = useState(null);
  const [seconds, setSeconds] = useState(null);
  let navigate = useNavigate();

  const getAuthTokens = async () => {
    const auth = await Auth.currentSession();
    window.localStorage.setItem('idToken', auth.idToken.jwtToken);
  }

  const confirmSignUp = async (e) => {
    await Auth.confirmSignUp(state.email, value).catch((err) => {
      // Something is Wrong
      console.log('error verrifying your code', err);
    });
    await Auth.signIn(state.email, state.password)
      .then(res => { 
        getAuthTokens();
        navigate('/publicDumplings'); 
      }).catch(err => console.log(err));
  }

  const resendCode = async () => {
    await Auth.resendSignUp().catch(err => console.log('Could not resend code: ', err));
  }


  return (

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '17px' }}>

      <Box sx={{
        backgroundColor: "#171F38", 
        color: "white",
      }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <ChevronLeft onClick={() => navigate(-1)} style={{ float: 'left', position: 'relative', top: '47px', left: '40px', color: '#FFFFFF' }}/>
            <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>SIGN UP</h1>
            <hr style={{ borderColor: 'rgba(255,255,255,0.2)' }}/>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '85px' }}>
          <img src={verifyImg} width="60%" alt="verify logo"/>
          <div style={{width: "80%", textAlign: "center", fontFamily: "Montserrat-Regular" }}>
            <DumplingSubTitle> 
              Enter the 6-digit code sent to your email
            </DumplingSubTitle>
          </div>
          <StyledReactInputVerificationCode isInvalid={isInvalid}>
            <ReactInputVerificationCode
              value={value}
              placeholder={null}
              length={6}
              onChange={(newValue) => {
                console.log(newValue)
                setValue(newValue);

                if (newValue !== "") {
                  setError(null);
                }
              }}
            />
          </StyledReactInputVerificationCode>
        </div>
        

      {error && <StyledError>{error}</StyledError>}

      {seconds && (
        <StyledSeconds>{`Verification code has been re-sent (${seconds}s)`}</StyledSeconds>
      )}

{/* 
      <Button    
          onClick={() => {
          confirmSignUp()
          setValue("");
        }}
        
        style={{ marginTop: '40px', borderRadius: '30px', width: "80%", backgroundColor: '#0887E2', color: 'white', height: '44px', fontSize: '16px' }} variant="contained" 
        >
              Confirm
            </Button> */}

        <div style={{ display: 'flex', textTransform: "none", fontFamily: 'Montserrat-Regular', flexDirection: 'column', width: '100%', justifyContent: 'flex-end', alignItems: 'center', minHeight: 200 }}>
          <Fab  
            onClick={() => {
              confirmSignUp()
              setValue("");
            }} 
            sx={{ color: '#FFFFFF'}} 
            style={fabstyle} variant="extended" aria-label="add">
            Confirm
          </Fab>
          <div onClick={() => resendCode()} style={{ color: '#0887E2', fontSize: 16, fontWeight: 600, marginTop: 16, textTransform: "none", fontFamily: 'Montserrat-Regular' }}>
            Resend
          </div>
        </div>
      

            </Box>

    </div>
      

   
  );
}
