const environments = {
  development: {
    server: 'https://odqztd406i.execute-api.us-east-1.amazonaws.com/prod',
//         server: 'https://emp26kfyq1.execute-api.us-east-1.amazonaws.com/prod2',
  },
  local: {
    server: 'http://127.0.0.1:5000',
  },
  staging: {
//    server: 'https://mjgq0oofr1.execute-api.us-east-1.amazonaws.com/uat',
     server: 'https://d7utqx2og6.execute-api.us-east-1.amazonaws.com/uat2',
  },
  production: {
    server: '',
  },
}

function setEnvironment() {
//   const nodeEnv = process.env.REACT_APP_DEPLOY_ENV || process.env.NODE_ENV
  return environments['development']
//   return environments['staging']
//     return environments['local']
}

const env = setEnvironment()

export { env }
