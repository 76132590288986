import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import "./styles.css";
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import {Auth} from 'aws-amplify';
import {AmplifyAuthKeys} from '../Components/AmplifyAuthKeys'

import {Component} from "react";
import {
  useNavigate
} from 'react-router-dom';
import validator from 'validator';
import Button from "../Components/Button";

/*
Amplify.configure({
  Auth: {
    userPoolId: 'us-east-1_l8TVD6ZwX', //UserPool ID
    region: 'us-east-1',
    userPoolWebClientId: '74abi9q7rroa1ua9km9mk0ah21' //WebClientId
  }
});
*/

AmplifyAuthKeys();

const modalStyle = {
  position: 'absolute',
  fontFamily: "Montserrat-Regular",
  top: '50%',
  left: '50%', 
  transform: 'translate(-50%, -50%)',
  minWidth: '300px',
  bgcolor: 'background.paper',
  borderRadius: 5,
  borderColor: "white",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#3F4B5C", 
  color: "white"
};

// const [isOpen, setOpen] = React.useState(false);
// const handleOpen = () => setOpen(true);
// const handleClose = () => setOpen(false);


class Login extends Component {

  state = {
    email: "",
    password: "",
    validatePassword: "",
    areaCode: '+1',
    phone: "",
    formattedPhone: "",
    isAuthenticated: false,
    jwtToken: "",
    pageIndex: 0,
    openDialog: false,
    verificationCode: "",
    name: "",
    isErrorOpen: false,
    errorMessages: []
  };

  handleChange = e => {
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });
  };

  formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  handleAreaCode = e => {
    const code = e.currentTarget.value.length > 0 && e.currentTarget.value[0] === '+' ? e.currentTarget.value : '+1';
    this.setState({
      areaCode: code
    })
  }

  handlePhone = e => {
    const newPhone = e.currentTarget.value.replace(/[^+\d]+/g, "");
    this.setState({ 
      phone: `${this.state.areaCode}${newPhone}`,
      formattedPhone: this.formatPhoneNumber(e.currentTarget.value)
    });
  }

  handleErrorOpen = () => this.setState({ isErrorOpen: true });
  handleErrorClose = () => this.setState({ isErrorOpen: false });


  submitSignup = e => {
    var passwordValidator = require('password-validator');
    var schema = new passwordValidator();
    schema
      .is().min(8)                                    // Minimum length 8
      .is().max(100)                                  // Maximum length 100
      .has().uppercase()                              // Must have uppercase letters
      .has().lowercase()                              // Must have lowercase letters
      .has().digits(1)                                // Must have at least 1 digits
      .has().symbols(1)                                // Must have at least 1 digits
      .has().not().spaces()                           // Should not have spaces
    // .is().not().oneOf(['Passw0rd', 'Password123']); // Blacklist these values
    const valid =  schema.validate(this.state.password, { details: true })

    const isPhoneValid = this.validatePhoneNumber(this.state.phone)

    if (valid.length === 0 && this.state.password === this.state.validatePassword) {

    const email = this.state.email
    const password = this.state.password
    const phone = this.state.phone
  
      Auth.signUp({username: email, password, phone,
        "attributes": {
          "email": this.state.email,
          "name": this.state.name,
          "phone_number": phone
        }}).then((result) => {
        // console.log("result", result)
        // console.log(result.userConfirmed)

        if (!result.UserConfirmed) {
          this.props.navigate('/verifyUser',
          {
              state: {
                phone: this.state.phone,
                password: this.state.password,
                email: this.state.email
              }
          });
        }

      }).catch((err) => {
        // Something is Wrong
        console.log(err)
        console.log(err.message)
        this.setState({ errorMessages: [err.message] });
        this.handleErrorOpen()
      })
    }
    else {
      const errMsg = []
      valid.forEach((error) => {
          errMsg.push(error.message)
      });
      if (errMsg.length === 0 && this.state.password !== this.state.validatePassword) {
        errMsg.push("Passwords do not match")
      } 
      if (!isPhoneValid){
        errMsg.push("Phone is not formatted correctly ie. +1 999 999 9999")
      }
      this.setState({ errorMessages: errMsg });
      this.handleErrorOpen()
    }
  }


  confirmSignUp = async (e) => {
    await Auth.confirmSignUp(this.state.email, this.state.verificationCode).catch((err) => {
      // Something is Wrong
      console.log('error verrifying your code', err);
    })
    await Auth.signIn(this.state.email, this.state.password).then(res => {
      this.props.navigate('/publicDumplings');
    }).catch(err => {
      console.log('error', err);
    })
  }


  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleTextFieldChange = e => {
    this.setState({
      verificationCode: e.target.value
    });
  }


  validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number) && number.startsWith("+")

    return (isValidPhoneNumber)
  }
  


  render() {
    const formStyle = {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'flex-start',
      marginTop: 20
    }

    const inputContainer = {
      display: 'flex',
      width: '100%',
      marginTop: '15px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: 'white',
      fontFamily: 'Montserrat-Regular',
      fontSize: '14px'
    }

    const textField = {
      width: '92%',
      height: '44px',
      paddingLeft: '12px',
      paddingRight: '12px',
      borderRadius: 10,
      border: '1px solid white',
      backgroundColor: 'transparent',
      color: 'white',
      fontSize: '16px'
    }
    
    const areaCodeField = {
      width: '8%',
      height: '44px',
      paddingLeft: '12px',
      paddingRight: '12px',
      borderRadius: 10,
      border: '1px solid white',
      backgroundColor: 'transparent',
      color: 'white',
      fontSize: '16px'
    }

    const buttonStyles = {
      width: '100%',
      height: '46px',
      marginTop: '30px',
      marginBottom: '0px',
      borderRadius: 30,
      backgroundColor: '#0887E2',
      color: '#FFFFFF',
      fontFamily: 'Montserrat-Regular',
      fontSize: '16px',
      fontWeight: 600,
      textTransform: 'none'
    }

    return (
      <div>
        <div>
          <form style={formStyle} className="form">
          <label style={inputContainer}>
              <span style={{ marginBottom: '4px' }}>Name</span>
              <input style={textField} id="name" placeholder='James Bond' type='text' onChange={this.handleChange}/>
            </label>
            <label style={inputContainer}>
              <span style={{ marginBottom: '4px' }}>Email</span>
              <input style={textField} id="email" placeholder='agent007@MI6.gov' type='text' onChange={this.handleChange}/>
            </label>
            <label style={inputContainer}>
              <span style={{ marginBottom: '4px' }}>Password</span>
              <input style={textField} id="password" type='password' placeholder='********' onChange={this.handleChange}/>
            </label>
            <p style={{...inputContainer, marginBottom: '0px', marginTop: 27}}>
              <strong>Password requirements:</strong>
              <ul>
                <li>Must be 8 characters minimum</li>
                <li>Must contain at least one special character, one number, one capital letter, and at least one lower case letter</li>
              </ul>
            </p>
            <label style={inputContainer}>
              <span style={{ marginBottom: '4px' }}>Re-enter Password</span>
              <input style={textField} id="validatePassword" type='password' placeholder='********' onChange={this.handleChange}/>
            </label>
            <label style={inputContainer}>
              <span style={{ marginBottom: '4px' }}>Mobile Phone Number</span>
              <div style={{ display: 'flex' }}>
                <input style={areaCodeField} id="areaCode" placeholder="+XXX" defaultValue={this.state.areaCode} onChange={this.handleAreaCode}/>
                <input style={textField} id="phone" placeholder='(999)-999-9999' value={this.state.formattedPhone} type='tel' onChange={this.handlePhone}/>
              </div>
            </label>

            <Button style={buttonStyles} type="button" color="primary" className="form__custom-button" onClick={this.submitSignup}>
              Sign up
            </Button>
          </form>
        </div>
        <div>
          <Dialog open={this.state.openDialog} onClose={this.handleClose}>
            <DialogTitle>Code Sent!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                We sent a verification code to {this.state.phone}. Please enter the code that was sent to your email below.
              </DialogContentText>
              <TextField
                sx={{ input: { color: 'white' } }}
                autoFocus
                margin="dense"
                id="name"
                label="Verification Code"
                type="email"
                fullWidth
                variant="standard"
                value={this.state.verificationCode}
                onChange={this.handleTextFieldChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button onClick={this.confirmSignUp}>Verify Phone</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
        <Modal
          open={this.state.isErrorOpen}
          onClose={this.handleErrorClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
          <div style={{display: "flex"}}>
            <h1 style={{marginBottom: "0px"}}>{`Unable to sign up! `}</h1>
            </div>
            <h3> 
            <div style={{marginTop: "20px", marginBottom: "10px"}}>
            {this.state.errorMessages.map(function(item, i){
              return <li key={i}>{item}</li>
            })}
          </div>
            </h3>
            <Button style={buttonStyles} onClick={this.handleErrorClose} >Ok</Button>
        </Box>
      </Modal>
        </div>
        
      </div>
    );
  }
}


function SignUpForm(props) {
  let navigate = useNavigate();
  return <Login {...props} navigate={navigate} />
}

export default SignUpForm