// import Dumpling from '../images/DumplingLogo.jpg'
import { styled } from '@material-ui/core';
import CircleIcon from '@mui/icons-material/Circle';
import { Container, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  useLocation
} from 'react-router-dom';
import { env } from '../environment';
import Dumpling from './Dumpling';
import SharedDumplings from './SharedDumplings';
import Following from './ImFollowing';

const title = {
  fontFamily: 'Montserrat-Regular',
  fontSize: "14px",
  textAlign: "center",
  marginTop: '50px',
  marginBottom: '18px',
  letterSpacing: '0.12em'
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, paddingLeft: '0px', paddingRight: '0px' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DumplingTabs = styled(Tabs)({
  borderBottom: 1,
  width: '100%',
  borderColor: 'white',
  '& .MuiTabs-indicator': {
    backgroundColor: 'white'
  }
})

const dumplingTab = {
  fontFamily: 'Montserrat-Regular',
  fontSize: '12px',
  width: '33%',
  letterSpacing: '0.1em',
  color: 'rgba(255,255,255,0.5)',
  weight: 700,
  '&.MuiTab-textColorPrimary': {
    color: 'rgba(255,255,255,0.5)'
  },
  '&.Mui-selected': {
    color: 'white'
  },
  '&.MuiTab-fullWidth': {
    width: '20px'
  }
}

const DumplingTab = styled(Tab)(({ theme }) => ({
  ...dumplingTab
}));

export default function Dashboard() {

  const [showNotification, setShowNotification] = useState(false);
  const { state } = useLocation();
  const [value, setValue] = React.useState((state !== null && state.isShared) ? 1 : (state !== null && state.isFollowed) ? 2 : 0);

  const getSharedDumplings = async () => {
    const auth = await Auth.currentSession();
    const GET_SHARED_DUMPLINGS_ENDPOINT = `${env.server}/get_shared_dumplings?userId=${auth.idToken.payload.email}`;
    const options = {
      headers: {
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      }
    }

    await fetch(GET_SHARED_DUMPLINGS_ENDPOINT, options)
      .then(res => res.json())
      .then(json => {
        checkNewSharedDumplings(json)
      });
  }

  useEffect(() => {
    getSharedDumplings();
  }, []);

  function checkNewSharedDumplings(sharedDumplings) {

    if (sharedDumplings.length > 0) {

      var hasFalseKeys = sharedDumplings.map(v => !!v.flag);
      let checker = arr => arr.every(Boolean);
      let hideModal = checker(hasFalseKeys)


      if (hideModal === false) {
        setShowNotification(true)
      }
    }
  }


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, color: "white", backgroundColor: '#171E37' }}>
      <h1 style={title}>DUMPLINGS</h1>
      <Divider style={{ borderColor: 'rgba(255,255,255,0.2)', marginBottom: '32.5px' }} />
      <Container style={{ backgroundColor: '#171E37' }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          color: "white"
        }}>
        <Box sx={{ borderBottom: 1, borderColor: 'rgba(255,255,255,0.5)'}}>
          <DumplingTabs value={value} onChange={handleChange} centered aria-label="basic tabs example">
            <DumplingTab sx={dumplingTab} disableRipple label="Created By Me" {...a11yProps(0)} />

              {showNotification &&
                <DumplingTab sx={dumplingTab} disableRipple label="Shared With Me" {...a11yProps(1)} icon={<CircleIcon sx={{ color: "#02EDBA", height: "12px", width: "12px" }} />} iconPosition="end" />
              }
              {!showNotification &&
                <DumplingTab sx={dumplingTab} disableRipple label="Shared With Me" {...a11yProps(1)} />
              }

              <DumplingTab sx={dumplingTab} disableRipple label="I'm Following" {...a11yProps(2)} />

            </DumplingTabs>
          </Box>
          <TabPanel style={{ minHeight: '81vh' }} value={value} index={0}>
            <Dumpling />
          </TabPanel>
          <TabPanel style={{ minHeight: '81vh' }} value={value} index={1}>
            <SharedDumplings />
          </TabPanel>
          <TabPanel style={{ minHeight: '81vh' }} value={value} index={2}>
            <Following />
          </TabPanel>
        </Box>
      </Container>
    </Box>
  );
}
