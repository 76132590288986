import {styled} from '@material-ui/core';
import {Skeleton, Tab, Tabs} from '@mui/material';
// import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import PropTypes from 'prop-types';
import '@uniswap/widgets/fonts.css';
import {Auth} from 'aws-amplify';
import {useEffect,useState} from 'react';
import {useNavigate} from 'react-router-dom';
import 'simple-react-donut-chart/src/style.css';
import FeaturedDumplingCard from '../Components/FeaturedDumplingCard';
import {env} from '../environment';
import SharedDumplingCard from '../Components/SharedDumplingCard';
import DumplingTags, {sortByTag} from '../Components/DumplingTags';

const dumplingTitle = {
  padding: "6px",
  paddingBottom: '0px',
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 600,
  marginBottom: "0px",
  marginTop: '0px',
  color: "white",
  whiteSpace: "nowrap"
}

const dumplingSubTitle = {
  marginTop: "2px",
  paddingLeft: "8px",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "0px",
  color: "white",
}

const dumplingSub = {
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 400,
  fontFamily: 'Montserrat-Regular',
  marginTop: '5px',
  marginBottom: "20px",
  color: "white"
}

const DumplingSub = styled('h3')(({ theme }) => ({
  ...dumplingSub,
  [theme.breakpoints.down('md')]: {
    fontSize: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px'
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));

const dumplingDesc = {
  maxHeight: '160px',
  paddingLeft: "8px",
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 200,
  marginTop: "10px",
  marginLeft: '5px',
  color: "white",
  overflowY: 'scroll'
}

const DumplingDesc = styled('h3')(({ theme }) => ({
  ...dumplingDesc,
  [theme.breakpoints.down('md')]: {
    fontSize: '14px'
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));

const currSubtitle = {
  marginTop: "0px",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  color: "white",
  float: "left"
}

const header= {
  textAlign: "left"
}

const title = {
  fontSize: "14px",
  fontWeight: 600,
  marginTop: '50px',
  marginBottom: '18px',
  textAlign: "center",
  fontFamily: 'Montserrat-Regular',
  letterSpacing: '0.12em'
}

const sectionTitle = {
  marginBottom: '0px',
  fontSize: "24px",
  fontFamily: 'Montserrat-Regular',
  fontWeight: 500,
}

const SectionTitle = styled('h1')(({ theme }) => ({
  ...sectionTitle,
  [theme.breakpoints.down('md')]: {
    marginTop: '20px'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, paddingLeft: '0px', paddingRight: '0px' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DumplingTabs = styled(Tabs)({
  borderBottom: 1,
  borderColor: 'white',
  '& .MuiTabs-indicator': {
    backgroundColor: 'white'
  }
})

const dumplingTab = {
  fontFamily: 'Montserrat-Regular',
  fontSize: '12px',
  letterSpacing: '0.1em',
  marginLeft: "-15px",
  width: '50%',
  color: 'rgba(255,255,255,0.5)',
  weight: 700,
  '&.MuiTab-textColorPrimary': {
    color: 'rgba(255,255,255,0.5)'
  },
  '&.Mui-selected': {
    color: 'white'
  }
}

const DumplingTab = styled(Tab)(({ theme }) => ({
  ...dumplingTab,
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));

const PublicDumplings = () => {
  const [authInfo, setAuthInfo] = useState();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [publicDumplings, setPublic] = useState();
  const [communityDumplings, setCommunity] = useState();
  const [filteredDumplings, setFiltered] = useState();
  const navigate = useNavigate();
  const [defaultTags, setDefaultTags] = useState([]);
  const handleTags = (e, newTags) => {
    setDefaultTags(newTags);
    if(newTags.length === 0) {
      setFiltered(null);
    }
    else {
      setFiltered(sortByTag(newTags, communityDumplings));
    }
  }

  const getPublicDumplings = async () => {
    const auth = await Auth.currentSession();
    setAuthInfo({
      idToken: auth.idToken.jwtToken,
      accessToken: auth.accessToken.jwtToken,
      username: auth.idToken.payload.email
    });
    const options = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      }
    }

    await fetch(`${env.server}/get_public_dumplings?dumpling_type=public`, options)
      .then(res => res.json())
      .then(json => {
        setPublic(json);
        console.log('test',json)
      }).catch(err => {
        console.log(err);
      });

    await fetch(`${env.server}/get_public_dumplings?dumpling_type=featured`, options)
      .then(res => res.json())
      .then(json => {
        setCommunity(json);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    getPublicDumplings();
  }, []);

const fabstyle = {
    marginTop: '16px',
    marginBottom: '16px',
    backgroundColor: '#0887E2',
    padding: "5px",
    height: '44px',
    minWidth: '358px',
    fontFamily: "Montserrat-Regular",
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'none'
};

  const totalBalanceStyle = {
    color: "white",
    fontSize: "40px",
    fontWeight: 200,
    textAlign: "left",
    marginBottom: "0px !important",
  }

  const chartdata = [
  { name: "ETH", value: 40 },
  { name: 'BTC', value: 40 },
  { name: 'SOL', value: 20 },
];
const COLORS = ['#9D4FFF', '#02EDBA', '#FF50F9', '#9C4FFF'];

const handleTabChange = (event, newValue) => {
  value === 0 
    ? setValue(1) 
    : setValue(0)
}

const handleCreateDumpling = async () => {
  const options = {
    headers: {
      "Authorization": authInfo.idToken,
      "accessTokenStr": authInfo.accessToken
    }
  }

  await fetch(`${env.server}/get_user_deduped_wallets?userId=${authInfo.username}`, options)
    .then(res => res.json())
    .then(json => {
      if(json.length !== 0) {
        navigate('/create', { replace: true, state: { existing: true } });
      }
      else {
        navigate('/connect');
      }
    })
    .catch(err => {
      console.log('unable to get user wallets...', err);
    })
}

const getSingleDumpling = (dumplingId, dumplingTotal, dumplingAssets, isEverything = null, dumplingOwner, dumplingName, quantities, values) => {

  navigate('/singleDumpling',
      {
          state: {
            dumpling_id: dumplingId,
            myTotal: dumplingTotal,
            assets: dumplingAssets,
            dumplingOwner: dumplingOwner,
            dumpling_name: dumplingName,
            quantities: quantities,
            values: values,
            public: true
          }
      });
}

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: '#171E37', color: "white" }}>
      <h1 style={title}>HOME</h1>
      <Divider style={{width:'100%', borderColor: 'rgba(255,255,255,0.2)'}}/>
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={358}
          >
          <div style={{ marginBottom: '172px' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'rgba(255,255,255,0.5)' }}>
                <DumplingTabs value={value} variant="fullWidth" onChange={(e) => handleTabChange(e)} centered aria-label="basic tabs example">
                  <DumplingTab sx={dumplingTab} disableRipple label="Featured" {...a11yProps(0)} />
                  <DumplingTab sx={dumplingTab} disableRipple label="Public" {...a11yProps(1)} />
                </DumplingTabs>
              </Box>
              <TabPanel value={value} index={0}>
                <div style={header}>
                  <SectionTitle>Get inspired</SectionTitle>
                  <DumplingSub>Explore our Featured Dumplings </DumplingSub>
                </div>
                { isLoading
                  ? (
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                      <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px' }} variant="text" width={360} height={40}/>
                      <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px' }} variant="text" width={360} height={40}/>
                      <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px', marginBottom: '16px' }} variant="rectangular" width={360} height={300} />
                      <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px', marginBottom: '16px' }} variant="rectangular" width={360} height={136} />
                    </div>
                  )
                  : (<>
                      { publicDumplings !== undefined &&  publicDumplings.length > 0 &&

                          <div>
                            {publicDumplings.map((dumpling) => {
                              return (
                                <FeaturedDumplingCard dumpling={dumpling} />
                              )})}
                          </div>
                      }
                  </>) }
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div style={header}>
                  <SectionTitle>Explore dumplings shared by members of the community</SectionTitle>
                </div>
                <div className='customize-feed'>
                  <p style={{ fontFamily: 'Montserrat-Regular', fontSize: 12, fontWeight: 600 }}>Customize your feed</p>
                  <DumplingTags defaultTags={defaultTags} handleFn={handleTags}/>
                </div>
                { isLoading
                  ? (
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                      <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px' }} variant="text" width={360} height={40}/>
                      <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px' }} variant="text" width={360} height={40}/>
                      <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px', marginBottom: '16px' }} variant="rectangular" width={360} height={300} />
                      <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px', marginBottom: '16px' }} variant="rectangular" width={360} height={136} />
                    </div>
                  )
                  : (<>
                      { communityDumplings !== undefined &&  communityDumplings.length > 0 &&

                          <div style={{ marginTop: 20 }}>
                            {filteredDumplings 
                              ? filteredDumplings.map((dumpling) => {
                                return (
                                  <SharedDumplingCard dumpling={dumpling} roundedTotal={0} total={0} goToDumpling={getSingleDumpling} isEverything={false}/>
                                )})
                              : communityDumplings.map((dumpling) => {
                                return (
                                  <SharedDumplingCard dumpling={dumpling} roundedTotal={0} total={0} goToDumpling={getSingleDumpling} isEverything={false}/>
                                )})}
                          </div>
                      }
                  </>) }
              </TabPanel>
            </div>
          </Box>
          </Container>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: '#171E37', position: 'fixed', top: 'auto', bottom: 96, borderTop: '1px solid rgba(255,255,255,0.25)' }}>
            <Fab sx={{ color: '#FFFFFF' }} onClick={() => handleCreateDumpling()} style={fabstyle} variant="extended" aria-label="add">
              <p>Create a dumpling</p>
            </Fab>
          </div>
          </Box>
  );
}

export default PublicDumplings;

