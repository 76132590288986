// eslint-disable-next-line react-hooks/exhaustive-deps
// import { styled } from '@mui/material/styles';
import { styled } from '@material-ui/core';
import { Check, ChevronLeft, Close } from '@mui/icons-material';
import { Container, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import '@uniswap/widgets/fonts.css';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  useLocation, useNavigate
} from 'react-router-dom';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import AssetList, { aggregateCurrencies } from '../Components/AssetList';
import Button from "../Components/Button";
import CurrencyCard, { determineChange } from '../Components/CurrencyCard';
import { ReactComponent as EditNameIcon } from '../Components/svgs/edit-name-icon.svg';
import { ReactComponent as ManageDumplingIcon } from '../Components/svgs/manage-dumpling.svg';
import { env } from '../environment';
import ManageDumpling from './ManageDumpling';
import ManageSharedDumpling from './ManageSharedDumpling';
import { aggregateSharedCurrencies } from '../Components/SharedDumplingCard';
import {buildTagList} from '../Components/DashboardDumplingCard';

const dumplingTitle = {
  paddingBottom: '0px',
  textAlign: "left",
  fontSize: "20px",
  fontFamily: 'Montserrat-Regular',
  fontWeight: 600,
  marginBottom: "0px",
  marginTop: '0px',
  color: "white",
  width: 280
}

const dumplingSubTitle = {
  marginTop: "5px",
  textAlign: "left",
  fontSize: "10px",
  fontFamily: 'Montserrat-Regular',
  fontWeight: 400,
  marginBottom: "0px",
  color: "rgba(255,255,255,0.5)",
}

const dumplingFollowTitle = {
  marginTop: "0px",
  textAlign: "left",
  fontSize: "10px",
  fontFamily: 'Montserrat-Regular',
  fontWeight: 400,
  marginBottom: "0px",
  color: '#3CA1EB'
}


const title = {
  fontSize: "14px",
  fontFamily: 'Montserrat-Regular',
  letterSpacing: '0.12em',
  textAlign: 'center',
  marginTop: '50px'
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, paddingLeft: '0px', paddingRight: '0px' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DumplingTabs = styled(Tabs)({
  borderBottom: 1,
  borderColor: 'white',
  '& .MuiTabs-indicator': {
    backgroundColor: 'white'
  }
})

const dumplingTab = {
  fontFamily: 'Montserrat-Regular',
  fontSize: '12px',
  letterSpacing: '0.1em',
  marginLeft: "-15px",
  width: '50%',
  color: 'rgba(255,255,255,0.5)',
  weight: 700,
  '&.MuiTab-textColorPrimary': {
    color: 'rgba(255,255,255,0.5)'
  },
  '&.Mui-selected': {
    color: 'white'
  }
}

const DumplingTab = styled(Tab)(({ theme }) => ({
  ...dumplingTab,
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));

const SingleDumpling = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [value, setValue] = React.useState((state !== null && state.isShared) ? 1 : (state !== null && state.isFollowed) ? 2 : 0);
  const [authInfo, setAuthInfo] = useState();
  const [isOpen, setOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [sharedWith, setSharedWith] = React.useState('');
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [quantitiesChecked, setQuantitiesChecked] = useState();
  const [valuesChecked, setValuesChecked] = useState();
  const [name, setName] = useState();
  const [isNameFocused, setIsNameFocused] = React.useState(false);

  const [myDumpling, setMyDumpling] = useState();

  const [isFilled, setIsFilled] = useState(false);
  const [followCount, setFollowCount] = useState(0);


  async function followDumpling() {

    var raw = JSON.stringify({
      "dumplingOwnerId": state.dumplingOwner,
      "dumplingId": state.dumpling_id,
      "followerId": authInfo.username,
      "followerFirstName": firstName,
      "followerLastName": lastName
    });

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      },
      body: raw,
      redirect: 'follow'
    };

    await fetch(`${env.server}/follow_dumpling`, requestOptions).then(res => res.json()).then(json => {
      //console.log("Test Follow", json)
    }).catch(error => console.log('error', error));

  }

  async function unfollowDumpling() {
    var raw = JSON.stringify({
      "dumplingOwnerId": state.dumplingOwner,
      "dumplingId": state.dumpling_id,
      "followerId": authInfo.username,
      "followerFirstName": firstName,
      "followerLastName": lastName
    });

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      },
      body: raw,
      redirect: 'follow'
    };

    await fetch(`${env.server}/unfollow_dumpling`, requestOptions).then(res => res.json()).then(json => {

      //console.log("ignore", json)
      //window.location.reload()
      //setOpen(true);// create new modal  for succesfful change

    }).catch(error => console.log('error', error));
  }

  const getDumpling = async () => {

    const auth = await Auth.currentSession();
    setAuthInfo({
      idToken: auth.idToken.jwtToken,
      accessToken: auth.accessToken.jwtToken,
      username: auth.idToken.payload.email
    });
    const options = {
      headers: {
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      }
    }

    if (state.isEverything) {
      await fetch(`${env.server}/get_user_dumplings?userId=${auth.idToken.payload.email}`, options)
        .then(res => res.json())
        .then(json => {
          if (json) {
            setMyDumpling(json[json.length - 1]);
          }
        }).catch(err => console.log('could not get everything', err));
    }
    /*
    else if (state.isPublic) {
      await fetch(`${env.server}/get_dumpling?userId=abc.xyz@slalom.com&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
        //console.log("user", json)
        setMyDumpling(json)
      });
    }
    */
    else if (state.shared || state.followed || state.public) {
      /*
      await fetch(`${env.server}/get_dumpling?userId=${state.dumplingOwner}&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
        console.log("user", json)
        setMyDumpling(json)
      });
      */
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "id": state.dumpling_id,
        "dumplingOwnerId": state.dumplingOwner,
        "dumplingFollowerId": auth.idToken.payload.email,
        "quantitiesFlag": state.quantities,
        "valuesFlag": state.values
      });

      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": auth.idToken.jwtToken,
          "accessTokenStr": auth.accessToken.jwtToken
        },
        body: raw,
        redirect: 'follow'
      };

      await fetch(`${env.server}/get_shared_dumpling_by_id`, requestOptions).then(res => res.json()).then(json => {
        //console.log("TEST SHARE", json)
        setMyDumpling(json);
        setIsFilled(json.following_flag)
        setFollowCount(json.follow_count)
      }).catch(error => console.log('error', error));
    }
    else if (state.isRefreshed) {
      await fetch(`${env.server}/get_dumpling?userId=${auth.idToken.payload.email}&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
        //console.log("user", json)
        setMyDumpling(json)
      });
    }
    else {
      await fetch(`${env.server}/get_dumpling?userId=${auth.idToken.payload.email}&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
        //console.log("user", json)
        setMyDumpling(json)
      });
    }
  }
  /*
    useEffect(() => {
      getDumpling();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  */

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 5,
    borderColor: "white",
    boxShadow: 24,
    p: 4,
    backgroundColor: "#3F4B5C",
    color: "white"
  };



  const bigTotal = {
    color: "white",
    fontSize: "40px",
    fontFamily: 'Montserrat-Regular',
    fontWeight: 200,
    textAlign: "center",
    margin: "10px",
  }

  const getChartData = () => {
    if (state.shared || state.followed || state.public) {
      const aggregate = aggregateSharedCurrencies(myDumpling.currency_set, myDumpling.wallet_info);
      // console.log(aggregate);
      const data = [];

      //now create chartData
      for (let type in aggregate) {
        data.push({
          name: type,
          value: aggregate[type].percentage
        });
      }

      return data.length !== 0 ? data : null;
    }
    else {
      const { aggregate, totalValue } = aggregateCurrencies(myDumpling.currency_set, myDumpling.wallet_info);
      // console.log(aggregate);
      const data = [];

      //now create chartData
      for (let type in aggregate) {
        data.push({
          name: type,
          value: aggregate[type].value / totalValue * 100
        });
      }

      return data.length !== 0 ? data : null;
    }
  }

  const mockData = [
    { name: "ETH", value: 100 }
  ];

  const COLORS = ['#02EDBA', '#3CA1EB', '#9C4FFF', '#EFD54D', '#FF50F8', '#FF9B00'];
  const getChartColors = (data) => {
    data.map((entry, index) => {
      entry.color = COLORS[index % COLORS.length];
    });

    return data;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleToggle = () => {
    setIsFilled(!isFilled)

    if (isFilled) {
      unfollowDumpling()
      var result = parseInt(followCount)
      result = result - 1
      setFollowCount(result)
    }
    else {
      followDumpling()
      var result = parseInt(followCount)
      result = result + 1
      setFollowCount(result)
      dumplingFollowTitle.color = "white"
    }
  }

  const manageDumpling = (dumplingID, myTotal) => {
    navigate('/manageDumpling',
      {
        state: {
          dumpling_id: state.dumpling_id,
          username: authInfo.username
        }
      });
  }

  const openDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const editDumpling = async () => {
    const options = {
      headers: {
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      }
    }

    await fetch(`${env.server}/change_dumpling_name?userId=${authInfo.username}&dumplingId=${state.dumpling_id}&name=${name}`, options).then(res => res.json()).then(json => {
      //console.log("edit", json);
      setIsNameFocused(false);
      window.location.reload(false);
    });
  }

  useEffect(() => {

    Auth.currentUserInfo().then((result) => {
      let lastname = result.attributes.name !== "" ? result.attributes.name.split(' ').pop() : ""
      var index = result.attributes.name.lastIndexOf(" ");
      let firstname = result.attributes.name.substring(0, index);
      //console.log(result)
      setFirstName(result.attributes.name)
      setFirstName(firstname)
      setLastName(lastname)
    }).catch((err) => {
      // Something is Wrong
      console.log('error getting user', err);
    })
    getDumpling()

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (<>
    {myDumpling
      ? <div style={{ backgroundColor: '#171E37' }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          color='white'
        >
          <Container style={{ backgroundColor: '#171E37' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', width: '100%' }}>
              <div style={{ width: '100%' }}>

                {state.shared === true
                  ? <ChevronLeft onClick={() => navigate('/dashboard', { state: { isShared: state.shared } })} style={{ float: 'left', position: 'relative', top: '47px', left: 16, color: '#FFFFFF' }} />
                  : (<>
                    {state.followed === true
                      ? <ChevronLeft onClick={() => navigate('/dashboard', { state: { isFollowed: state.followed } })} style={{ float: 'left', position: 'relative', top: '47px', left: 16, color: '#FFFFFF' }} />
                      : <ChevronLeft onClick={() => navigate(-1)} style={{ float: 'left', position: 'relative', top: '47px', left: 16, color: '#FFFFFF' }} />
                    }
                  </>)
                }
                
                {state.shared === true

                  ? <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Shared With Me</h1>
                  : (<>
                    {state.followed === true
                      ? (<h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>I'm Following</h1>)
                      : (<>
                        {state.public === true
                          ? <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Created By Community</h1>
                          : <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Created By Me</h1>}
                      </>)
                    }</>)


                }
                <hr style={{ width: '100%', borderColor: 'rgba(255,255,255,0.2)' }} />
              </div>
            </div>
            <Container style={{ marginTop: '20px', paddingLeft: '0px', paddingRight: '0px', maxWidth: '400px' }}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: "flex" }}>
                  <div style={{ float: "left", marginBottom: "20px", marginRight: "20px" }}>
                    {/* <img src={littleDumplings} width="150px" style={{ float: "left" }} /> */}
                    <Box>
                      {state.isEverything
                        ? <h3 style={dumplingTitle}> All of my assets </h3>
                        : (<>
                          {state.shared || state.followed
                            ? <h3 style={dumplingTitle}> {myDumpling.name} </h3> :
                            !isNameFocused
                              ? <h3 style={dumplingTitle}> {myDumpling.name} <EditNameIcon onClick={() => setIsNameFocused(true)} style={{ marginLeft: '10px' }} /></h3>
                              : (<>
                                <TextField
                                  InputProps={{ sx: { color: '#FFFFFF', '&::placeholder': { color: '#FFFFFF' }, fontSize: '18px', fontWeight: 400 } }}
                                  autoFocus
                                  variant="standard"
                                  placeholder={myDumpling.name}
                                  onChange={(event) => setName(event.target.value)} />
                                <IconButton
                                  sx={{
                                    color: '#3CA1EB',
                                    marginTop: '-18px',
                                    '&:disabled': {
                                      color: 'white',
                                      opacity: 0.3
                                    }
                                  }}
                                  disabled={name === "" || name === " " || name === undefined} onClick={() => {
                                    editDumpling()
                                  }}>
                                  <Check />
                                </IconButton>
                                <Close style={{ color: '#3CA1EB' }} onClick={() => setIsNameFocused(false)} />
                              </>)}

                        </>)}

                      {state.shared || state.followed || state.public
                        ? <h3 style={dumplingSubTitle}> Shared by {myDumpling.user_full_name} </h3> : ''
                      }
                      <h3 style={dumplingSubTitle}> Created {(Date(myDumpling.created_at)).slice(0, 16)} </h3>

                    </Box>
                  </div>
                </div>
                {!state.isEverything && !state.followed && !state.public
                  ? <ManageDumplingIcon style={{ marginTop: '14px' }} onClick={() => openDrawer()} />
                  : (<>

                    <Grid container spacing={0} columns={1} paddingLeft={5} whiteSpace='nowrap' >
                      <Grid item >
                        <Checkbox sx={{ color: '#3CA1EB' }} checked={isFilled} onChange={handleToggle} icon={<StarOutlineIcon />} checkedIcon={<StarIcon />} />
                        <h3 style={dumplingFollowTitle}> {followCount} follows </h3>
                      </Grid>
                    </Grid>

                  </>)


                  /*
                  : <IconButton onClick={toggleFilledIcon} fontSize="small">
                    {isFilled ? <StarIcon /> && followDumpling() : <StarOutlineIcon />}
                  </IconButton>
                  */
                }

              </div>

              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'flex-start' }}>
                  <h2 style={{ marginTop: '0px', marginBottom: '5px', fontSize: '32px', fontWeight: 400 }}>
                    {state.values && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(myDumpling.total_balance))}
                  </h2>
                  {determineChange(myDumpling.old_total_balance, myDumpling.total_balance)}
                  <div>
                    <p style={{ fontFamily: 'Montserrat-Regular', fontSize: 10, fontWeight: 400, color: 'rgba(255,255,255,0.7)', marginTop: 15, marginBottom: 0 }}>{ myDumpling.tags && buildTagList(myDumpling) }</p>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'flex-end' }}>
                  <div style={{ display: "flex", width: 125, justifyContent: 'center', alignItems: 'flex-start', height: 125 }}>
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={getChartData() || mockData}
                          innerRadius="85%"
                          outerRadius="100%"
                          dataKey="value"
                          stroke="none"
                          paddingAngle={5}
                        >
                          {getChartData()
                            ? getChartData().map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))
                            : <Cell key={`cell-1`} fill={`rgba(255,255,255,0.5)`} />}
                        </Pie>
                        {/* <Legend verticalAlign="middle" layout="vertical"/> */}
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>

              <AssetList currencies={myDumpling.currency_set} walletInfo={myDumpling.wallet_info} colorMap={getChartData() ? getChartColors(getChartData()) : []} quantities={state.quantities} values={state.values} />
              {state.shared === true
                ?
                <><Box sx={{ borderBottom: 1, borderColor: 'rgba(255,255,255,0.5)' }}>
                  <DumplingTabs value={value} variant="fullWidth" onChange={handleChange} centered aria-label="basic tabs example">
                    <DumplingTab sx={dumplingTab} disableRipple label="Details" {...a11yProps(0)} />
                    <DumplingTab sx={dumplingTab} disableRipple label="Comments" {...a11yProps(1)} />
                  </DumplingTabs>
                </Box><TabPanel style={{ minHeight: '81vh' }} value={value} index={0}>
                    {myDumpling.public_desc && (<p style={{ fontSize: 14, fontFamily: 'Montserrat-Regular', marginTop: 36, marginBottom: 36 }}>{myDumpling.public_desc}</p>)}
                    <div style={{ marginBottom: '185px' }}>
                      <h3 style={{ fontSize: '12px', fontFamily: 'Montserrat-Regular', fontWeight: 600, marginTop: '36px' }}>
                        {myDumpling.currency_set?.length === 0 ? 'NO ASSETS FOUND' : 'ASSETS'}
                      </h3>
                      <CurrencyCard currencies={myDumpling.currency_set} walletInfo={myDumpling.wallet_info} quantities={state.quantities} values={state.values} />
                    </div>
                  </TabPanel><TabPanel style={{ minHeight: '81vh' }} value={value} index={1}>
                    <h3 style={{ fontSize: '15px', fontFamily: 'Montserrat-Regular', fontWeight: 600, marginTop: '36px' }}>
                      Coming Soon
                    </h3>
                  </TabPanel></>
                : (<>
                  {state.followed === true
                    ?
                    <h3 style={{ fontSize: '12px', fontFamily: 'Montserrat-Regular', fontWeight: 600, marginTop: '36px' }}>
                      COMMENTS
                    </h3>
                    : (<>
                      {state.public === true
                        ?
                        <>
                          <Box sx={{ borderBottom: 1, borderColor: 'rgba(255,255,255,0.5)' }}>
                            <DumplingTabs value={value} variant="fullWidth" onChange={handleChange} centered aria-label="basic tabs example">
                              <DumplingTab sx={dumplingTab} disableRipple label="Comments" {...a11yProps(0)} />
                            </DumplingTabs>
                          </Box>
                          <TabPanel style={{ minHeight: '81vh' }} value={value} index={0}>
                            <h3 style={{ textAlign:'center', fontSize: '15px', fontFamily: 'Montserrat-Regular', fontWeight: 600 }}>
                              Coming Soon
                            </h3>
                          </TabPanel>
                        </>
                        : (<>
                          {myDumpling.public_desc && (<p style={{ fontSize: 14, fontFamily: 'Montserrat-Regular', marginTop: 36, marginBottom: 36 }}>{myDumpling.public_desc}</p>)}
                          <div style={{ marginBottom: '185px' }}>
                            <h3 style={{ fontSize: '12px', fontFamily: 'Montserrat-Regular', fontWeight: 600, marginTop: '36px' }}>
                              {myDumpling.currency_set?.length === 0 ? 'NO ASSETS FOUND' : 'ASSETS'}
                            </h3>
                            <CurrencyCard currencies={myDumpling.currency_set} walletInfo={myDumpling.wallet_info} quantities={state.quantities} values={state.values} />
                          </div>
                        </>)
                      }
                    </>)
                  }</>)
              }

            </Container>
          </Container>
        </Box>
        {state.shared &&
          < ManageSharedDumpling state={state} openDrawer={drawerOpen} toggleDrawer={openDrawer} />
        }

        {!state.shared && !state.followed && !state.public &&
          < ManageDumpling state={state} openDrawer={drawerOpen} toggleDrawer={openDrawer} />
        }

        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>

            <div style={{ display: "flex" }}>

              <h1 style={{ marginBottom: "0px" }}>{`Dumpling Deleted! `}</h1>

            </div>
            <h3> You have deleted {myDumpling.name}
            </h3>

            <Button href='/dashboard' color="primary" style={{ marginBottom: "10px", width: "100%", borderRadius: "20px", color: "#00578E", borderColor: "00578E" }} >Go to Dashboard</Button>
          </Box>
        </Modal>
        {!state.shared && !state.followed &&
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: '#171E37', position: 'fixed', top: 'auto', bottom: 96, borderTop: '1px solid rgba(255,255,255,0.25)' }}>
            <Button sx={{ '&:disabled': { color: 'white', opacity: 0.2 } }} disabled={myDumpling.currency_set?.length === 0} style={{ border: '2px solid #3CA1EB', borderRadius: 30, backgroundColor: 'transparent', color: 'white', boxShadow: 'none', minWidth: '175px', marginTop: '16px', marginBottom: '16px', textTransform: 'none' }} onClick={() => { navigate('/transact') }}>
              <p style={{ fontSize: '16px', fontFamily: 'Montserrat-Regular', fontWeight: 600, margin: '0px' }}>Buy</p>
            </Button>
            <Button sx={{ '&:disabled': { color: 'white', opacity: 0.2 } }} disabled={myDumpling.currency_set?.length === 0} style={{ marginLeft: "10px", borderRadius: 30, border: '2px solid #3CA1EB', backgroundColor: 'transparent', color: 'white', boxShadow: 'none', minWidth: '175px', marginTop: '16px', marginBottom: '16px', textTransform: 'none' }} onClick={() => { navigate('/transact') }}>
              <p style={{ fontSize: '16px', fontFamily: 'Montserrat-Regular', fontWeight: 600, margin: '0px' }}>Swap</p>
            </Button>
          </div>
        }
      </div>
      : <div></div>}
  </>);
}

export default SingleDumpling;
