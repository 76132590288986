import {createTheme,ThemeProvider} from '@mui/material/styles';
import {Auth} from "aws-amplify";
import PropTypes from 'prop-types';
import {useEffect,useState} from "react";
import {
  MemoryRouter,
  Route,
  Routes,useLocation,useNavigate
} from 'react-router-dom';
import {StaticRouter} from 'react-router-dom/server';
import BottomNav from "./Components/BottomNav";
import Home from './Home';
import AddWallet from "./Screens/AddWallet";
import CodeVerify from './Screens/CodeVerify.js';
import Connect from './Screens/Connect.js';
import CreateDumpling from './Screens/CreateDumpling.js';
import Dashboard from './Screens/Dashboard';
import DataUsage from './Screens/DataUsage';
import ForgotPassword from './Screens/ForgotPassword';
import ManageAssets from './Screens/ManageAssets';
import ManageDumpling from './Screens/ManageDumpling.js';
import ManageSharing from "./Screens/ManageSharing";
import ProtectedRoute from "./Screens/ProtectedRoute";
import PublicDumplings from './Screens/PublicDumplings.js';
import Settings from './Screens/Settings';
import SignUp from './Screens/SignUp';
import SingleDumpling from './Screens/SingleDumpling.js';
import SinglePublicDumpling from './Screens/SinglePublicDumpling.js';
import TandC from './Screens/TandC';
import Transact from './Screens/Transact';
import TransactSource from './Screens/TransactSource';
import TransakWidget from './Screens/TransakWidget.js';
import UniswapWidget from './Screens/UniswapWidget';
import useGATracker from './useGATracker';
import SharedWith from './Screens/SharedWith.js';
import Following from './Screens/ImFollowing.js';


function Router(props) {
  const { children } = props;
  if (typeof window === 'undefined') {
    return <StaticRouter location="/drafts">{children}</StaticRouter>;
  }

  return (
    <MemoryRouter initialEntries={['/drafts']} initialIndex={0}>
      {children}
    </MemoryRouter>
  );
}

Router.propTypes = {
  children: PropTypes.node,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#232933',
      light: '#313B47'
    },
  }
});

function App() {
  useGATracker();

  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const [ authInfo, setAuthInfo ] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    console.log(location.pathname);
    const checkAuth = async () => { 
      console.log('checking session...')
      await Auth.currentSession()
        .then(res => {
          res && setIsAuthenticated(true);
        })
        .catch(err => {
          setIsAuthenticated(false);
          if(window.localStorage.getItem('idToken')) {
            window.localStorage.removeItem('idToken');
            window.location.href = '/login';
          }
        });
      console.log('the session has been set...', isAuthenticated);
    }

    checkAuth();
  }, [isAuthenticated]);
  
  return (
      <ThemeProvider theme={theme}>
        <div className="App" style={{ width: '100%', height: '100vh', backgroundColor: '#171E37' }}>
          <Routes>
            <Route path="/" element={<Home isAuthenticated={isAuthenticated}/>} />
            <Route path="/login" element={<SignUp />} />
            <Route path="/data-usage" element={<DataUsage />}/>
            <Route path="/terms-and-conditions" element={<TandC />} />
            <Route path="/verifyUser" element={<CodeVerify/>}/>
            <Route path="/forgot-password" element={<ForgotPassword />}/>
            <Route element={<ProtectedRoute isAuthenticated={isAuthenticated}/>}>
              <Route path="/actions" element={<UniswapWidget />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/following" element={<Following />} />
              {/* <Route path="/gemini" element={<Gemini />} /> */}
              <Route path="/account" element={<Settings />} />
              <Route path="/connections" element={<Connect />} />
              <Route path="/singleDumpling" element={<SingleDumpling />} />
              <Route path="/singlePublicDumpling" element={<SinglePublicDumpling />} />
              <Route path="/connect" element={<Connect/>} />
              <Route path="/create" element={<CreateDumpling />} />
              <Route path="/manageDumpling" element={<ManageDumpling />} />
              <Route path="/manageSharing" element={<ManageSharing />} />
              <Route path="/sharedWith" element={<SharedWith />} />
              <Route path="/manageAssets" element={<ManageAssets />} />
              <Route path="/callback" element={<CreateDumpling />}/>
              <Route path="/addWallet" element={<AddWallet />}/>
              <Route path="/transact/buy" element={<TransakWidget/>}/>          
              <Route path="/transact/swap" element={<UniswapWidget/>}/>
              <Route path="/transact" element={<Transact/>}/>
              <Route path="/transactSource" element={<TransactSource/>}/>
              <Route path="/publicDumplings" element={<PublicDumplings />} />
            </Route>
          </Routes>
          <BottomNav />
        </div>
      </ThemeProvider>

  )
}

export default App