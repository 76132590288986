import {styled,Table} from "@material-ui/core";
import {TableBody,TableCell,TableHead,TableRow} from "@mui/material";

const StyledTableRowHead = styled(TableRow)(({ theme }) => ({
  border: 0,
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(255,255,255,0.1)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(255,255,255,0.1)',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(255,255,255,0.05)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  }
}));

const StyledTableCellHead = styled(TableCell) (({ theme }) => ({
  color: '#FFFFFF !important',
  fontSize: '14px',
  fontFamily: 'Montserrat-Regular',
  fontWeight: 600,
  padding: '10px'
}));

const StyledTableCell = styled(TableCell) (({ theme }) => ({
  color: '#FFFFFF !important',
  fontSize: '12px',
  fontFamily: 'Montserrat-Regular',
  padding: '10px',
  borderBottom: 'none'
}));

export const aggregateCurrencies = (currencies, wallets) => {
  //create intial aggregate object
  const aggregate = {};
  currencies.forEach(type => {
    aggregate[type] = {
      name: type,
      legalName: '',
      balance: 0,
      value: 0,
      formerValue: 0,
      percentage: 0
    }
  });

  //create currencies list
  let combinedCurrencies = [];
  wallets.forEach(wallet => {
    combinedCurrencies = combinedCurrencies.concat(wallet.currencies)
  });

  //populate values and get total value
  let totalValue = 0;
  combinedCurrencies.forEach(currency => {
    if(currency.selected) {
      totalValue += parseFloat(currency.usd_amount);
      aggregate[currency.name].legalName = currency.legalName;
      aggregate[currency.name].balance += parseFloat(currency.balance);
      aggregate[currency.name].value += parseFloat(currency.usd_amount);
      aggregate[currency.name].formerValue += parseFloat(currency.old_usd_amount);
      aggregate[currency.name].percentage += parseFloat(currency.percentage);
    } else {
      delete aggregate[currency.name];
    }
  });

  return { aggregate, totalValue };
}

const createTable = (currencies, wallets, colorMap, quantities, values) => {
  //create intial aggregate object
  const { aggregate, totalValue } = aggregateCurrencies(currencies, wallets);
  const getColor = (name, colorMap) => {
    let color = ""
    colorMap.forEach(entry => {
      if(name === entry.name){
        color = entry.color;
      }
    });
    return color;
  }

  //return the map of Table Rows!
  return Object.keys(aggregate).map(currency => {
    const color = getColor(currency, colorMap);
    const cellNameStyle = {
      borderLeft: `4px solid ${color}`,
      borderBottom: 'none'
    }
    const percent = values ? aggregate[currency].value/totalValue * 100 : aggregate[currency].percentage;

    return (
      <StyledTableRow>
        <StyledTableCell style={cellNameStyle} align="left">{aggregate[currency].name}</StyledTableCell>
        {quantities && <StyledTableCell style={{ borderBottom: 'none', padding: '10px' }} align="right">{parseFloat(aggregate[currency].balance).toFixed(3)}</StyledTableCell>}
        {values && <StyledTableCell style={{ borderBottom: 'none', padding: '10px' }} align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(aggregate[currency].value)}</StyledTableCell>}
        <StyledTableCell style={{ borderBottom: 'none', padding: '10px' }} align="right">{percent.toFixed(2)}%</StyledTableCell>
      </StyledTableRow>
    )
  });
}

const AssetList = ({ currencies, walletInfo, colorMap, quantities, values }) => {
  return (
    <div style={{ marginTop: '47px' }}>
      <Table sx={{ maxWidth: 400 }}>
        <TableHead>
          <StyledTableRowHead>
            <StyledTableCellHead align="left">Asset</StyledTableCellHead>
            {quantities && <StyledTableCellHead align="right">Quantity</StyledTableCellHead>}
            {values && <StyledTableCellHead align="right">Value</StyledTableCellHead>}
            <StyledTableCellHead align="right">%</StyledTableCellHead>
          </StyledTableRowHead>
        </TableHead>
        <TableBody>
          {createTable(currencies, walletInfo, colorMap, quantities, values)}
        </TableBody>
      </Table>
    </div>
  )
}

export default AssetList;