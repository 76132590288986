// import { styled } from '@mui/material/styles';
import {Button,Fab,styled} from '@material-ui/core';
import {ChevronLeft} from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Skeleton} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {Auth} from 'aws-amplify';
import React,{useEffect,useState} from "react";
import {useLocation,useNavigate} from "react-router-dom";
import Switch from "react-switch";
import {env} from '../environment';
import CoinbaseLogo from '../images/Coinbase.svg';
import MetamaskLogo from '../images/MetamaskLogo.svg';
import WalletConnectLogo from '../images/WalletConnectLogo.svg';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
    },
  },
  '& .MuiSwitch-switchBase': {
    paddingLeft: "10px",
    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    marginTop: "10px",
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: "#3B4055"
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    marginLeft: "-20px",
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


const WalletImg = styled('img')(({ theme }) => ({
  borderRadius: '3px',
  height: '35px',
  width: '140px',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '140px',
  }
}));

const fabstyle = {
  color: "white",
  fontFamily: 'Montserrat-Regular',
  fontSize: 16,
  textTransform: 'none',
  fontWeight: 700,
  marginTop: '16px',
  marginBottom: '16px',
  backgroundColor: '#0887E2',
  borderRadius: 30,
  padding: "5px",
  height: '44px',
  minWidth: '358px',
};

const CBImg = styled('img')(({ theme }) => ({
  borderRadius: '3px',
  height: '35px',
  width: '90px',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '90px',
  }
}));

const currencyStyle = {
    fontFamily: "Montserrat-Regular",
    fontSize: "14px",
    marginLeft: "60px",
    color: "white",
    whiteSpace: "nowrap",
    float: "right"
};

const currencyStyleUSD  = {
  fontFamily: "Montserrat-Regular",
  fontSize: "18px",
  marginLeft: "30px",
  color: "white",
  whiteSpace: "nowrap",
  float: "right",
  textAlign: "right"
};

const currencyStyleBig = {
  fontFamily: "Montserrat-Regular",
  fontSize: "16px",
  color: "white",
  whiteSpace: ''
};

const currencyStyleHeader = {
  fontFamily: "Montserrat-Regular",
  fontSize: "12px",
  color: "white",
  whiteSpace: "nowrap",
  float: "right"
};

const providerTitle = {
  paddingBottom: '0px',
  marginBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 600,
  color: "white",
  whiteSpace: "nowrap",
  marginTop: "10px",
  marginLeft: 12
}

const ProviderTitle = styled('h3')(({ theme }) => ({
  ...providerTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '20px'
  }
}));

const currencyheader = {
  marginLeft: "60px",
  fontFamily: "Montserrat-Regular",
  fontSize: "12px",
  color: "white",
  whiteSpace: "nowrap",
};


const dumplingTitle = {
  padding: "6px",
  paddingBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 200,
  marginBottom: "8px",
  marginTop: '20px',
  color: "white",
  whiteSpace: "nowrap"
}

const DumplingTitle = styled('h3')(({ theme }) => ({
  ...dumplingTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '24px'
  }
}));

const dumplingSubTitle = {
  marginTop: "2px",
  paddingLeft: "8px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "20px",
  color: "white",
}

const WalletConnectButton = styled(Button)(({ theme }) => ({
  borderRadius: 10,
  fontSize: '14px',
  width: "170px",
  height: "18px",
  marginBottom: "20px",
  textTransform: "none",
  fontFamily: "Montserrat-Regular",
  color: "#3CA1EB",
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    width: "170px",
    height: "18px",
    marginBottom: "20px",
    textTransform: "none",
    marginTop: "10px"
  }
}));

const DumplingSubTitle = styled('h3')(({ theme }) => ({
  ...dumplingSubTitle
}));

function getProvImage(provider) {
  const logo = (provider === "Metamask") ? MetamaskLogo : (provider === "walletConnect") ? WalletConnectLogo : (provider === "Coinbase") ? CoinbaseLogo : (provider === "coinbaseExchange") ? CoinbaseLogo : null
  const text = (provider === "Coinbase") ? "Wallet" : (provider === "coinbaseExchange") ? "Exchange" : null
  if (text === "Wallet" || text === "Exchange") {
  return (
    <div style={{display:"flex"}}>
    <CBImg
    src={logo}
    alt=" Logo"
    />
    <div style={{ marginLeft: "6px", marginTop: "8px", fontSize: "16px"}}> {text} </div> 
    </div>
  )
  }
  else {
    return (
      <WalletImg
      src={logo}
      alt=" Logo"
      />
    )
  }
}

function ManageAssets() {
  const [checked, setChecked] = React.useState([]);
  const [allOptions, setAllOptions] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);


    const { state } = useLocation();

    const navigate = useNavigate();
    const [authInfo, setAuthInfo] = React.useState();

    const [name, setName] = React.useState('');
    const [walletCurrencies, setWalletCurrencies] = React.useState([]);
    const [allWalletInfo, setAllWalletInfo] = React.useState([]);
    const [ allWallets, setAllWallets ] = React.useState([]);  
    const [ prov, setProv] = React.useState([]);

    const [walletProvider, setWalletProvider] = React.useState("");
    const [walletId, setWalletId] = React.useState("");

  
    const handleToggle = (value) => () => {

      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      newChecked[currentIndex].selected = !newChecked[currentIndex].selected
      setChecked(newChecked)

    };

    const getDumpling = async () => {
      const auth = await Auth.currentSession();
      setAuthInfo({
        idToken: auth.idToken.jwtToken,
        accessToken: auth.accessToken.jwtToken,
        username: auth.idToken.payload.email
      });
      const options = {
        headers: {
          "Authorization": auth.idToken.jwtToken,
          "accessTokenStr": auth.accessToken.jwtToken
        }
      }

      await fetch(`${env.server}/get_dumpling?userId=${auth.idToken.payload.email}&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
        setAllWalletInfo(json.wallet_info)      
        setWalletProvider(json.wallet_info[0].provider)
        setWalletId(json.wallet_info[0].address)
  
        let selectedWallets = []
  
            json.wallet_info.map((value) => {
                value.currencies.map((curr) => {
                    selectedWallets.push(curr)
              })
            })
  
            setChecked(selectedWallets)
            setIsLoading(false);
      }).catch(err => setIsLoading(false));
    }

    async function addDumplingContents(){

      var myHeaders = new Headers();
      // const dumplingId = json
      myHeaders.append("Content-Type", "application/json");
  
  
      var raw = JSON.stringify([{
        "dumplingId": state.dumpling_id,
        "walletId": walletId,
        "provider": walletProvider,
        "currencies": checked,
        "userId": authInfo.username
      }]);
     
      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": authInfo.idToken,
          "accessTokenStr": authInfo.accessToken
        },
        body: raw,
        redirect: 'follow'
      };
  
      await fetch(`${env.server}/add_wallet`, requestOptions)
      .then(res => res.json()).then(json => {
        navigate('/singleDumpling',
        {
            state: {
              isRefreshed: true,
              dumpling_id: state.dumpling_id,
            }
        });
      }).catch(err => {
        console.log('error', err);
      })
  
    }
  

  const sendData = () => {
    addDumplingContents()
  }

  async function getAllWalletsInDumpling(){
    const options = {
      headers: {
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      }
    }

    await fetch(`${env.server}/get_user_deduped_wallets?userId=${authInfo.username}`, options).then(res => res.json()).then(json => {
      
    let walletAddresses = []
    let walletProvidersMap = {}

    json.map((value) => (
      walletAddresses.push(value.address),
      walletProvidersMap[value.address] = value.provider
    ))

      setProv(walletProvidersMap)

      // getWalletBalances(walletAddresses)
  
    });
  }

  async function getAllConnectedWallets(){
    const options = {
      headers: {
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      }
    }

    await fetch(`${env.server}/get_user_deduped_wallets?userId=${authInfo.username}`, options).then(res => res.json()).then(json => {
  
    let walletAddresses = []
    let walletProvidersMap = {}

    json.map((value) => (
      walletAddresses.push(value.address),
      walletProvidersMap[value.address] = value.provider
    ))

      setProv(walletProvidersMap)
    });
  }

  useEffect(() => {
    getDumpling();
    getAllWalletsInDumpling();
    getAllConnectedWallets();
    setIsLoading(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps



    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center' }}>
        {/* <h1 style={title}>Create A Dumpling</h1> */}

        <div style={{ width: '100%' }}>
          <ChevronLeft onClick={() => navigate(-1)} style={{ float: 'left', position: 'relative', top: 47, left: 16, color: '#FFFFFF' }}/>
          <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '19px' }}>MY DUMPLINGS</h1>
          <hr style={{ width: '100%', borderColor: 'rgba(255,255,255,0.2)' }}/>
        </div>
        <div>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: "center", 
          justifyContent: "center",
          color: "white",
          width: 358
          }}>

                <Box direction="column" alignItems="left" justifyContent="left">
                  <DumplingTitle> Add or remove assets </DumplingTitle>
                  <DumplingSubTitle>Select the assets you would like to put in your dumpling.</DumplingSubTitle>
                </Box>


              { isLoading
                ? (
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                    <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px', marginBottom: '16px' }} variant="rectangular" width={360} height={300} />
                  </div>
                ) : (
                <div>
              {allWalletInfo.map((wallet, index) => {

                   return (
                <Card style={{backgroundColor: "#252B43", marginTop: "10px", marginBottom: "10px", color: "white", borderRadius: "10px", width: '358px'}}>
                  {/* <DumplingTitle>{walletMap[wallet.provider] || wallet.provider}</DumplingTitle> */}

                  <ProviderTitle> {getProvImage(wallet.provider)} </ProviderTitle>

                <List sx={{ width: '100%',  color: "white", backgroundColor: "#252B43"}}>

                <ListItem key={"header"} role={undefined} dense>
                     
                      <ListItemText
                        primary={
                        <React.Fragment>
                            <Typography
                              sx={currencyheader}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                            Available Assets
                            </Typography>
                          </React.Fragment>}

                        />
                            <ListItemText
                        primary={
                        <React.Fragment>
                            <Typography
                              sx={currencyStyleHeader}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                            Amount
                            </Typography>
                          </React.Fragment>}

                        />
                   </ListItem>

                  {wallet.currencies && wallet.currencies.length > 0
                    ? wallet.currencies.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.name}`;
                    const totalCrypto = (Number(value.balance)).toFixed(4)
                    const totalUSD = (Number(value.usd_amount)).toFixed(2) 

                    
                    return (
                      <ListItem
                        key={labelId}
                        sx={{backgroundColor: (index % 2 === 0) ? "#3B4055" : "#252B43", width: "auto"}}
                        disablePadding
                      >
                        <ListItem role={undefined} dense>
                      <Switch onChange={handleToggle(value)} width={36} height={20} handleDiameter={16} onColor="#0887E2" onHandleColor="#3B4055" offHandleColor="#3B4055" uncheckedIcon={false} checkedIcon={false} checked={value["selected"]} />

                      <ListItemText
                        style={{ 
                          textAlign: 'left',
                          whiteSpace: 'normal',
                          marginLeft: 20
                        }}
                        primary={
                        <React.Fragment>
                            <Typography
                              sx={currencyStyleBig}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                            {value.legalName}
                            </Typography>
                          </React.Fragment>}

                        />

                      <div style={{display: "grid"}}>
                        <ListItemText
                        primary={
                          <React.Fragment>
                              <Typography
                                sx={currencyStyleUSD}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                            {`$ ${totalUSD}`}
                              </Typography>
                            </React.Fragment>}

                        secondary={
                          <React.Fragment>
                            <Typography
                                sx={currencyStyle} 
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                            {`${totalCrypto} ${value.name}`}
                            </Typography>
                          </React.Fragment>
                        }
                        />
                        </div>
                        </ListItem>
                      </ListItem>
                    );
                  })
                  : (
                    <ListItem
                        sx={{backgroundColor: (index % 2 === 0) ? "#3B4055" : "#252B43", width: "auto"}}
                        disablePadding
                      >
                        <ListItem role={undefined} dense>
                      <Switch disabled width={36} height={20} handleDiameter={16} onColor="#0887E2" onHandleColor="#3B4055" offHandleColor="#3B4055" uncheckedIcon={false} checkedIcon={false} checked={false} />

                      <ListItemText
                        style={{ 
                          textAlign: 'left',
                          whiteSpace: 'normal',
                          marginLeft: 20
                        }}
                        primary={
                        <React.Fragment>
                            <Typography
                              sx={currencyStyleBig}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                            No assets found
                            </Typography>
                          </React.Fragment>}

                        />

                      <div style={{display: "grid"}}>
                        <ListItemText
                        primary={
                          <React.Fragment>
                              <Typography
                                sx={currencyStyleUSD}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                            -
                              </Typography>
                            </React.Fragment>}
                        />
                        </div>
                        </ListItem>
                      </ListItem>
                  )}
                </List>

                </Card>

                );
                })} 
                </div>
                  )
                }

                <div style={{textAlign: "left", width: "100%", marginBottom: '170px'}}>
                <WalletConnectButton  startIcon={<AddCircleIcon />}  sx={{color: "#3CA1EB", marginTop: "16px", marginRight: "16px"}} variant="text" disableElevation      
                onClick={() => {
                    navigate('/addWallet', 
                    {
                        state: {
                          dumpling_id: state.dumpling_id
                        }
                    })
                    
                    }} >
                  Add account
                </WalletConnectButton>
                </div>
      


                {/* <Button mx='auto' variant="contained" color="primary" style={{ marginBottom: "10px", width: "250px", borderRadius: "20px", fontFamily: "Montserrat-Regular", }} onClick={() => { sendData() }} >Continue</Button> */}

                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: '#171E37', position: 'fixed', top: 'auto', bottom: 96, borderTop: '1px solid rgba(255,255,255,0.25)' }}>
                  <Fab onClick={() => sendData()} style={fabstyle} variant="extended" aria-label="add">
                    <p>View my dumpling</p>
                  </Fab>
                </div>   
        </Box>
        

        
      </div>
      </div>
    );
}
  
export default ManageAssets;