import React, { createContext, useReducer } from 'react';
import { reducer, initialState } from "./reducer"

// export const UserContext = React.createContext({
//   state: initialState,
//   dispatch: () => null
// })

// export const UserProvider = ({ children }) => {
// //   const [state, dispatch] = React.useReducer(reducer, initialState)

//   const [state, dispatch] = React.useReducer(
//     (state, newValue) => ({ ...state, ...newValue }),
//     {dumplingList: []}
//   );

//   return (
//     <UserContext.Provider value={[ state, dispatch ]}>
//     	{ children }
//     </UserContext.Provider>
//   )
// }

// import React, { createContext, useReducer } from 'react';
// import AppReducer from './AppReducer';

// const initialState = {
//    dumplingList : []
// }

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
   const [state, dispatch] = useReducer(reducer, initialState);

   // Actions for changing state

   function addItemToList(item) {
       console.log("item", item)
       dispatch({
           type: 'ADD_ITEM',
           payload: item
       });
   }
   function removeItemFromList(item) {
       dispatch({
           type: 'REMOVE_ITEM',
           payload: item
       });
   }

   return(
      <GlobalContext.Provider value = {{dumplingList : state.dumplingList, addItemToList, removeItemFromList}}> 
        {children} 
   </GlobalContext.Provider>
   )
}


// const defaultGlobalState = {
//   num: 0,
//   text: "foo",
//   bool: false
// };
// const globalStateContext = React.createContext(defaultGlobalState);
// const dispatchStateContext = React.createContext(undefined);

// const GlobalStateProvider = ({ children }) => {
//   const [state, dispatch] = React.useReducer(
//     (state, newValue) => ({ ...state, ...newValue }),
//     defaultGlobalState
//   );
//   return (
//     <globalStateContext.Provider value={state}>
//       <dispatchStateContext.Provider value={dispatch}>
//         {children}
//       </dispatchStateContext.Provider>
//     </globalStateContext.Provider>
//   );
// };
