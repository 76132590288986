// import "./styles.css";
import {Close} from '@material-ui/icons';
import {Link} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import {Auth} from 'aws-amplify';
import {AmplifyAuthKeys} from '../Components/AmplifyAuthKeys'

import {Component} from "react";
import {
  useNavigate
} from 'react-router-dom';
import Button from "../Components/Button";

/*
Amplify.configure({
    Auth: {
    userPoolId: 'us-east-1_l8TVD6ZwX', //UserPool ID
    region: 'us-east-1',
    userPoolWebClientId: '74abi9q7rroa1ua9km9mk0ah21' //WebClientId
    }
});
*/

AmplifyAuthKeys();

class Login extends Component {

  constructor() {
    super();
    this.submitLogin = this.submitLogin.bind(this);
  }
    
  state = {
    email: "",
    password: "",
    isAuthenticated: false,
    jwtToken: "",
    pageIndex: 0,
    errorMessages: [],
    errorModalOpen: false, 
  };

  handleChange = e => {
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });
  };

  handleForgotPassword = () => {
    this.props.navigate('/forgot-password', { state: { username: this.state.email } });
  }

  getToken = async ()=>{
    var data = await Auth.currentSession()
    localStorage.setItem('idToken', data.idToken.jwtToken);
    localStorage.setItem('username', data.idToken.payload.email);
//     console.log("data", data);
//     console.log("idToken", data.idToken.jwtToken);
    if (data.idToken !== null) {
        this.setState({ jwtToken: data.idToken.jwtToken, isAuthenticated: true });
    }
    return data.idToken.jwtToken
  }
// Now get JWT just by calling this function.

  submitLogin = async (e) => {
    await Auth.signIn(this.state.email, this.state.password).then((result) => {
        this.getToken();
        console.log('Signing in and redirecting...');
        this.props.navigate('/publicDumplings', { replace: true });
       }).catch((err) => {
        // Something is Wrong
        console.log("error", err) 
        this.setState({ errorMessages: err.message });
        this.handleErrorOpen()
       })
  }

  handleErrorOpen = () => {
    this.setState({ errorModalOpen: true });
  };

  handleErrorClose = () => {
    this.setState({ errorModalOpen: false });
  };

  render() {
    const formStyle = {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'flex-start',
      marginTop: 25
    }

    const inputContainer = {
      display: 'flex',
      width: '100%',
      marginTop: '15px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: 'white',
      fontFamily: 'Montserrat-Regular',
      fontSize: '14px'
    }

    const modalStyle = {
      position: 'absolute',
      fontFamily: "Montserrat-Regular",
      top: '50%',
      left: '50%', 
      transform: 'translate(-50%, -50%)',
      minWidth: '300px',
      bgcolor: 'background.paper',
      borderRadius: 5,
      borderColor: "white",
      boxShadow: 24,
      p: 4,
      backgroundColor: "#3F4B5C", 
      color: "white"
    };


    const textField = {
      width: '92%',
      height: '44px',
      paddingLeft: '12px',
      paddingRight: '12px',
      borderRadius: 10,
      border: '1px solid white',
      backgroundColor: 'transparent',
      color: 'white',
      fontFamily: 'Montserrat-Regular',
      fontSize: '16px'
    }

    const buttonStyles = {
      width: '100%',
      height: '46px',
      marginTop: '30px',
      marginBottom: '0px',
      borderRadius: 30,
      fontSize: '16px',
      backgroundColor: '#0887E2',
      boxShadow: 'none',
      fontFamily: 'Montserrat-Regular',
      fontWeight: 600,
      textTransform: 'none'
    }

    return (

      <div>
        <Modal
        open={this.state.errorModalOpen}
        onClose={(event, backdropClick) => {this.handleErrorClose()}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
            <div style={{display: "flex", justifyContent: 'space-between'}}>
              <h1 style={{marginBottom: "10px", marginTop: '0px', fontSize: '20px'}}>Sign In Error!</h1>
              <Close onClick={this.handleErrorClose}/>
            </div>
            <div>{this.state.errorMessages}</div>
            <Button style={{ marginTop: '40px', borderRadius: '30px', backgroundColor: '#0887E2', color: 'white', height: '44px', fontSize: '16px' }} variant="contained" onClick={this.handleErrorClose}>
              Ok
            </Button>
          </div>
        </Box>
      </Modal>

      <form className="form" style={formStyle}>
        <label style={inputContainer}>
          <span style={{ marginBottom: '4px' }}>Username</span>
          <input style={textField} id="email" placeholder='email@address.com' type='text' onChange={this.handleChange}/>
        </label>
        <label style={inputContainer}>
          <span style={{ marginBottom: '4px' }}>Password</span>
          <input style={textField} id="password" type='password' placeholder='******' onChange={this.handleChange}/>
        </label>
        <Link style={{ textDecoration: 'none', fontSize: 14, color: '#0887E2', width: '100%', textAlign: 'right', marginTop: '3px' }} onClick={this.handleForgotPassword}>
          Forgot password?
        </Link>
        <Button style={buttonStyles} type="button" className="form__custom-button" onClick={this.submitLogin}>
          Sign in
        </Button>
      </form>
      </div>
    );
  }
}


function LoginForm(props) {
    let navigate = useNavigate();
    return <Login {...props} navigate={navigate} />
}

export default LoginForm
