import {Box,Card} from "@mui/material";
import {Cell,Legend,Pie,PieChart,ResponsiveContainer} from "recharts";
import featDumplingBg from '../Components/svgs/featured-dumpling-graph.svg';
import {determineChange} from './CurrencyCard';
import {getChartData} from "./DashboardDumplingCard";

const currSubtitle = {
  marginTop: "0px",
  marginBottom: '0px',
  fontFamily: "Montserrat-Regular",
  fontSize: "12px",
  fontWeight: 500,
  color: "#FFFFFF",
}

const dumplingTitle = {
  padding: 0,
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "20px",
  fontWeight: 500,
  marginBottom: "0px",
  marginTop: '0px',
  color: "white",
  whiteSpace: "nowrap"
}

const chartdata = [
  { name: "ETH", value: 40 },
  { name: 'BTC', value: 20 },
  { name: 'SOL', value: 5 },
  { name: 'DOGE', value: 15 },
  { name: 'SHIB', value: 5 },
  { name: 'USDT', value: 15 }
];

const COLORS = ['#02EDBA', '#3CA1EB', '#9C4FFF', '#EFD54D', '#FF50F8', '#FF9B00'];

const DataLegendItem = ({ currencyName, percentage, dataColor }) => {
  return(
    <>
      <div style={{ 
        background: 'rgba(255, 255, 255, 0.1)', 
        minWidth: 55,
        display: 'flex',
        flexDirection:  'column', 
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '5px 5px 5px 5px',
        borderLeft: `4px solid ${dataColor}`, 
        marginRight: '5px',
        marginBottom: 5,
        color: 'white',
        fontFamily: 'Montserrat-Regular',
        letterSpacing: '0.1em',
        fontWeight: 600,
        fontSize: 12 }}>
          {currencyName} <span style={{ color: `${dataColor}` }}>{percentage.toFixed(2)}%</span>
      </div>
    </>
  )
}

const FeaturedDumplingCard = ({ dumpling, goToDumpling }) => {
  const cardStyles = { 
    backgroundColor: 'transparent', 
    border: '1px solid rgba(255,255,255,0.2)', 
    backgroundImage: `url(${featDumplingBg})`,
    backgroundRepeat: 'none',
    backgroundSize: 'cover'
  }
  const roundedTotal = 0;
  const total = 0;

  return (
    <Card sx={cardStyles} style={{ padding: "20px", marginTop: "10px", marginBottom: "20px", borderRadius: "10px", boxShadow: 'none' }}>
      <div style={{ maxWidth: '400px' }}>
        <div style={{ display: "flex", width: '100%' }}>
          <div style={{ width: '100%' }}>
            {/* <img src={littleDumplings} width="150px" style={{ float: "left" }} /> */}
            <Box>
              <h3 style={dumplingTitle}>{dumpling.name}</h3>
              <div style={{ marginTop: 5, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '100%' }}>
                <h3 style={currSubtitle}>{dumpling.public_desc}</h3>
                {/* <h3 style={{ color: '#02EDBA', fontSize: '12px' }}>+$27.18 (3.075%)</h3> */}
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '10px' }}>
                <div style={{ width: '40%', height: 120 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={getChartData(dumpling)}
                        innerRadius={41}
                        outerRadius={51}
                        paddingAngle={5}
                        dataKey="value"
                        stroke="none"
                      >
                        {getChartData(dumpling).map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                  {/* <div style={{ color: '#02EDBA', position: 'relative', top: '-68px', left: '48px', fontSize: '14px' }}>
                    +15.078%
                  </div> */}
                </div>
                <div style={{ display: 'flex', width: 160, justifyContent: 'flex-start', flexWrap: 'wrap', marginTop: 5 }}>
                  {getChartData(dumpling).map((entry, index) => {
                    return <DataLegendItem currencyName={entry.name} percentage={entry.value} dataColor={COLORS[index % COLORS.length]} isLastItem={index === 3 && chartdata.length > 4} count={chartdata.length - 4} />
                  })}
                </div>
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <h2 style={{ fontSize: '24px', fontWeight: 400, color: '#FFFFFF', marginBottom: '0px' }}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(dumpling.total_balance) || '$0.00'}</h2>
                <p style={{ color: '#FF5E5E', fontSize: '12px', fontWeight: 400, marginBottom: '0px' }}>{determineChange(dumpling.old_total_balance, dumpling.total_balance)}</p>
              </div>
              
              <Legend content={getChartData(dumpling)} verticalAlign="bottom" layout="horizontal"/>
            </Box>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default FeaturedDumplingCard;