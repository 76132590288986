// import { styled } from '@mui/material/styles';
import {styled} from '@material-ui/core';
import {ChevronLeft} from "@mui/icons-material";
import {Fab} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {Auth} from 'aws-amplify';
import * as React from 'react';
import {useEffect} from 'react';
import {useLocation,useNavigate} from "react-router-dom";
import Switch from "react-switch";
import DumplingTags from '../Components/DumplingTags';
import {env} from '../environment';
import CoinbaseLogo from '../images/Coinbase.svg';
import WalletConnectLogo from '../images/WalletConnectLogo.svg';

const currencyStyle = {
    fontFamily: "Montserrat-Regular",
    fontSize: "14px",
    // marginLeft: "50px",
    color: "white",
    whiteSpace: "nowrap",
    float: "right"
};

const fabstyle = {
  fontFamily: 'Montserrat-Regular',
  fontSize: 16,
  textTransform: 'none',
  fontWeight: 700,
  marginTop: '16px',
  marginBottom: '16px',
  backgroundColor: '#0887E2',
  borderRadius: 30,
  padding: "5px",
  height: '44px',
  minWidth: '358px'
};

const currencyStyleUSD  = {
  fontFamily: "Montserrat-Regular",
  fontSize: "18px",
  marginLeft: "50px",
  color: "white",
  whiteSpace: "nowrap",
  float: "right",
  textAlign: "right"
};

const currencyStyleBig = {
  fontFamily: "Montserrat-Regular",
  fontSize: "18px",
  color: "white",
  whiteSpace: "nowrap",
  marginLeft: "24px"
};

const currencyheader = {
  marginLeft: "60px",
  fontFamily: "Montserrat-Regular",
  fontSize: "12px",
  color: "white",
  whiteSpace: "nowrap",
};

const currencyStyleHeader = {
  fontFamily: "Montserrat-Regular",
  fontSize: "12px",
  marginLeft: "50px",
  color: "white",
  whiteSpace: "nowrap",
  float: "right"
};

const textField = {
  width: '92%',
  height: '44px',
  paddingLeft: '12px',
  paddingRight: '12px',
  borderRadius: 10,
  border: '1px solid white',
  backgroundColor: '#171e37',
  color: 'white',
  fontSize: '16px',
}


const walletNameHeader ={
  fontSize: "18px",
  fontFamily: "Montserrat-Regular",
  color: "white",
  display: "block",
  inlineSize: "min-content",
  textAlign: "left",
  marginTop: "10px",
  marginLeft: "10px"
}

const providerTitle = {
  paddingBottom: '0px',
  marginBottom: '0px',
  marginLeft: '13px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "20px",
  fontWeight: 600,
  color: "white",
  whiteSpace: "nowrap"
}

const WalletImg = styled('img')(({ theme }) => ({
  borderRadius: '3px',
  height: '90px',
  width: '200px',
  [theme.breakpoints.down('sm')]: {
    height: '28px',
    width: '140px'
  }
}));

const ProviderTitle = styled('h3')(({ theme }) => ({
  ...providerTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '20px'
  }
}));

const subheadsection ={
  padding: "10px",
  marginBottom: "20px",
  width: "400px",
  margin: "auto"
}

const dumplingTitle = {
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 400,
  marginBottom: "20px",
  marginTop: '20px',
  color: "white",
  whiteSpace: "nowrap"
}

const DumplingTitle = styled('h3')(({ theme }) => ({
  ...dumplingTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '24px'
  }
}));

const dumplingSubTitle = {
  marginTop: "8px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "20px",
  color: "white",
}

const DumplingSubTitle = styled('h3')(({ theme }) => ({
  ...dumplingSubTitle
}));


function CreateDumpling() {
  
  const [checked, setChecked] = React.useState([]);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [name, setName] = React.useState('');
  const [authInfo, setAuthInfo] = React.useState(null);
  const [walletCurrencies, setWalletCurrencies] = React.useState([]);
  const [walletProvider, setWalletProvider] = React.useState("");
  const [walletId, setWalletId] = React.useState("");
  const [allWallets, setAllWallets] = React.useState([]);
  // const [dumplingId, setDumplingId] = React.useState("");
  const [refreshToken, setRefreshToken] = React.useState("");
  const [isExchange, setIsExchange] = React.useState(false);

  const [defaultTags, setDefaultTags] = React.useState(() => ['Cryptos']);
  const handleTags = (e, newTags) => {
    if(newTags.length){
      setDefaultTags(newTags);
    }
  }

  const handleToggle = (value) => () => {
  const currentIndex = checked.indexOf(value);
  const newChecked = [...checked];
  
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    value.selected = !value.selected;
  };

    const getAuthUser = async () => {
      await Auth.currentSession()
        .then(res =>
          setAuthInfo({
            idToken: res.idToken.jwtToken,
            accessToken: res.accessToken.jwtToken,
            username: res.idToken.payload.email
          })
        )
        .catch(err => console.log('could not get user...'));
    }

    const handleSelectAsset = (value, address) => {
      const currentIndex = checked.findIndex(x => x.walletId === address && x.currency === value.name);
      const newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push({
          walletId: address,
          currency: value.name,
          selected: value.selected
        });
      } else {
        newChecked.splice(currentIndex, 1);
      }
      console.log('This is the final list of checked: ', newChecked);
      setChecked(newChecked);
      value.selected = !value.selected;
    }

    const isChecked = (value, address) => {
      return checked.findIndex(x => x.walletId === address && x.currency === value.name);
    }

  async function makeDumpling() {
    const options = {
      headers: {
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      }
    }

    await fetch(`${env.server}/create_dumpling?name=${name}&userId=${authInfo.username}&dumplingType=private`, options).then(res => res.json()).then(json => {

      addDumplingContents(json.dumplingId, authInfo.username)
      if (isExchange) {
        addCoinbaseToken(json.dumplingId, authInfo.username)
      }
    }).catch(err => {
      console.log('error', err);
    })

  }

  function getProvImage(provider) {
    const logo = (provider === "walletConnect") ? WalletConnectLogo : (provider === "Coinbase" || "coinbaseExchange") ? CoinbaseLogo : null
    return (
      <WalletImg
      src={logo}
      alt=" Logo"
      />
    )
  }


  async function addDumplingContents(json, username){

    var myHeaders = new Headers();
    const dumplingId = json
    myHeaders.append("Content-Type", "application/json");

    let raw;
    if(state && state.existing) {
      const formatWallets = [];
      allWallets.forEach(wallet => {
        console.log('CURRENCIES EITHER FALSE OR TRUE: ', wallet.currencies);
        formatWallets.push({
          "dumplingId": dumplingId,
          "walletId": wallet.address,
          "provider": wallet.provider,
          "currencies": wallet.currencies,
          "userId": username
        })
      });
      raw = JSON.stringify(formatWallets);
      console.log(formatWallets);
    }
    else {
      raw = JSON.stringify([{
        "dumplingId": dumplingId,
        "walletId": walletId,
        "provider": walletProvider,
        "currencies": walletCurrencies,
        "userId": username
      }]);
    }
   
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      },
      body: raw,
      redirect: 'follow'
    };

    const tagOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      },
      body: JSON.stringify({ "dumplingId": dumplingId, "userId": username, "tags": defaultTags }),
      redirect: 'follow'
    }

    await fetch(`${env.server}/add_tags`, tagOptions)
      .catch(err => console.log(err));

    await fetch(`${env.server}/add_wallet`, requestOptions).then(res => res.json()).then(json => {
      navigate('/dashboard')
    }).catch(err => {
      console.log('error', err);
    })

    // const response = await fetch(`${env.server}/add_wallet`, requestOptions).catch(error => console.error(error));

    // navigate('/dashboard')

  }

  const sendData = () => {
   if (state != null && state.dumpling_id !== undefined) {
    addDumplingContents(state.dumpling_id, authInfo.username)
    if (isExchange) {
      addCoinbaseToken(state.dumpling_id, authInfo.username)
    }

   } else {
    makeDumpling()
   }

  }

  const getAllWallets = async () => {
    const auth = await Auth.currentSession();
    const options = {
      headers: {
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      }
    }

    await fetch(`${env.server}/get_user_deduped_wallets?userId=${auth.idToken.payload.email}`, options)
      .then(res => res.json())
      .then(json => {
        const checkedList = [];
        json.forEach(wallet => {
          wallet.currencies.forEach(currency => {
            currency.selected = true;
            currency.selected && checkedList.push({
              walletId: wallet.address,
              currency: currency.name,
              selected: currency.selected
            });
          });
        });
        setChecked(checkedList);
        setAllWallets(json);
      })
  }

  async function getWalletBalances() {
    var myHeaders = new Headers();
  
    myHeaders.append("Content-Type", "application/json");

  
    var raw = JSON.stringify({
       "wallets" : [state.wallet_id]
    }
    );
  const auth = await Auth.currentSession();
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      },
      body: raw,
      redirect: 'follow'
    };

    await fetch(`${env.server}/get_wallet_balance`, requestOptions).then(res => res.json()).then(json => {
        
      setWalletCurrencies(json[0].wallet_info)
      setChecked(json[0].wallet_info)
      return json
  
    }).catch(error => console.log('error', error));
  
  }

  async function getCoinbaseExchangeBalances(code) {
    const auth = await Auth.currentSession();

      const options = {
        headers: {
          "Authorization": auth.idToken.jwtToken,
          "accessTokenStr": auth.accessToken.jwtToken
        }
      }
      await fetch(`${env.server}/coinbase_accounts?code=${code}&userId=${auth.idToken.payload.email}`, options).then(res => res.json()).then(json => {
        setWalletCurrencies(json[0].wallet_info)
        setChecked(json[0].wallet_info)
        setWalletId(json[0].wallet_address)
        setRefreshToken(json[0].refresh_token)      
      });
  }

  async function addCoinbaseToken(dumplingId, username) {
      var myHeaders = new Headers();
    
      myHeaders.append("Content-Type", "application/json");
    
      var raw = JSON.stringify(
        {
        "userId" : username,
        "dumplingId" : dumplingId,
        "refreshToken" : refreshToken
        }
      );
    
      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": authInfo.idToken,
          "accessTokenStr": authInfo.accessToken
        },
        body: raw,
        redirect: 'follow'
      };
    
      await fetch(`${env.server}/add_coinbase_refresh_token`, requestOptions).then(res => res.json()).then(json => {
    
      }).catch(error => console.log('error', error));
    
    }


    function isNewCoinbaseConnect() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      return code
    }

  useEffect(() => {
    getAuthUser();
    if(state && state.existing) {
      getAllWallets();
    }
    else {
      const authCode = isNewCoinbaseConnect()

      if (authCode !== null) {
        getCoinbaseExchangeBalances(authCode)
        setWalletProvider("coinbaseExchange")
        setIsExchange(true)
      }
      
      if (state !== null && authCode === null) {
        if (state.cb_assets.length === 0) {
          getWalletBalances()
          setWalletProvider(state.wallet_provider)
          setWalletId(state.wallet_id)
        } else {
          setWalletProvider("coinbaseExchange")
          setWalletCurrencies(state.cb_assets[0].wallet_info)
          setChecked(state.cb_assets[0].wallet_info)
          setWalletId(state.cb_assets[0].wallet_address)
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Paper
          sx={{
          // bgcolor: (theme) => theme.palette.secondary.main,
          bgcolor: 'transparent',
          textAlign: 'center',
          fontSize: '0.875rem',
          fontWeight: '700',
          boxShadow: "none",
        // width: "600px",
        }}>
        <div>
          <div style={{ width: '100%' }}>
          <ChevronLeft onClick={() => { state && state.existing ? navigate('/dashboard') : navigate(-1)}} style={{ float: 'left', position: 'relative', left: 16, color: '#FFFFFF' }}/>
          <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Create a Dumpling</h1>
          <hr style={{ borderColor: 'rgba(255,255,255,0.2)' }}/>
        </div>
        <div>
        <Box sx={{ 
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: "center", 
          justifyContent: "center",
          color: "white",
          marginBottom: 170}}>
            <Box direction="column" alignItems="left" justifyContent="left" width={358}>
              {(state === null || state.dumpling_id === undefined || !state.dumpling_id) &&
              <div>
                <DumplingTitle> Name your dumpling </DumplingTitle>
                <form>
                  <div >
                      <input
                        variant="outlined"
                        margin="normal"
                        required
                        name="name"
                        label="Dumpling Name"
                        type="text"
                        id="name"
                        autoComplete="name"
                        autoFocus
                        value={name}
                        style = {textField}
                        onChange={(event) => {setName(event.target.value)}} //whenever the text field change, you save the value in state
                      />
                      </div>

                  </form>
                  <DumplingSubTitle>&#42; Required field </DumplingSubTitle>
                  </div> 
                }
                <DumplingTitle style={{ marginTop: 40, marginBottom: 0 }}>Type of Dumpling</DumplingTitle>
                <DumplingSubTitle>Select at least one tag from the options below.</DumplingSubTitle>
                <DumplingTags defaultTags={defaultTags} handleFn={handleTags}/>
                <DumplingTitle style={{ marginTop: 40, marginBottom: 0 }}> Add Assets </DumplingTitle>
                <DumplingSubTitle>Select the assets you would like to put in your dumpling.</DumplingSubTitle>
                </Box>
                  <div style={{ marginBottom: 170 }}>
                  {state && state.existing
                    ? allWallets.map((wallet, index) => (
                      <Card style={{paddingRight: "0px", backgroundColor: "#252B43", marginTop: "10px", marginBottom: "10px", color: "white", borderRadius: "10px", width: 358}}>
                        <ProviderTitle> {getProvImage(wallet.provider)} </ProviderTitle>
                        <List sx={{ width: '100%',  color: "white", backgroundColor: "#252B43", }}>
                          <ListItem role={undefined} dense>
                            <ListItemText
                              primary={
                              <React.Fragment>
                                  <Typography
                                    sx={currencyheader}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                  Available Assets
                                  </Typography>
                                </React.Fragment>}/>
                            <ListItemText
                              primary={
                              <React.Fragment>
                                  <Typography
                                    sx={currencyStyleHeader}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                  Amount
                                  </Typography>
                                </React.Fragment>}/>
                          </ListItem>
                          {wallet.currencies.length > 0
                            ? wallet.currencies.map((value, index) => {
                              const totalCrypto = (Number(value.balance)).toFixed(4)
                              const totalUSD =  (Number(value.usd_amount)).toFixed(2)

                              return (
                                <ListItem
                                  key={value.name}
                                  sx={{backgroundColor: (index % 2 === 0) ? "#3B4055" : "#252B43", width: "auto", maginRight: "10px"}}
                                  disablePadding
                                  >
                                  <ListItem role={undefined} dense>
                                    <Switch onChange={() => { handleSelectAsset(value, wallet.address) }} width={36} height={20} handleDiameter={16} onColor="#0887E2" onHandleColor="#3B4055" offHandleColor="#3B4055" uncheckedIcon={false} checkedIcon={false} checked={isChecked(value, wallet.address) !== -1}/>

                                <ListItemText
                                  sx={currencyStyle}
                                  primary={
                                  <React.Fragment>
                                      <Typography
                                        sx={currencyStyleBig}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                      {value.legalName}
                                      </Typography>
                                    </React.Fragment>}

                                  />

                              <div style={{display: "grid"}}>
                                  <ListItemText
                                  primary={
                                    <React.Fragment>
                                        <Typography
                                          sx={currencyStyleUSD}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                        >
                                      {`$ ${totalUSD}`}
                                        </Typography>
                                      </React.Fragment>}

                                  secondary={
                                    <React.Fragment>
                                      <Typography
                                          sx={currencyStyle} 
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                      {`${totalCrypto} ${value.name}`}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                  />
                                </div>

                  
                                  </ListItem>
                                </ListItem>
                              );
                            })
                            : <ListItem
                            sx={{backgroundColor: "#3B4055", width: "auto", maginRight: "10px"}}
                            // secondaryAction={
                            //   <IconButton edge="end" aria-label="comments">
                            //     <CommentIcon />
                            //   </IconButton>
                            // }
                            disablePadding
                          >
                            <ListItem role={undefined} dense>
                        <Switch disabled width={36} height={20} handleDiameter={16} onColor="#0887E2" onHandleColor="#3B4055" offHandleColor="#3B4055" uncheckedIcon={false} checkedIcon={false} checked={false}/>

                          <ListItemText
                            sx={currencyStyle}
                            primary={
                            <React.Fragment>
                                <Typography
                                  sx={currencyStyleBig}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                No assets found
                                </Typography>
                              </React.Fragment>}

                            />

                        <div style={{display: "grid"}}>
                            <ListItemText
                            primary={
                              <React.Fragment>
                                  <Typography
                                    sx={currencyStyleUSD}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                $N/A
                                  </Typography>
                                </React.Fragment>}
                            />
                          </div>
                            </ListItem>
                          </ListItem>}
                          </List>
                      </Card>
                    ))
                    : (
                      <Card style={{paddingRight: "0px", backgroundColor: "#252B43", marginTop: "10px", marginBottom: "170px", color: "white", borderRadius: "10px", width: 358}}>
                        <ProviderTitle> {getProvImage(walletProvider)} </ProviderTitle>
                        <List sx={{ width: '100%',  color: "white", backgroundColor: "#252B43", }}>    
                        <ListItem role={undefined} dense>
                              <ListItemText
                                primary={
                                <React.Fragment>
                                    <Typography
                                      sx={currencyheader}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                    Available Assets
                                    </Typography>
                                  </React.Fragment>}

                                />
                                <ListItemText
                                primary={
                                <React.Fragment>
                                    <Typography
                                      sx={currencyStyleHeader}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                    Amount
                                    </Typography>
                                  </React.Fragment>}

                                />
                          </ListItem>

                          {walletCurrencies.length > 0 
                            ? walletCurrencies.map((value, index) => {
                            const totalCrypto = (Number(value.balance)).toFixed(4)
                            const totalUSD =  (Number(value.usd_amount)).toFixed(2)

                            return (
                              <ListItem
                                key={value.name}
                                sx={{backgroundColor: (index % 2 === 0) ? "#3B4055" : "#252B43", width: "auto", maginRight: "10px"}}
                                // secondaryAction={
                                //   <IconButton edge="end" aria-label="comments">
                                //     <CommentIcon />
                                //   </IconButton>
                                // }
                                disablePadding
                              >
                                <ListItem role={undefined} dense>
                            <Switch onChange={handleToggle(value)} width={36} height={20} handleDiameter={16} onColor="#0887E2" onHandleColor="#3B4055" offHandleColor="#3B4055" uncheckedIcon={false} checkedIcon={false} checked={checked.indexOf(value) !== -1}/>

                              <ListItemText
                                sx={currencyStyle}
                                primary={
                                <React.Fragment>
                                    <Typography
                                      sx={currencyStyleBig}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                    {value.legalName}
                                    </Typography>
                                  </React.Fragment>}

                                />

                            <div style={{display: "grid"}}>
                                <ListItemText
                                primary={
                                  <React.Fragment>
                                      <Typography
                                        sx={currencyStyleUSD}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                    {`$ ${totalUSD}`}
                                      </Typography>
                                    </React.Fragment>}

                                secondary={
                                  <React.Fragment>
                                    <Typography
                                        sx={currencyStyle} 
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                    {`${totalCrypto} ${value.name}`}
                                    </Typography>
                                  </React.Fragment>
                                }
                                />
                              </div>

                
                                </ListItem>
                              </ListItem>
                            );
                          }) : (
                            <ListItem
                                sx={{backgroundColor: "#3B4055", width: "auto", maginRight: "10px"}}
                                // secondaryAction={
                                //   <IconButton edge="end" aria-label="comments">
                                //     <CommentIcon />
                                //   </IconButton>
                                // }
                                disablePadding
                              >
                                <ListItem role={undefined} dense>
                            <Switch disabled width={36} height={20} handleDiameter={16} onColor="#0887E2" onHandleColor="#3B4055" offHandleColor="#3B4055" uncheckedIcon={false} checkedIcon={false} checked={false}/>

                              <ListItemText
                                sx={currencyStyle}
                                primary={
                                <React.Fragment>
                                    <Typography
                                      sx={currencyStyleBig}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                    No assets found
                                    </Typography>
                                  </React.Fragment>}

                                />

                            <div style={{display: "grid"}}>
                                <ListItemText
                                primary={
                                  <React.Fragment>
                                      <Typography
                                        sx={currencyStyleUSD}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                    -
                                      </Typography>
                                    </React.Fragment>}
                                />
                              </div>
                                </ListItem>
                              </ListItem>
                          )}
                        </List>
                </Card>
                    )}
          </div>      
        </Box>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: '#171E37', position: 'fixed', top: 'auto', bottom: 96, borderTop: '1px solid rgba(255,255,255,0.25)' }}>
          <Fab sx={{
                  color: '#FFFFFF',
                  '&:disabled': {
                    color: 'white',
                    opacity: 0.3
                  }
                }} onClick={() => sendData()} disabled={name === "" && !state?.wallet_id} style={fabstyle} variant="extended" aria-label="add">
            <p>View dumpling</p>
          </Fab>
        </div>   
      </div>
      </div>
      </Paper>
    );
}
  
export default CreateDumpling;