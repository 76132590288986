// import Dumpling from '../images/DumplingLogo.jpg'
import { styled } from '@material-ui/core';
import CircleIcon from '@mui/icons-material/Circle';
import { ChevronLeft } from "@mui/icons-material";
import { Container, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Amplify, { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  useLocation
} from 'react-router-dom';
import { env } from '../environment';
import Dumpling from './Dumpling';
import SharedDumplings from './SharedDumplings';
import ShareForm from './ShareForm';
import SharedWith from './SharedWith';


Amplify.configure({
  Auth: {
    userPoolId: 'us-east-1_8MrfRwh7H', //UserPool ID
    region: 'us-east-1',
    userPoolWebClientId: '43pbrj3geo8pbu4j649lcm65c6' //WebClientId
  }
});

const title = {
  fontFamily: 'Montserrat-Regular',
  fontSize: "14px",
  textAlign: "center",
  marginTop: '50px',
  marginBottom: '18px',
  letterSpacing: '0.12em'
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, paddingLeft: '0px', paddingRight: '0px' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DumplingTabs = styled(Tabs)({
  borderBottom: 1,
  borderColor: 'white',
  '& .MuiTabs-indicator': {
    backgroundColor: 'white'
  }
})

const dumplingTab = {
  fontFamily: 'Montserrat-Regular',
  fontSize: '12px',
  letterSpacing: '0.1em',
  marginLeft: "-15px",
  width: '50%',
  color: 'rgba(255,255,255,0.5)',
  weight: 700,
  '&.MuiTab-textColorPrimary': {
    color: 'rgba(255,255,255,0.5)'
  },
  '&.Mui-selected': {
    color: 'white'
  }
}

const DumplingTab = styled(Tab)(({ theme }) => ({
  ...dumplingTab,
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));

const dumplingTitle = {
  paddingBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 500,
  marginBottom: "0px",
  marginTop: '0px',
  color: "white",
  whiteSpace: "nowrap"
}

const DumplingTitle = styled('h3')(({ theme }) => ({
  ...dumplingTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: "24px",
  }
}));

const dumplingSubTitle = {
  marginTop: "4px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "15px",
  color: "white",
}

const DumplingSubTitle = styled('h3')(({ theme }) => ({
  ...dumplingSubTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));

export default function Dashboard() {

  const [showNotification, setShowNotification] = useState(false);
  const { state } = useLocation();
  const [value, setValue] = React.useState((state !== null && state.isShared) ? 1 : 0);
  const [dumpling, setDumpling] = useState();
  const [authInfo, setAuthInfo] = React.useState();
  const navigate = useNavigate();


  const getSharedDumplings = async () => {
    const auth = await Auth.currentSession();
    const GET_SHARED_DUMPLINGS_ENDPOINT = `${env.server}/get_shared_dumplings?userId=${auth.idToken.payload.email}`;
    const options = {
      headers: {
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      }
    }

    await fetch(GET_SHARED_DUMPLINGS_ENDPOINT, options)
      .then(res => res.json())
      .then(json => {
        checkNewSharedDumplings(json)
      });
  }

  const getDumpling = async () => {
    const auth = await Auth.currentSession();
    setAuthInfo({
      idToken: auth.idToken.jwtToken,
      accessToken: auth.accessToken.jwtToken,
      username: auth.idToken.payload.email
    });
    const options = {
      headers: {
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      }
    }
    await fetch(`${env.server}/get_dumpling?userId=${auth.idToken.payload.email}&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
      setDumpling(json)
      console.log('test', dumpling)
    });
  }

  useEffect(() => {
    getDumpling();

  }, []);



  function checkNewSharedDumplings(sharedDumplings) {

    if (sharedDumplings.length > 0) {

      var hasFalseKeys = sharedDumplings.map(v => !!v.flag);
      let checker = arr => arr.every(Boolean);
      let hideModal = checker(hasFalseKeys)


      if (hideModal === false) {
        setShowNotification(true)
      }
    }
  }


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {dumpling !== undefined &&
        <div style={{ backgroundColor: '#171E37', height: '100vh', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', width: '100%' }}>
            <div style={{ width: '100%' }}>
              <ChevronLeft onClick={() => navigate(-1)} style={{ float: 'left', position: 'relative', top: '47px', left: 16, color: '#FFFFFF' }} />
              <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Manage sharing</h1>
              <hr style={{ width: '100%', borderColor: 'rgba(255,255,255,0.2)' }} />
            </div>
          </div>

          <Box sx={{ marginLeft: "16px", marginTop: "10px" }}>
            <DumplingTitle> {dumpling.name}</DumplingTitle>
            <DumplingSubTitle> Updated {(Date(dumpling.created_at)).slice(0, 16)} </DumplingSubTitle>
          </Box>

          <Container style={{ backgroundColor: '#171E37' }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              color: "white"
            }}>
              <Box sx={{ borderBottom: 1, borderColor: 'rgba(255,255,255,0.5)' }}>
                <DumplingTabs value={value} variant="fullWidth" onChange={handleChange} centered aria-label="basic tabs example">
                  <DumplingTab sx={dumplingTab} disableRipple label="Share" {...a11yProps(0)} />
                  <DumplingTab sx={dumplingTab} disableRipple label="Shared With" {...a11yProps(1)} />
                </DumplingTabs>
              </Box>
              <TabPanel value={value} index={0}>
                <ShareForm />
              </TabPanel>
              <TabPanel value={value} index={1}>

                <SharedWith />
              </TabPanel>
            </Box>


          </Container>
        </div>
      }
    </div>
  );
}
