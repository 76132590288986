import {makeStyles} from "@material-ui/core";
import {BottomNavigation,BottomNavigationAction,Paper} from "@mui/material";
import {Link,matchPath,useLocation,useNavigate} from "react-router-dom";
import {ReactComponent as AccountIcon} from './svgs/account-icon.svg';
import {ReactComponent as AccountSelectedIcon} from './svgs/account-selected-icon.svg';
import {ReactComponent as DumplingIcon} from './svgs/dumpling-nav-icon.svg';
import {ReactComponent as DumplingSelectedIcon} from './svgs/dumpling-nav-selected.svg';
import {ReactComponent as HomeIcon} from './svgs/home-icon.svg';
import {ReactComponent as HomeSelectedIcon} from './svgs/home-selected.svg';
import {ReactComponent as TransactIcon} from './svgs/transact-icon.svg';
import {ReactComponent as TransactSelectedIcon} from './svgs/transact-selected-icon.svg';

const useRouteMatch = (patterns) => {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}
const useStyles = makeStyles({
  button: {
    fontFamily: "Montserrat-Regular",
    color: "#FFFFFF",
    fontSize: 11
  }
});

const BottomNav = () => {
  const classes = useStyles();
  const location = useLocation();
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  const routeMatch = useRouteMatch(['/dashboard', '/connections', '/transact', '/account']);
  const currentTab = routeMatch?.pattern?.path;
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    console.log(newValue)
    location.push(`/${newValue}`);
  };

  const noTabs = () => {
    const paths = ['/', '/login', '/data-usage', '/terms-and-conditions', '/verifyUser'];
    if(paths.includes(location.pathname)) {
      return true;
    }
    else {
      return false;
    }
  }

  const determineIcon = (path) => {
    if(path === '/publicDumplings') {
      return path === location.pathname ? <HomeSelectedIcon /> : <HomeIcon />;
    }
    else if (path === '/dashboard') {
      return path === location.pathname ? <DumplingSelectedIcon /> : <DumplingIcon />;
    }
    else if (path === '/transact') {
      return path === location.pathname ? <TransactSelectedIcon /> : <TransactIcon />;
    }
    else if (path === '/account') {
      return path === location.pathname ? <AccountSelectedIcon /> : <AccountIcon />;
    }
  }

  const determineLabelColor = (path) => {
    if(path === '/publicDumplings') {
      return path === location.pathname ? '#FFFFFF' : '#3CA1EB';
    }
    else if (path === '/dashboard') {
      return path === location.pathname ? '#FFFFFF' : '#3CA1EB';
    }
    else if (path === '/transact') {
      return path === location.pathname ? '#FFFFFF' : '#3CA1EB';
    }
    else if (path === '/account') {
      return path === location.pathname ? '#FFFFFF' : '#3CA1EB';
    }
  }

  return !noTabs() ? (
    
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, height: 96, backgroundColor: "#171E37" }} elevation={3}> 
      <BottomNavigation
        showLabels
        value={currentTab}
        onChange={handleChange}
        style={{ backgroundColor: "#171E37", borderTop: '1px solid rgba(255,255,255,0.25)' }}
      >
        <BottomNavigationAction 
          style={{ color: determineLabelColor('/publicDumplings') }} 
          className={classes.button} 
          label="Home" 
          value="/publicDumplings" 
          to="/publicDumplings" 
          component={Link} 
          icon={determineIcon('/publicDumplings')} />
        <BottomNavigationAction style={{ color: determineLabelColor('/dashboard') }} className={classes.button} label="Dumplings" value="/dashboard" to="/dashboard" component={Link} icon={determineIcon('/dashboard')} />
        <BottomNavigationAction style={{ color: determineLabelColor('/transact') }} className={classes.button} label="Transact" value="/transact" to="/transact" component={Link} icon={determineIcon('/transact')} />
        <BottomNavigationAction style={{ color: determineLabelColor('/account') }} className={classes.button} label="Settings" value="/account" to="/account" component={Link} icon={determineIcon('/account')} />
      </BottomNavigation>
    </Paper>

  ) : null;
}

export default BottomNav;