import { styled } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { ChevronLeft } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import { Container, Radio, RadioGroup } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Grid, } from '@material-ui/core';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Switch from 'react-switch';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Amplify, { Auth } from 'aws-amplify';
import * as React from 'react';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { env } from '../environment';
import FormControlLabel from '@mui/material/FormControlLabel';
import { NoDumplingH1, NoDumplingH2, NoDumplingH3, noDumplingTextCard } from './Dumpling.js';
import { Fab } from '@mui/material';

Amplify.configure({
  Auth: {
    userPoolId: 'us-east-1_8MrfRwh7H', //UserPool ID
    region: 'us-east-1',
    userPoolWebClientId: '43pbrj3geo8pbu4j649lcm65c6' //WebClientId
  }
});

const useStyles = makeStyles({
  select: {
    border: "none",
    color: "white",
    "before": {
      border: "none",
    }
  },
  selectMenu: {
    "& ul": {
      backgroundColor: "#3A4056",
      color: "white",
      fontFamily: "Montserrat-Regular",
    },
    "& li": {
      fontFamily: "Montserrat-Regular",
      display: "block",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },

});

export const cardStyle = {
  padding: "20px",
  marginBottom: "20px",
  borderRadius: "20px",
  marginLeft: "0px",
  color: "white",
  backgroundColor: "#3A4056",
  fontSize: "18px",
  fontFamily: "Montserrat-Regular",
  fontWeight: 400,
}

const ShareForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [dumpling, setDumpling] = useState();
  const [authInfo, setAuthInfo] = React.useState();
  const [isOpenShared, setOpenShared] = React.useState(false);
  const [isPrivate, setIsPrivate] = React.useState(true);
  const handleOpenShared = () => setOpenShared(true);
  const handleCloseShared = () => setOpenShared(false);
  const [sharedWith, setSharedWith] = React.useState('');
  const [sharedWithFname, setSharedWithFname] = React.useState('');
  const [sharedWithLname, setSharedWithLname] = React.useState('');
  const [sharedWithCount, setSharedWithCount] = React.useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [permisison, setPermission] = React.useState('view');
  const classes = useStyles();

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDelete = () => setOpenDeleteModal(true);
  const handleCloseDelete = () => setOpenDeleteModal(false);

  const [removeDumplingID, setremoveDumplingID] = React.useState('');
  const [removePersonID, setremovePersonID] = React.useState('');
  
  const [quantitiesChecked, setQuantitiesChecked] = useState(true);
  const [valuesChecked, setValuesChecked] = useState(true);

  const QuantitiesHandler = (event) => {
    setQuantitiesChecked(event.target.checked);
  };

  const ValuesHandler = (event) => {
    setValuesChecked(event.target.checked);
  };

  // const handleChange = (event) => {
  //   setPermission(event.target.value);
  //   console.log("event", event)
  //   if (event === 'remove') {
  //     console.log("remove")
  //   }
  // };

  /*
  async function removeSharedWith() {
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    console.log("TO REMOVE", removePersonID.receiver_id, removeDumplingID)

    var raw = JSON.stringify({
      "receiverId": removePersonID.receiver_id,
      "sentFirstName": sharedWithFname,
      "sentLastName": sharedWithLname,
      "senderId": authInfo.username,
      "dumplingId": removeDumplingID,
      "actionType": "Remove"
    });

    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      },
      body: raw,
      redirect: 'follow'
    };

    await fetch(`${env.server}/ignore_dumpling`, requestOptions).then(res => res.json()).then(json => {

      console.log("ignore", json)
      handleCloseDelete()
      window.location.reload()
      //setOpen(true);// create new modal  for succesfful change

    }).catch(error => console.log('error', error));

  }
*/


  const handleChange = (person, event, dumplingID) => {
    console.log("test", person, event.target.value, dumplingID)
    setPermission(event.target.value);

    if (event.target.value === 'remove') {
      setremoveDumplingID(dumplingID)
      setremovePersonID(person)

      handleOpenDelete()
    }
  };

  const modalStyle = {
    top: '50%',
    left: '50%',
    position: 'absolute',
    fontFamily: "Montserrat-Regular",
    transform: 'translate(-50%, -50%)',
    width: "400",
    bgcolor: 'background.paper',
    borderRadius: 5,
    borderColor: "white",
    boxShadow: 24,
    p: 4,
    backgroundColor: "#3A4056",
    color: "white",
    textAlign: 'center'
  };


  async function shareDumpling() {
    if(!isPrivate) {
      const raw = JSON.stringify({
        "dumplingId": state.dumpling_id,
        "userId": authInfo.username,
        "userFirstName": firstName,
        "userLastName": lastName,
      });

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": authInfo.idToken,
          "accessTokenStr": authInfo.accessToken
        },
        body: raw,
        redirect: 'follow'
      };

      await fetch(`${env.server}/community_sharing`, requestOptions).then(res => res.json()).then(json => {
        console.log("TEST SHARE", json);
        handleOpenShared();
      }).catch(error => console.log('error', error));
    } 
    else {
      var raw = JSON.stringify({
        "dumplingId": state.dumpling_id,
        "receiverID": sharedWith,
        "receiverFirstName": sharedWithFname,
        "receiverLastName": sharedWithLname,
        "senderID": authInfo.username,
        "senderFirstName": firstName,
        "senderLastName": lastName,
        "quantitiesFlag": quantitiesChecked,
        "valuesFlag": valuesChecked
      });
  
      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": authInfo.idToken,
          "accessTokenStr": authInfo.accessToken
        },
        body: raw,
        redirect: 'follow'
      };
  
      await fetch(`${env.server}/share_dumpling`, requestOptions).then(res => res.json()).then(json => {
        console.log("TEST SHARE", json)
        handleOpenShared();
      }).catch(error => console.log('error', error));
    }
  }
  const sendButton = {
    fontFamily: 'Montserrat-Regular',
    fontSize: 16,
    textTransform: 'none',
    fontWeight: 700,
    marginTop: '16px',
    marginBottom: '16px',
    backgroundColor: '#0887E2',
    borderRadius: 30,
    padding: "5px",
    height: '44px',
    minWidth: '358px'
  }

  const inputContainer = {
    display: 'flex',
    width: '171px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: 'white',
    fontFamily: 'Montserrat-Regular',
    fontSize: '12px'
  }

  const textField = {
    width: '140px',
    height: '44px',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderRadius: 10,
    border: '1px solid white',
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: '16px'
  }

  const emailField = {
    width: '92%',
    height: '44px',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderRadius: 10,
    border: '1px solid white',
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: '16px'
  }

  const dumplingTitle = {
    paddingBottom: '0px',
    // marginTop: "84px",
    fontFamily: "Montserrat-Regular",
    textAlign: "left",
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "0px",
    marginTop: '0px',
    color: "white",
    whiteSpace: "nowrap"
  }

  const DumplingTitle = styled('h3')(({ theme }) => ({
    ...dumplingTitle,
    [theme.breakpoints.down('xs')]: {
      fontSize: "24px",
    }
  }));

  const dumplingSubTitle = {
    marginTop: "2px",
    fontFamily: "Montserrat-Regular",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: 400,
    marginBottom: "0px",
    color: "white",
  }

  const DumplingSubTitle = styled('h3')(({ theme }) => ({
    ...dumplingSubTitle,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  }));

  const getDumpling = async () => {
    const auth = await Auth.currentSession();
    setAuthInfo({
      idToken: auth.idToken.jwtToken,
      accessToken: auth.accessToken.jwtToken,
      username: auth.idToken.payload.email
    });
    const options = {
      headers: {
        "Authorization": auth.idToken.jwtToken,
        "accessTokenStr": auth.accessToken.jwtToken
      }
    }

    await fetch(`${env.server}/get_dumpling?userId=${auth.idToken.payload.email}&id=${state.dumpling_id}`, options).then(res => res.json()).then(json => {
      setDumpling(json)
      setSharedWithCount(json.shared_with.length)
    });
  }

  const handleTypeChange = (e) => {
    e.target.value === 'individual'
      ? setIsPrivate(true)
      : setIsPrivate(false);
  }

  const disableShare = () => {
    if(!isPrivate){
      return false
    }
    else {
      return sharedWithFname === "" || sharedWithLname === "" || sharedWith === ""
    }
  }

  useEffect(() => {
    Auth.currentUserInfo().then((result) => {
      let lastname = result.attributes.name !== "" ? result.attributes.name.split(' ').pop() : ""
      var index = result.attributes.name.lastIndexOf(" ");
      let firstname = result.attributes.name.substring(0, index);
      console.log(result)
      setFirstName(result.attributes.name)
      setFirstName(firstname)
      setLastName(lastname)
    }).catch((err) => {
      // Something is Wrong
      console.log('error getting user', err);
    })

    getDumpling();
    console.log('testing', dumpling)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    
    <div>
      {dumpling !== undefined &&

        <div style={{ backgroundColor: '#171E37', width: '100%' }}>
          <Container sx={{ paddingLeft: '0px', paddingRight: '0px', display: 'flex', justifyContent: 'center', marginBottom: '172px' }}>
              {/* <Card style={{padding: "10px", width: "450px", backgroundColor: "#313B47", marginTop: "10px", marginBottom: "50px",  borderRadius: "10px"}}> */}
              <form style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start' }}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: 24 }}>
                  <RadioGroup style={{ width: '100%', flexWrap: 'nowrap' }} row defaultValue="individual" onChange={(e) => handleTypeChange(e)}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 24, color: '#3CA1EB' } }} value="individual" />
                      <label style={{ fontFamily: 'Montserrat-Regular', fontSize: 20 }}>Individual</label>
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 24, color: '#3CA1EB' } }} value="community" />
                      <label style={{ fontFamily: 'Montserrat-Regular', fontSize: 20 }}>Community</label>
                    </div>
                  </RadioGroup>
                </div>
                { isPrivate
                  ? (<>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                  <div>
                    <label style={inputContainer}>
                      <span style={{ marginBottom: '4px', fontWeight: 600 }}>First name</span>
                      <input style={textField} id="name" placeholder='James' type='text' onChange={(e) => setSharedWithFname(e.target.value)}/>
                    </label>
                  </div>
                  <div>
                    <label style={inputContainer}>
                      <span style={{ marginBottom: '4px', fontWeight: 600 }}>Last name</span>
                      <input style={textField} id="name" placeholder='Bond' type='text' onChange={(e) => setSharedWithLname(e.target.value)}/>
                    </label>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start' }}>
                  <label style={{ marginTop: 16, display: 'flex', flexDirection: 'column', width: '100%', fontFamily: 'Montserrat-Regular', fontSize: 12, fontWeight: 500 }}>
                    <span style={{ marginBottom: '4px', fontWeight: 600 }}>Email</span>
                    <input style={emailField} id="name" placeholder='james.bond@mi6.com' type='text' onChange={(e) => setSharedWith(e.target.value)}/>
                  </label>
                  <DumplingSubTitle>&#42; Required field </DumplingSubTitle>

                  <NoDumplingH3 style={{ color: "white" }}> Asset name and allocation will always be shared. <br /> Also share: </NoDumplingH3>

                  <Box backgroundColor='rgba(255,255,255,0.06)' display="flex" justifyContent="flex-start" alignItems="flex-start" padding="10px" style={{ borderRadius: "10px", width: "96%", marginBottom: "10px" }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                      <Switch onChange={() => setQuantitiesChecked(!quantitiesChecked)} width={36} height={20} handleDiameter={16} onColor="#0887E2" onHandleColor="#3B4055" offHandleColor="#3B4055" uncheckedIcon={false} checkedIcon={false} checked={quantitiesChecked} />
                      <p style={{ width: 84, marginLeft: 20, marginRight: 25 }}>Quantities</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Switch onChange={() => setValuesChecked(!valuesChecked)} width={36} height={20} handleDiameter={16} onColor="#0887E2" onHandleColor="#3B4055" offHandleColor="#3B4055" uncheckedIcon={false} checkedIcon={false} checked={valuesChecked} />
                      <p style={{ width: 84, marginLeft: 20 }}>Values</p>
                    </div>
                  </Box>
                </div></>)
                : (
                  <div style={{ fontFamily: 'Montserrat-Regular', fontSize: 13, fontWeight: 400 }}>
                    When sharing with the entire Dumpling community, only the asset name and allocation will be shared.
                  </div>
                ) }
              </form>
          </Container>
          </div>
      }

      <Modal
        open={isOpenShared}
        onClose={handleCloseShared}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div style={{ textAlign: "center" }}>
            <CheckIcon style={{ marginBottom: "0px", color: "#02EDBA" }} fontSize="large" />
            <h1 style={{ marginBottom: "0px", textAlign: "center" }}>{`Dumpling Shared! `}</h1>
          </div>
          <h3 style={{ textAlign: "center" }}> You have shared the contents of this dumpling with {isPrivate ? `${sharedWithFname} ${sharedWithLname}` : 'the community.'} </h3>
          <Button href='/dashboard' style={{ marginBottom: "10px", height: "44px", backgroundColor: "#0887E2", color: 'white', width: "100%", borderRadius: "20px", textTransform: "none", fontFamily: "Montserrat-Regular" }} >Ok</Button>
        </Box>

      </Modal>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: '#171E37', position: 'fixed', top: 'auto', bottom: 96, borderTop: '1px solid rgba(255,255,255,0.25)' }}>
        <Fab sx={{
          color: '#FFFFFF',
          '&:disabled': {
            color: 'white',
            opacity: 0.3
          }
        }} onClick={() => shareDumpling()} disabled={disableShare()} style={sendButton} variant="extended" aria-label="add">
          <p>Share</p>
        </Fab>
      </div>
    </div>

  )
}

export default ShareForm;