// eslint-disable-next-line react-hooks/exhaustive-deps
import {styled} from '@material-ui/core';
import {Container,Fab,Skeleton} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import '@uniswap/widgets/fonts.css';
import {Auth} from 'aws-amplify';
import {useEffect,useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Button from "../Components/Button";
import DashboardDumplingCard from '../Components/DashboardDumplingCard';
import EverythingCard from '../Components/EverythingCard';
import SortDrawer,{sortAlphabetical,sortBalance,sortMostRecent} from '../Components/SortDrawer';
import {ReactComponent as SortIcon} from '../Components/svgs/sort-icon.svg';
import {env} from '../environment';
import {getAllWallets} from "../Utils/getAllWallets.js";

export const noDumplingTextCard = {
  padding: "20px", 
  marginBottom: "132px", 
  borderRadius: "20px",
  marginLeft: "0px",
  color: "white",
  backgroundColor: "#3A4056",
  fontSize: "18px",  
  fontFamily: "Montserrat-Regular",
  fontWeight: 400,
}

export const NoDumplingH1 = styled('h1')(({ theme }) => ({
  fontSize: '24px',
  marginBottom: '17px',
  fontFamily: "Montserrat-Regular",
  fontWeight: 400,
}));

export const NoDumplingH2 = styled('h2')(({ theme }) => ({
  fontSize: '20px',
  padding: 0,
  fontFamily: "Montserrat-Regular",
  fontWeight: 400,
  [theme.breakpoints.up('sm')]: { 
    fontSize: '20px'
  }
}));


export const NoDumplingH3 = styled('h3')(({ theme }) => ({
  fontSize: '14px',
  padding: 0,
  fontFamily: "Montserrat-Regular",
  fontWeight: 400,
}));

const dumplingCount = {
  marginTop: "2px",
  paddingLeft: "8px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "10px",
  fontWeight: 600,
  marginBottom: "0px",
  color: "white"
}

const sortButton = {
  height: '24px',
  width: '130px',
  borderRadius: 5,
  backgroundColor: 'transparent',
  boxShadow: 'none',
  fontSize: '10px',
  fontWeight: 600,
  textTransform: 'none',
  paddingRight: 10,
  mx: 'auto'
}

const fabstyle = {
  fontFamily: 'Montserrat-Regular',
  fontSize: 16,
  textTransform: 'none',
  fontWeight: 600,
  marginTop: '16px',
  marginBottom: '16px',
  backgroundColor: '#0887E2',
  borderRadius: 30,
  padding: "5px",
  height: '44px',
  minWidth: '358px'
};

export const SortButton = styled(Button)(({ theme }) => ({
  ...sortButton,
  [theme.breakpoints.down('xs')]: {
    fontSize: '10px'
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'rgba(255,255,255,0.06)'
  },
  '&:focus': {
    boxShadow: 'none',
    backgroundColor: 'rgba(255,255,255,0.06)'
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'rgba(255,255,255,0.06)'
  }
}));

const Dumpling = () => {

const navigate = useNavigate();
const [authInfo, setAuthInfo] = useState();
const [myDumplings, setMyDumplings] = useState(0);
const [allAssets, setAllAssets] = useState();
const [isLoading, setIsLoading] = useState(false);
const [isLoadingEverything, setIsLoadingEverything] = useState();
const [hideEverythingDumpling, setHideEverythingDumpling] = useState(false);

// const [myAssets, setAssets] = useState(0);
const [ walletValues, setWalletValues ] = useState([]);
const [ hasDumplings, setHasDumplings ] = useState(false);
const [sort, setSort] = useState('Most recent');
const [openSort, setOpenSort] = useState(false);

const getUserDumplings = async () => {
  const auth = await Auth.currentSession();
  setAuthInfo({
    idToken: auth.idToken.jwtToken,
    accessToken: auth.accessToken.jwtToken,
    username: auth.idToken.payload.email
  });
  const options = {
    headers: {
      "Authorization": auth.idToken.jwtToken,
      "accessTokenStr": auth.accessToken.jwtToken
    }
  }

  await fetch(`${env.server}/get_user_dumplings?userId=${auth.idToken.payload.email}`, options).then(res => res.json()).then(json => {
    const everything_element = json.pop();
    if(everything_element){
      setAllAssets(everything_element);
    }
    setIsLoadingEverything(false);
    if (json && json.length > 0){
      if(sort === 'Most recent'){
        setMyDumplings(sortMostRecent(json));
      }
      else if(sort === 'A-Z'){
        setMyDumplings(sortAlphabetical(json));
      }
      else if(sort === 'Highest balance'){
        setMyDumplings(sortBalance(json, 'high'));
      }
      else if(sort === 'Lowest balance'){
        setMyDumplings(sortBalance(json, 'low'));
      }
      if (json.length === 1 && json[0].currency_set.length === 0) {
        setHideEverythingDumpling(true)
      }
      setHasDumplings(true);
    }
    setIsLoading(false);
  }).catch(err => setIsLoading(false));
}

useEffect(() => {

    const totalWalletVals = getAllWallets();

    totalWalletVals.then(function(result) {
      setWalletValues(result)
    });
    
    setIsLoading(true);
    setIsLoadingEverything(true);
    getUserDumplings();
  }, [sort]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSingleDumpling = (dumplingID, myTotal, assets, isEverything = null) => {

      navigate('/singleDumpling',
          {
              state: {
                dumpling_id: dumplingID,
                myTotal: myTotal,
                assets: assets,
                isEverything: isEverything,
                quantities: true,
                values: true
              }
          });
  }


const toggleSort = () => {
  setOpenSort(!openSort);
}

const handleCreateDumpling = async () => {
  const options = {
    headers: {
      "Authorization": authInfo.idToken,
      "accessTokenStr": authInfo.accessToken
    }
  }

  await fetch(`${env.server}/get_user_deduped_wallets?userId=${authInfo.username}`, options)
    .then(res => res.json())
    .then(json => {
      if(json.length !== 0) {
        navigate('/create', { replace: true, state: { existing: true } });
      }
      else {
        navigate('/connect');
      }
    })
    .catch(err => {
      console.log('unable to get user wallets...', err);
    })
}

return (
<div>
  { isLoading
    ? (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
        <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px' }} variant="text" width={360} height={40}/>
        <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px' }} variant="text" width={360} height={40}/>
        <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px', marginBottom: '16px' }} variant="rectangular" width={360} height={300} />
        <Skeleton sx={{ bgcolor: 'rgba(255,255,255,0.06)', borderRadius: '10px', marginBottom: '16px' }} variant="rectangular" width={360} height={136} />
      </div>
    ) : (
      <>
        { hasDumplings && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Container sx={{ paddingLeft: '0px', paddingRight: '0px', paddingTop: '5px', display: 'flex', justifyContent: 'center', marginBottom: '172px' }}>
            <div style={{ width: 358 }}>

            {/* <Button mx='auto' variant="contained" color="primary" style={{ float: "right",  width: "200px", borderRadius: "20px", fontFamily: "Montserrat-Regular", }} onClick={() => { sync() }}> Sync Assets</Button> */}
            
            { !hideEverythingDumpling &&
            <div style={{ width: 358 }}>
            { allAssets && <EverythingCard dumpling={allAssets} roundedTotal={0} total={0} goToDumpling={getSingleDumpling} /> }
              </div>
            }
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <h3 style={dumplingCount}>
                {myDumplings.length} {myDumplings.length === 1 ? 'Dumpling' : 'Dumplings'}
              </h3>
              <SortButton variant="contained" onClick={() => toggleSort()}>
                <span style={{ fontSize: '10px' }}>
                  {sort} <SortIcon style={{ marginLeft: 13, fontSize: 10 }}/>
                </span>
              </SortButton>
            </div>



                { myDumplings !== undefined && myDumplings.length > 0 &&
                  <div>
                    {myDumplings.map((dumpling) => {
                      let total = 0;
                      let roundedTotal = 0;
                      return  (
                        <DashboardDumplingCard dumpling={dumpling} roundedTotal={roundedTotal} total={total} goToDumpling={getSingleDumpling} isEverything={null}/>
                      )})}
                  </div>
                }
              </div>
            </Container>
          </Box>  
        )} 

        {!hasDumplings && (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <div style={{ maxWidth: '400px'}}>
            <NoDumplingH1> You haven't created any dumplings yet. </NoDumplingH1>
      
            <Card style={noDumplingTextCard}>      
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}> 
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.859619" width="24" height="24" rx="12" fill="#171E37"/>
                  <path d="M13.4336 7.85962V18.3596H11.4836V9.49462H9.26355V7.85962H13.4336Z" fill="white"/>
                </svg> 
                <NoDumplingH1 style={{ fontSize: 16, margin: 0, marginLeft: 8 }}>Connect accounts</NoDumplingH1>
              </div>
              <NoDumplingH3 style={{ marginLeft: 32, marginTop: 5 }}> Connect a wallet or exchange to identify your assets. </NoDumplingH3>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}> 
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.446533" width="24" height="24" rx="12" fill="#171E37"/>
                  <path d="M15.8998 16.2965V17.9465H8.15977V16.6415L12.3298 12.6815C12.7998 12.2315 13.1148 11.8415 13.2748 11.5115C13.4448 11.1715 13.5298 10.8365 13.5298 10.5065C13.5298 10.0165 13.3648 9.64153 13.0348 9.38153C12.7048 9.12153 12.2198 8.99153 11.5798 8.99153C10.5098 8.99153 9.68977 9.35653 9.11977 10.0865L7.75477 9.03653C8.16477 8.48653 8.71477 8.06153 9.40477 7.76153C10.1048 7.45153 10.8848 7.29653 11.7448 7.29653C12.8848 7.29653 13.7948 7.56653 14.4748 8.10653C15.1548 8.64653 15.4948 9.38153 15.4948 10.3115C15.4948 10.8815 15.3748 11.4165 15.1348 11.9165C14.8948 12.4165 14.4348 12.9865 13.7548 13.6265L10.9498 16.2965H15.8998Z" fill="white"/>
                </svg>
                <NoDumplingH1 style={{ fontSize: 16, margin: 0, marginLeft: 8 }}>Select assets</NoDumplingH1>
              </div>
              <NoDumplingH3 style={{ marginLeft: 32, marginTop: 5 }}> Decide which assets to put into your new dumpling.</NoDumplingH3>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}> 
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.446533" width="24" height="24" rx="12" fill="#171E37"/>
                  <path d="M11.5948 18.0965C10.8548 18.0965 10.1298 17.9915 9.41977 17.7815C8.71977 17.5615 8.12977 17.2615 7.64977 16.8815L8.48977 15.3665C8.86977 15.6765 9.32977 15.9265 9.86977 16.1165C10.4098 16.3065 10.9748 16.4015 11.5648 16.4015C12.2648 16.4015 12.8098 16.2615 13.1998 15.9815C13.5898 15.6915 13.7848 15.3015 13.7848 14.8115C13.7848 14.3315 13.6048 13.9515 13.2448 13.6715C12.8848 13.3915 12.3048 13.2515 11.5048 13.2515H10.5448V11.9165L13.5448 8.34653L13.7998 9.08153H8.15977V7.44653H15.3148V8.75153L12.3148 12.3215L11.2948 11.7215H11.8798C13.1698 11.7215 14.1348 12.0115 14.7748 12.5915C15.4248 13.1615 15.7498 13.8965 15.7498 14.7965C15.7498 15.3865 15.5998 15.9315 15.2998 16.4315C14.9998 16.9315 14.5398 17.3365 13.9198 17.6465C13.3098 17.9465 12.5348 18.0965 11.5948 18.0965Z" fill="white"/>
                </svg>
                <NoDumplingH1 style={{ fontSize: 16, margin: 0, marginLeft: 8 }}>Create to view, share, or modify</NoDumplingH1>
              </div>
            </Card>
            </div>
          </div>
          )}
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: '#171E37', position: 'fixed', top: 'auto', left: 0, bottom: 96, borderTop: '1px solid rgba(255,255,255,0.25)' }}>
            <Fab sx={{ color: '#FFFFFF' }} onClick={() => handleCreateDumpling()} style={fabstyle} variant="extended" aria-label="add">
              <p>Create a dumpling</p>
            </Fab>
          </div>
          <SortDrawer openSort={openSort} setSort={setSort} toggleSort={toggleSort} sortType={sort}/>
      </>
    ) }
</div>

        



  );
}

export default Dumpling;
