
import {
  Text
} from "@chakra-ui/react";
import {styled} from "@material-ui/core";
import {ChevronLeft} from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Modal from '@mui/material/Modal';
import {ReactComponent as MetamaskIcon} from '../Components/svgs/metamask-icon.svg';
import {ReactComponent as RainbowIcon} from '../Components/svgs/rainbow-icon.svg';
import {ReactComponent as TrustIcon} from '../Components/svgs/trust-icon.svg';
import {useWeb3React} from "@web3-react/core";
import {Auth} from "aws-amplify";
import React,{useEffect} from 'react';
import {useLocation,useNavigate} from "react-router-dom";
import {env} from '../environment';
import CoinbaseLogo from '../images/Coinbase.svg';
import MetamaskLogo from '../images/MetamaskLogo.svg';
import WalletConnectLogo from '../images/WalletConnectLogo.svg';
import {connectors} from "./Connectors";

// const truncateAddress = (address) => {
//   if (!address) return "No Account";
//   const match = address.match(
//     /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
//   );
//   if (!match) return address;
//   return `${match[1]}…${match[2]}`;
// };

const ConnectContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '#171e37'
}));

const providerTitle = {
  paddingBottom: '0px',
  marginBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "24px",
  fontWeight: 600,
  color: "white",
  whiteSpace: "nowrap",
  marginTop: "10px"
}

const ProviderTitle = styled('h3')(({ theme }) => ({
  ...providerTitle,
  [theme.breakpoints.down('xs')]: {
    fontSize: '20px'
  }
}));

const ProviderTitleModal = styled('h3')(({ theme }) => ({
  ...providerTitle,
  textAlign: "center",
  [theme.breakpoints.down('xs')]: {
    textAlign: "center",
  }
}));


function getProvImageModal(provider) {
  const logo = (provider === "Metamask") ? MetamaskLogo : (provider === "walletConnect") ? WalletConnectLogo : (provider === "Coinbase" || "coinbaseExchange") ? CoinbaseLogo : null
  return (
    <WalletImg
    src={logo}
    alt=" Logo"
    />
  )
}

function getProvImage(provider) {
  const logo = (provider === "Metamask") ? MetamaskLogo : (provider === "walletConnect") ? WalletConnectLogo : (provider === "Coinbase") ? CoinbaseLogo : (provider === "coinbaseExchange") ? CoinbaseLogo : null
  const text = (provider === "Coinbase") ? "Wallet" : (provider === "coinbaseExchange") ? "Exchange" : null
  if (text === "Wallet" || text === "Exchange") {
  return (
   <div style={{marginLeft: text === "Wallet" ? "20px" : ""}} >
    <CBImg
    src={logo}
    alt=" Logo"
    />
    <div style={{float: "right", marginLeft: "6px", marginTop: "8px", fontSize: "16px"}}> {text} </div> 
    </div>
  )
  }
  else {
    return (
      <WalletImg
      src={logo}
      alt=" Logo"
      />

    )

  }
}


const modalStyle = {
  position: 'absolute',
  fontFamily: "Montserrat-Regular",
  top: '50%',
  left: '50%', 
  transform: 'translate(-50%, -50%)',
  width: "400",
  bgcolor: 'background.paper',
  borderRadius: 5,
  borderColor: "white",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#3A4056", 
  color: "white",
  textAlign: 'center'
};

const connectCard = {
  padding: "10px", 
  marginBottom: "20px", 
  borderRadius: "20px",
  color: "white",
  backgroundColor: "#252B43",
  fontSize: "18px"
}

const connectionImg = {
  float: "left",
  marginLeft: "10px"
}
const dumplingSubTitle = {
  marginTop: "2px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 400,
  marginBottom: "30px",
  color: "white"
}

const sectionTitle = {
  fontSize: "20px",
  marginBottom: '10px',
  fontFamily: "Montserrat-Regular",
  color: "white"
}

const CBImg = styled('img')(({ theme }) => ({
  borderRadius: '3px',
  height: '35px',
  width: '90px',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '90px',
  }
}));

const WalletImg = styled('img')(({ theme }) => ({
  borderRadius: '3px',
  height: '35px',
  width: '140px',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '140px',
  }
}));


const WalletConnectButton = styled(Button)(({ theme }) => ({
  '.MuiButton-textPrimary': {
    color: "#3CA1EB" 
  },
  borderRadius: 10,
  fontSize: '14px',
  width: "112px",
  height: "18px",
  color: "#3CA1EB", 
  [theme.breakpoints.down('sm')]: {
    '.MuiButton-textPrimary': {
      color: "#3CA1EB" 
    },
    fontSize: '14px',
    width: "112px",
    height: "18px",
    color: "#3CA1EB", 
  }
}));


export default function AddWallet() {

  // const state = React.useContext(GlobalContext);
  const [authInfo, setAuthInfo] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const { state } = useLocation();
  const [ walletType, setWalletType ] = React.useState("");
  const [ cbBalance, setCBbalance ] = React.useState(0);
  const [ walletAdd, setWalletAdd ] = React.useState("");

  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };

  const {
    account,
    provider,
    activate,
    // deactivate,
    active
  } = useWeb3React();

  const getAuthUser = async () => {
    await Auth.currentSession()
      .then(res =>
        setAuthInfo({
          idToken: res.idToken.jwtToken,
          accessToken: res.accessToken.jwtToken,
          username: res.idToken.payload.email
        })
      )
      .catch(err => console.log('could not get user...'));
  }


  const handleSelect = async (value) => {

    if (value === "MM") {
      console.log("value", value)
      if (typeof window.ethereum !== "undefined") {
        let provider = window.ethereum;
        // edge case if MM and CBW are both installed
        if (window.ethereum.providers?.length) {
          window.ethereum.providers.forEach(async (p) => {
            if (p.isMetaMask) provider = p;
          });
        }
        await provider.request({
          method: "eth_requestAccounts",
          params: [],
        }).then((response) => {
          if (response && response.length > 0) {
            setWalletAdd(response[0])
            setWalletType("Metamask")
          }
        }).catch(err => console.log(err));
      }
    } else {
      activate(value)
    }
  };

  useEffect(() => {
    getAuthUser();
    if (active && walletType !== "") {
        setOpen(true)
    }
    else if (walletType === "Metamask" && walletAdd !== ""){
      setOpen(true)
    }
  }, [active, walletType, walletAdd])

  function redirectToCoinbase(){
    setOpen(false)
    window.location="https://www.coinbase.com/oauth/authorize?response_type=code&client_id=3107595ac5102a9dff25ffb67fd039277932915fd27a15d5919b362388eb2a89&redirect_uri=https://www.dumplingapp.io/callback&state=secret&scope=wallet:user:read,wallet:user:email,wallet:accounts:read,wallet:transactions:read&account=all" 

  }

  async function getCBbalance(){
    const options = {
      headers: {
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      }
    }

    await fetch(`${env.server}/get_coinbase_balance?userId=${authInfo.username}`, options)
     .then(res => res.json())
     .then(json => {
      setWalletType("Coinbase");
      if (json !== undefined) {
        setOpen(true)
        setCBbalance(json)
      }
    }).catch(err => console.log(err));
  }

  async function checkCoinbaseAccount(){
    const options = {
      headers: {
        "Authorization": authInfo.idToken,
        "accessTokenStr": authInfo.accessToken
      }
    }

    await fetch(`${env.server}/get_coinbase_refresh_token?userId=${authInfo.username}`, options)
      .then(res => res.json())
      .then(json => {

      if (json.HTTPStatusCode === 404) {
        redirectToCoinbase()
      }
      if (json.HTTPStatusCode === 200) {
        getCBbalance()
      }
      }).catch(err => console.log(err));
  }


  const sendData = () => {
    // console.log("walletType", walletType)
    // console.log("provider", provider)
    navigate('/create',
        {
            state: {
              dumpling_id: state.dumpling_id,
              wallet_id: account ? account : walletAdd,
              wallet_provider: walletType,
              cb_assets: cbBalance ? cbBalance : []
            }
        });
}

return (
  <>
  
  <div style={{ width: '100%' }}>
    <ChevronLeft onClick={() => navigate(-1)} style={{ float: 'left', position: 'relative', left: 16, color: '#FFFFFF' }}/>
    <h1 style={{ fontSize: '14px', fontFamily: 'Montserrat-Regular', letterSpacing: '0.12em', textAlign: 'center', textTransform: 'uppercase', color: '#FFFFFF', marginTop: '50px', marginBottom: '18px' }}>Create a Dumpling</h1>
    <hr style={{ width: '100%', borderColor: 'rgba(255,255,255,0.2)' }}/>
  </div>

  <ConnectContainer>
 <Box
    display="flex"
    justifyContent="center"
    style={{ backgroundColor: '#171e37' }}
  >

    <div style={{ width: 358 }}>
  
      <h1 style={sectionTitle}>Connect wallets & exchanges</h1>
      <h3 style={dumplingSubTitle}> Select which web3 accounts you would like to connect.</h3>

      <List sx={{ pt: 0 }}>
      <h1 style={sectionTitle}>Wallets</h1>
      <Card style={connectCard}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <ProviderTitle> Connect wallets </ProviderTitle>
            <p style={{ fontFamily: 'Montserrat-Regular', fontSize: 10, fontWeight: 400, marginTop: 5 }}>
              Powered by WalletConnect
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 210, height: 31 }}>
              <div style={{ backgroundColor: 'rgba(255,255,255,0.1)', display: 'flex', width: 50, height: 'inherit', justifyContent: 'center', alignItems: 'center' }}>
                <MetamaskIcon/>
              </div>
              <div style={{ backgroundColor: 'rgba(255,255,255,0.1)', display: 'flex', width: 50, height: 'inherit', justifyContent: 'center', alignItems: 'center' }}>
                <RainbowIcon/>
              </div>
              <div style={{ backgroundColor: 'rgba(255,255,255,0.1)', display: 'flex', width: 50, height: 'inherit', justifyContent: 'center', alignItems: 'center' }}>
                <TrustIcon/>
              </div>
              <div style={{ backgroundColor: '#52596D', border: '2px solid rgba(255,255,255,0.25)', borderRadius: 18, width: 44, height: 31, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontFamily: 'Montserrat-Regular', fontSize: 12, fontWeight: 600 }}>+150</p>
              </div>
            </div>
          </div>
          <WalletConnectButton  startIcon={<AddCircleIcon />}  sx={{color: "#3CA1EB", marginTop: "16px", width: '30%' }} variant="text" disableElevation      
            onClick={() => {
                setProvider('walletConnect');
                setWalletType('walletConnect');
                handleSelect(connectors.walletConnect);
              }} >
              Connect
          </WalletConnectButton>
        </div>
      </Card>

      <h1 style={sectionTitle}>Exchanges</h1>
      <Card style={connectCard}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <ProviderTitle> {getProvImage('coinbaseExchange')} </ProviderTitle>
          </div>
          <WalletConnectButton startIcon={<AddCircleIcon />}  sx={{color: "#3CA1EB"}} variant="text" disableElevation        
            onClick={() => {
              checkCoinbaseAccount()
              // window.location="https://www.coinbase.com/oauth/authorize?response_type=code&client_id=3107595ac5102a9dff25ffb67fd039277932915fd27a15d5919b362388eb2a89&redirect_uri=https://www.dumplingapp.io/callback&state=secret&scope=wallet:user:read,wallet:user:email,wallet:accounts:read,wallet:transactions:read&account=all"
              }} > 
            Connect
          </WalletConnectButton>
        </div>
      </Card>
      </List>
 <Modal
    open={isOpen}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
     <Box sx={modalStyle}>
      <div style={{ padding: 10 }}>
        <CheckIcon style={{marginBottom: "0px", color: "#02EDBA"}} fontSize="large"/>
        <ProviderTitleModal> {getProvImageModal(walletType)} </ProviderTitleModal>
        <Text>You can now add assets to your dumplings from this wallet.</Text>
        <Button onClick={() => { sendData() }} style={{marginBottom: "10px", height: "44px", backgroundColor: "#0887E2", color: 'white', width: "100%", borderRadius: "20px", textTransform: "none", fontFamily: "Montserrat-Regular", }} >Continue</Button>
      </div>
     </Box>
   </Modal>

 </div>

    </Box>
    </ConnectContainer>

  </>
);
}



