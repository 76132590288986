import {styled} from "@material-ui/core";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Box,Card} from "@mui/material";
import {cryptoSymbol} from 'crypto-symbol';
import {Cell,Legend,Pie,PieChart,ResponsiveContainer} from "recharts";
import {aggregateCurrencies} from "./AssetList";
import {determineChange} from "./CurrencyCard";
import {buildTagList} from "./DashboardDumplingCard";
import {COLORS} from "./EverythingCard";

const currSubtitle = {
  marginTop: "0px",
  marginBottom: '0px',
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "12px",
  fontWeight: 400,
  color: "rgba(255,255,255,0.7)",
  float: "left"
}

const dumplingTitle = {
  paddingBottom: '0px',
  // marginTop: "84px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 600,
  marginBottom: "0px",
  marginTop: '0px',
  color: "white"
}

const dumplingSubTitle = {
  marginTop: "2px",
  paddingLeft: "8px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  marginBottom: "0px",
  color: "white",
}

const dumplingSub = {
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  marginTop: '0px',
  marginBottom: "0px",
  color: "white"
}

const DumplingSub = styled('h3')(({ theme }) => ({
  ...dumplingSub,
  [theme.breakpoints.down('md')]: {
    fontSize: '14px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px'
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));

const dumplingDesc = {
  maxHeight: '160px',
  paddingLeft: "8px",
  fontFamily: "Montserrat-Regular",
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 200,
  marginTop: "10px",
  marginLeft: '5px',
  color: "white",
  overflowY: 'scroll'
}

const DumplingDesc = styled('h3')(({ theme }) => ({
  ...dumplingDesc,
  [theme.breakpoints.down('md')]: {
    fontSize: '14px'
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px'
  }
}));

const totalBalanceStyle = {
  fontFamily: "Montserrat-Regular",
  color: "white",
  fontSize: "22px",
  fontWeight: 200,
  textAlign: "left",
  margin: '0px'
}

const chartdata = [
  { name: "ETH", value: 100 }
];

export const aggregateSharedCurrencies = (currencies, wallets) => {
  //create intial aggregate object
  const aggregate = {};
  currencies.forEach(type => {
    aggregate[type] = {
      name: type,
      legalName: '',
      percentage: 0
    }
  });

  //create currencies list
  let combinedCurrencies = [];
  wallets.forEach(wallet => {
    combinedCurrencies = combinedCurrencies.concat(wallet.currencies)
  });

  //populate values and get total value
  combinedCurrencies.forEach(currency => {
    if(currency.selected) {
      aggregate[currency.name].legalName = currency.legalName;
      aggregate[currency.name].percentage += parseFloat(currency.percentage);
    }
  });

  return aggregate;
}

export const getChartData = (myDumpling) => {
  const aggregate = aggregateSharedCurrencies(myDumpling.currency_set, myDumpling.wallet_info);
  const data = [];

  for(let type in aggregate) {
    data.push({
      name: type,
      value: aggregate[type].percentage
    });
  }

  return data.length !== 0 ? data : null;
}

const SharedDumplingCard = ({ dumpling, roundedTotal, total, goToDumpling, isEverything }) => {
  const date = new Date(dumpling.created_at);
  const { nameLookup } = cryptoSymbol({});
  const buildCurrencyList = () => {
    let temp = [];
    dumpling.currency_set.forEach(type => {
      temp.push(nameLookup(type) || type);
    });

    return temp.join(', ');
  }

  return (
    <Card onClick={() => goToDumpling(dumpling.id, roundedTotal, total, isEverything, dumpling.user_id, dumpling.name, dumpling.quantities_flag, dumpling.values_flag)} sx={{ backgroundColor: 'rgba(255,255,255,0.06)' }} style={{ padding: '14px 12px 13px 16px', marginTop: "10px", marginBottom: "20px", borderRadius: "10px", boxShadow: 'none' }}>
      { console.log() }
      <div>
        <div style={{ display: "flex", width: '100%' }}>
          <div style={{ width: '100%' }}>
            {/* <img src={littleDumplings} width="150px" style={{ float: "left" }} /> */}
            <Box>
              <h3 style={dumplingTitle}> {dumpling.name} <ArrowForwardIosIcon style={{float:"right", color: 'white'}}/></h3>
              <DumplingSub> Shared by {dumpling.user_full_name} </DumplingSub>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '18px' }}>
                <div style={{ width: '20%', height: 48 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={getChartData(dumpling) || chartdata}
                        innerRadius={19}
                        outerRadius={23}
                        paddingAngle={5}
                        fill="#8884d8"
                        dataKey="value"
                        stroke="none"
                      >
                        {getChartData(dumpling)
                          ? getChartData(dumpling).map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))
                          : <Cell key={`cell-1`} fill={'rgba(255,255,255,0.5)'}/>}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                
                <div style={{ display: 'flex', flexDirection: 'column', width: '70%', justifyContent: 'space-around', marginLeft: '5px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    { !parseFloat(dumpling.total_balance) ? null : <h3 style={totalBalanceStyle}>${parseFloat(dumpling.total_balance).toFixed(2)}</h3> }
                    <h3 style={{ color: '#02EDBA', fontSize: '12px', fontWeight: 400, margin: '0px', textAlign: 'right' }}>{determineChange(dumpling.old_total_balance, dumpling.total_balance)}</h3>
                  </div>
                  <h3 style={{ color: 'rgba(255,255,255,0.7)', fontSize: '10px', fontWeight: 400, margin: '0px' }}>{dumpling.currency_set && buildCurrencyList()}</h3>
                </div>
              </div>
              <div>
                <p style={{ fontFamily: 'Montserrat-Regular', fontSize: 10, fontWeight: 400, color: 'rgba(255,255,255,0.7)', marginTop: 15, marginBottom: 0 }}>{ dumpling.tags && buildTagList(dumpling) }</p>
              </div>
              <Legend content={getChartData(dumpling)} verticalAlign="bottom" layout="horizontal"/>
            </Box>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default SharedDumplingCard;