import {Button,Modal} from "@mui/material";
import {Box} from "@mui/system";
import {Auth} from "aws-amplify";
import PasswordValidator from "password-validator";
import {useState} from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import {useLocation,useNavigate} from "react-router-dom";
import styled from "styled-components";
import {ReactComponent as DumplingLogo} from '../Components/svgs/dumpling-logo.svg';

const textField = {
  width: '92%',
  height: '44px',
  paddingLeft: '12px',
  paddingRight: '12px',
  borderRadius: 10,
  border: '1px solid white',
  backgroundColor: 'transparent',
  color: 'white',
  fontFamily: 'Montserrat-Regular',
  fontSize: '16px'
}

const errTextField = {
  width: '92%',
  height: '44px',
  paddingLeft: '12px',
  paddingRight: '12px',
  borderRadius: 10,
  border: '1px solid red',
  backgroundColor: 'transparent',
  color: 'white',
  fontFamily: 'Montserrat-Regular',
  fontSize: '16px'
}

const inputContainer = {
  display: 'flex',
  width: '100%',
  marginTop: '15px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  color: 'white',
  fontFamily: 'Montserrat-Regular',
  fontSize: '14px'
}

const modalStyle = {
  position: 'absolute',
  fontFamily: "Montserrat-Regular",
  top: '50%',
  left: '50%', 
  transform: 'translate(-50%, -50%)',
  width: '358px',
  bgcolor: 'background.paper',
  borderRadius: 5,
  borderColor: "white",
  boxShadow: 24,
  p: 0,
  backgroundColor: "#3F4B5C", 
  color: "white"
};

const buttonStyles = {
  width: '100%',
  height: '46px',
  marginTop: '20px',
  marginBottom: '0px',
  borderRadius: 30,
  backgroundColor: '#0887E2',
  color: '#FFFFFF',
  fontSize: '16px',
  weight: 700,
  textTransform: 'none'
}

const StyledReactInputVerificationCode = styled.div`
  display: flex;
  justify-content: center;

  --ReactInputVerificationCode-itemWidth: 40px;
  --ReactInputVerificationCode-itemHeight: 48px;
  --ReactInputVerificationCode-itemSpacing: 8px;

  .ReactInputVerificationCode__item {
    font-size: 16px;
    font-weight: 500;
    color: #fff;

    background: rgba(53, 67, 98, 1);
    border: 1px solid
      ${({ isInvalid }) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
    border-radius: 4px;
    box-shadow: none;
  }

  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 1px solid #36c6d9;
  }
`;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState();
  const [hasValidPass, setHasValidPass] = useState(false);
  const [passMatch, setPassMatch] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const validatePass = new PasswordValidator();

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  }

  const sendCode = async () => {
    console.log(email);
    await Auth.forgotPassword(email).then(res => { setActiveStep(activeStep + 1) })
      .catch(err => {
        setErrorMessage(err.message);
        setOpenErrorModal(true);
      });
  }

  const handleCollectPassword = e => {
    validatePass
      .is().min(8)                                    // Minimum length 8
      .is().max(100)                                  // Maximum length 100
      .has().uppercase()                              // Must have uppercase letters
      .has().lowercase()                              // Must have lowercase letters
      .has().digits(1)                                // Must have at least 1 digits
      .has().symbols(1)                                // Must have at least 1 digits
      .has().not().spaces();
    setHasValidPass(validatePass.validate(e.target.value));
    setNewPassword(e.target.value);
  }

  const handleConfirmPassword = e => {
    setConfirmPassword(e.target.value);
    setPassMatch(e.target.value === newPassword);
  }

  const getCurrentStep = () => {
    const showConfirmErr = !passMatch && confirmPassword.length > 0;
    const showNewPassErr = !hasValidPass && newPassword.length > 0;
    const stepMap = {
      0: (
        <>
          <label style={inputContainer}>
            <span style={{ marginBottom: '6px' }}>Please enter your email: </span>
            <input style={textField} id="email" placeholder='email@address.com' defaultValue={location.state?.email} type='text' onChange={handleChangeEmail}/>
          </label>
        </>
      ),
      1: (
        <>
          <label style={inputContainer}>
            <span style={{ marginBottom: '6px' }}>Enter the verification code sent to your phone or email: </span>
            <StyledReactInputVerificationCode>
              <ReactInputVerificationCode
                value={verificationCode}
                placeholder={null}
                length={6}
                onChange={(newValue) => {
                  setVerificationCode(newValue);
                }}
              />
            </StyledReactInputVerificationCode>
            
          </label>
        </>
      ),
      2: (
        <>
          <label style={inputContainer}>
            <span style={{ marginBottom: '6px' }}>New password: </span>
            <input style={showNewPassErr ? errTextField : textField} id="password" placeholder='******' type='password' onChange={handleCollectPassword}/>
          </label>
          { showNewPassErr && <p style={{ color: 'red', marginTop: 2 }}>Password does not meet requirements</p> }
          <p style={{...inputContainer, marginBottom: '0px'}}>
            <strong>Password requirements:</strong>
            <ul>
              <li>Must be 8 characters minimum</li>
              <li>Must contain at least one special character, one number, one capital letter, and at least one lower case letter</li>
            </ul>
          </p>
          <label style={inputContainer}>
            <span style={{ marginBottom: '6px' }}>Confirm new password: </span>
            <input style={showConfirmErr ? errTextField : textField} id="confirm-password" placeholder='******' type='password' onChange={handleConfirmPassword}/>
          </label>
          { showConfirmErr && <p style={{ color: 'red', marginTop: 2 }}>Passwords do not match</p> }
        </>
      )
    }

    return stepMap[activeStep];
  }

  const handleSignIn = async () => {
    await Auth.signIn(email, newPassword).then(res => navigate('/publicDumplings'));
  }

  const handleSubmit = async () => {
    console.log('SUBMISSION HANDLED');
    await Auth.forgotPasswordSubmit(email, verificationCode, newPassword).then(res => setOpenSuccessModal(true))
      .catch(err => {
        setErrorMessage(err.message);
        setOpenErrorModal(true);
      });
  }

  return(
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', alignItems: 'center' }}>
      <div style={{
        marginBottom: '36.27px', marginTop: '30px'
      }}>
        <DumplingLogo style={{ width: 207, height: 43 }}/>
      </div>
      <div id="gather-email" style={{ width: '326px' }}>
        <div id="gather-email" style={{ width: '326px' }}>
          {getCurrentStep()}
          <div id="stepper-nav" style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '10px' }}>
            <Button style={{ color: '#0887E2' }} onClick={() => navigate(-1)}>Cancel</Button>
            { activeStep === 0 && <Button style={{ color: '#0887E2' }} onClick={() => sendCode()}>
              Next
            </Button> }
            { activeStep === 1 && <Button style={{ color: '#0887E2' }} onClick={() => setActiveStep(activeStep + 1)} disabled={!verificationCode}>
              Next
            </Button> }
            { activeStep === 2 && <Button style={{ color: '#0887E2' }} onClick={() => handleSubmit()} disabled={activeStep === 2 && !passMatch && !hasValidPass}>
              Submit
            </Button> }
          </div>
        </div>
      </div>
      <Modal
          open={openSuccessModal}
          onClose={() => setOpenSuccessModal(!openSuccessModal)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <Box sx={modalStyle}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 16 }}>
            <div style={{display: "flex", justifyContent: 'flex-start', width: '100%'}}>
              <h1 style={{marginBottom: 0, marginTop: 0, fontSize: 24 }}>Your password has been reset</h1>
            </div>
            <Button style={buttonStyles} onClick={() => handleSignIn()}>Sign in</Button>
          </div>
        </Box>
      </Modal>
      <Modal
          open={openErrorModal}
          onClose={() => setOpenErrorModal(!openErrorModal)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 16 }}>
              <div style={{display: "flex", justifyContent: 'flex-start', width: '100%'}}>
                <h1 style={{marginBottom: 0, marginTop: 0, fontSize: 24 }}>Something went wrong!</h1>
              </div>
              <h3 style={{ textAlign: 'left', marginTop: 8, width: '100%', fontSize: 16 }}>{errorMessage}</h3>
              <Button style={buttonStyles} onClick={() => setOpenErrorModal(!openErrorModal)}>Close</Button>
            </div>
          </Box>
      </Modal>
    </div>
  )
}

export default ForgotPassword;
